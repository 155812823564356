import {
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  SET_SELECTED_CATEGORY_ID,
  SET_PRODUCT_SHOW_MODAL,
  PRODUCT_RECEIVED,
} from '../../types/ProductTypes';

export const getProducts = (filter: any = []) => ({
  type: GET_PRODUCTS,
  filter,
});

export const getProduct = (id: number) => ({
  type: GET_PRODUCT,
  id,
});

export const updateProduct = (id: number, data: any) => ({
  type: UPDATE_PRODUCT,
  data,
  id,
});

export const createProduct = (data: any) => ({
  type: CREATE_PRODUCT,
  data,
});

export const setSelectedCategoryId = (id: any) => ({
  type: SET_SELECTED_CATEGORY_ID,
  id,
});

export const showModal = (status: any) => ({
  type: SET_PRODUCT_SHOW_MODAL,
  status,
});

export const setProduct = (data: any) => ({
  type: PRODUCT_RECEIVED,
  data,
});

