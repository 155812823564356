import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { getDefaultValues, setDefaultValues, getDictionaries } from '../DefaultValueActions';
import { Formik } from 'formik';
import DefaultValueFormContent from './DefaultValueFormContent';
import './DefaultValueForm.scss';
import { useEffect } from 'react';

function DefaultValueForm({
  default_values,
  dictionaries,
  storages,
  categories,
  counterparties,
  accounts,
  is_mobile,
  getDefaultValues,
  setDefaultValues,
}: any) {
  useEffect(() => {
    getDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={default_values}
        onSubmit={(values: any) => setDefaultValues(values)}
      >
        {props => (
          <DefaultValueFormContent
            {...props}
            is_mobile={is_mobile}
            dictionaries={dictionaries}
            storages={storages}
            categories={categories}
            counterparties={counterparties}
            accounts={accounts}
          />
        )}
      </Formik>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  default_values: state.default_values.values,
  dictionaries: state.started_data.dictionaries,
  storages: state.started_data.storages,
  categories: state.started_data.categories,
  accounts: state.started_data.accounts,
  counterparties: state.started_data.counterparties,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDefaultValues,
      setDefaultValues,
      getDictionaries,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DefaultValueForm);
