export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORIES_RECEIVED = 'CATEGORIES_RECEIVED';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';

export const CREATE_CATEGORY_RECEIVED = 'CREATE_CATEGORY_RECEIVED';
export const DELETE_CATEGORY_RECEIVED = 'DELETE_CATEGORY_RECEIVED';
export const UPDATE_CATEGORY_RECEIVED = 'UPDATE_CATEGORY_RECEIVED';
export const CATEGORY_RECEIVED = 'CATEGORY_RECEIVED';

export const SET_CATEGORY_SHOW_MODAL = 'SET_CATEGORY_SHOW_MODAL';
export const SET_SELECTED_CATEGORY_ID = 'SET_SELECTED_CATEGORY_ID';
export const SET_CATEGORIES_FILTER = 'SET_CATEGORIES_FILTER';
export const GET_FILTERED_CATEGORIES = 'GET_FILTERED_CATEGORIES';
export const FILTERED_CATEGORIES_RECEIVED = 'FILTERED_CATEGORIES_RECEIVED';
