import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import PurchaseOrderForm from '../PurchaseOrderForm/PurchaseOrderForm';
import OrderItemsEditable from '../../../components/OrderItemsEditable/OrderItemsEditable';
import {
  createOrder,
  updateOrder,
  createOrderItem,
  deleteOrderItem,
  updateOrderItem,
  clearData,
} from '../../PurchaseOrderActions';
import SelectField from '../../../components/Fields/SelectField';
import ProductDialog from '../../../components/ProductDialog/ProductDialog';
import { I18n } from 'react-redux-i18n';
import { useCallback, useEffect, useMemo } from 'react';

function PurchaseOrderCreateContent({
  match,
  order,
  items,
  isReadingMode,
  products,
  getOrder,
  createOrder,
  updateOrder,
  createOrderItem,
  updateOrderItem,
  deleteOrderItem,
}: any) {

  useEffect(() => {
    if (match?.params?.id) {
      getOrder(match.params.id, []);
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  const create = useCallback((data: any) => {
    if (order?.id) {
      updateOrder(order.id, data);
    } else {
      createOrder(data);
    }
  }, [createOrder, order.id, updateOrder]);

  const updateItem = useCallback((data: any) => {
    updateOrderItem(data.id, data);
  }, [updateOrderItem]);

  const deleteItem = useCallback((data: any) => {
    deleteOrderItem(data, order.id);
  }, [deleteOrderItem, order.id]);

  const createItem = useCallback((data: any) => {
    createOrderItem({ ...data, p_o_id: order.id });
  }, [createOrderItem, order.id]);

  const renderInput = useCallback((props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'product_id') {
          options = products.filter((item: any) => item.published)
            .map((item: any) => ({title: item.title, value: item.id, key: item.id}));
        }

        return (
        <div style= {{ flexDirection : 'row', display : 'flex' }}>
          <div style = {{flex : '1'}}>
            <SelectField
              {...props}
              options={options}
              handleChange={(e: any) => {
                props.handleChange(e);
                products.some((item: any) => {
                  if (item.id == e.target.value) {
                    props.handleChange({target: { name : 'price', value : item.price }});
                    return true;
                  }
                });
              }}
            />
          </div>
          <ProductDialog
            onDoubleClick = {(product: any) => {
              props.handleChange({target: { name : 'product_id', value : product.id }});
              props.handleChange({target: { name : 'price', value : product.price }});
            }}
          />
        </div>);
      default:
        return null;
    }
  }, [products]);

  const onTableCell = useCallback((col: any, record: any) => {
    switch (col.dataIndex) {
      case 'product_id':
        return {
          inputType: 'select',
          renderInput,
        };
      case 'total':
        return {
          inputType: 'calculated',
          renderInput: (props: any) => {
            const { values } = props;
            const quantity = parseFloat(values.quantity)
            const price = parseFloat(values.price)
            if (isNaN(quantity) || isNaN(price)) {
              return '';
            }
            return (quantity * price).formatNumber(undefined, 4, 4);
          },
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }, [renderInput]);

  const validateRules = useMemo(() => [
    {name: 'product_id', rules: ['required']},
    {name: 'price', rules: ['required', 'numeric']},
    {name: 'quantity', rules: ['required', 'numeric']},
  ], []);

  const columns = useMemo(() => [
    {
      width: '40%',
      title: I18n.t('purchase_order.Product Title'),
      dataIndex: 'product_id',
      editable: true,
      render: (text: any, record: any) => {
        if (record.product) {
          return record.product.title;
        }
        return record.id;
      },
    },
    {
      width: '12%',
      title: I18n.t('purchase_order.Price'),
      dataIndex: 'price',
      align: 'right',
      editable: true,
    },
    {
      width: '12%',
      title: I18n.t('purchase_order.Quantity'),
      dataIndex: 'quantity',
      align: 'right',
      editable: true,
    },
    {
      width: '16%',
      title: I18n.t('general.Total'),
      dataIndex: 'total',
      editable: true,
      align: 'right',
      render: (text: any, record: any) => {
        return ((record.quantity || 0) * (record.price || 0)).formatNumber(undefined, 4, 4);
      },
    },
  ], []);

  const total = useMemo(() => items.reduce((sum: number, item: any) => {
    return sum + item.price * item.quantity
  }, 0), [items]);

  return (
    <Row>
      <PurchaseOrderForm onChange={create} order={order}/>
      <div style={{ marginBottom: 20 }}>
        {I18n.t('general.Total')}: <strong>{total.formatNumber(undefined, 4, 4)}</strong>
      </div>
      <div style = {{overflow: 'auto', width: '100%'}}>
        <OrderItemsEditable
          showNewLine={!isReadingMode && order.id ? true : false }
          showOperations={!isReadingMode ? true : false }
          items={items}
          create={createItem}
          update={updateItem}
          delete={deleteItem}
          validateRules={validateRules}
          columns={columns}
          onTableCell={onTableCell}
          btnCreateShow={false}
        />
      </div>
    </Row>
  );
}

const mapStateToProps = (state: any) => ({
  isReadingMode: state.purchase_order.isReadingMode,
  order: state.purchase_order.order,
  items: state.purchase_order.items,
  products: state.started_data.products,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createOrder,
      updateOrder,
      createOrderItem,
      deleteOrderItem,
      updateOrderItem,
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderCreateContent);
