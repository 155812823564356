import { Input, Form } from 'antd';

function FieldInput ({ attribute, values, handleChange, handleSubmit }: any) {

  const value = values?.value;

  return (
    <Form.Item
          // validateStatus={'error'}
          // help={'Error'}
        >
      <Input
        placeholder={attribute.title}
        name={attribute.name}
        value = {value}
        allowClear = {true}
        onChange={(e) => {
          const valueAtt: any = {
            attribute: attribute.name,
            value: e.target.value,
          };

          if (attribute.comparison) {
            valueAtt.comparison = attribute.comparison;
          }
          handleChange({
            target: {
              name: attribute.name,
              value: e.target.value ? valueAtt : '',
            },
          });
          setTimeout(() => {
            handleSubmit();
          }, 200);
        }}
      />
    </Form.Item>
  );
}

export default FieldInput;
