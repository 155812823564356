import { Input, Button, Col, Form } from 'antd';
import classNames from 'classnames';
import './style.scss';
import { connect } from 'react-redux';

function FormContent({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  loading,
}: any) {
  return (
    <Col xs={20} sm={13} lg={8} style={{ height: '100%', width: '100%' }}>
      <Form onFinish={handleSubmit} className="login-form">
        <Form.Item className={classNames({'has-error': errors.email && touched.email})}>
          <Input placeholder="Email" name="email" onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          {errors.email && touched.email ? <Col className="error-message">{errors.email}</Col> : null}
        </Form.Item>
        <Form.Item className={classNames({'has-error': errors.password && touched.password})}>
          <Input.Password
            placeholder="Password"
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
          {errors.password && touched.password ? <Col className="error-message">{errors.password}</Col> : null}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" disabled={loading} loading={loading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Col>
  );
}

const mapStateToProps = (state: any) => ({
  loading: state.auth.loading,
});

export default connect(mapStateToProps, null)(FormContent);
