import {
  GET_STORAGES,
  CREATE_STORAGE,
  UPDATE_STORAGE,
  DELETE_STORAGE,
} from '../../types/StorageTypes';

export const getStorages = (filter: any = []) => ({
  type: GET_STORAGES,
  filter,
});

export const createStorage = (data: any) => ({
  type: CREATE_STORAGE,
  data,
});

export const updateStorage = (id: number, data: any) => ({
  type: UPDATE_STORAGE,
  data,
  id,
});

export const deleteStorage = (id: any) => ({
  type: DELETE_STORAGE,
  id,
});
