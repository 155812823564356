import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import {
  GET_SALES_ORDERS,
  SALES_ORDERS_RECEIVED,
  SET_SALES_ORDERS_FILTER,
  SALES_ORDER_RECEIVED,
  GET_SALES_ORDER,
  CREATE_SALES_ORDER,
  UPDATE_SALES_ORDER,
  CREATE_SALES_ORDER_ITEM,
  UPDATE_SALES_ORDER_ITEM,
  DELETE_SALES_ORDER_ITEM,
  UPDATE_SALES_ORDER_ITEM_RECEIVED,
  CREATE_SALES_ORDER_ITEM_RECEIVED,
  DELETE_SALES_ORDER_ITEM_RECEIVED,
  // SALES_ORDER_DATA_CREATE,
  // SALES_ORDER_DATA_CREATE_RECEIVED,
  SALES_ORDER_ITEMS_RECEIVED,
  SALES_ORDER_PROCESSED,
  SALES_ORDER_PROCESSED_RECEIVED,
  SALES_ORDER_UPDATE,
  SALES_ORDER_SET_TOTALS,
  CREATE_SALES_ORDER_RECEIVED,
  UPDATE_SALES_ORDER_RECEIVED,
  DELETE_SALES_ORDER,
} from '../types/SalesOrderTypes';
import generateFilter from '../lib/genFilter';
import findIndex from 'lodash.findindex';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_SALES_ORDERS_FILTERS } from '../types/Constants';

function* getOrders({filter}: any) {
  setValueByKey(STORAGE_SALES_ORDERS_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('sales_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('sales_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      data.forEach((item: any) => {
        const index = findIndex(totals, ['s_o_id', item.id]);
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: SALES_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_SALES_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: SALES_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0}});
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}
function* deleteOrder({filter, id}: any) {

  try {
    yield restClient.delete('sales_orders', id);
    const {data, count}: GenericEntitiesResponse = yield restClient.get('sales_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('sales_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      data.forEach((item: any) => {
        const index = findIndex(totals, ['s_o_id', item.id]);
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: SALES_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_SALES_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: SALES_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0}});
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}

function* getOrder({id, filter}: any) {

  try {
    const data: GenericEntityResponse = yield restClient.findOne('sales_orders', id, { populate: 'items' });
    const { data: salesOrderItems }: GenericEntitiesResponse = yield restClient.find(
      'sales_order_items',
      {
        s_o_id: id,
        populate: 'product',
        // TODO: improve pagination order items pagination
        limit: 1000,
      },
    );
    yield put({ type: SALES_ORDER_RECEIVED, order: data });
    yield put({ type: SALES_ORDER_ITEMS_RECEIVED, items: salesOrderItems });
  } catch (error) {
    yield put({ type: SALES_ORDER_RECEIVED, order: {}});
    yield put({ type: SALES_ORDER_ITEMS_RECEIVED, items: [] });

    yield put({ type: ERROR, messages: ['Not order']});
  }
}

function* createOrder({data}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.post('sales_orders', data);
    const result: GenericEntityResponse = yield restClient.findOne('sales_orders', item.id, { populate: 'storage,counterparty' });
    yield put({ type: CREATE_SALES_ORDER_RECEIVED, order: result });
  } catch (error) {
    yield put({ type: CREATE_SALES_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* updateOrder({data, id}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.update('sales_orders', id, data);
    const result: GenericEntityResponse = yield restClient.findOne('sales_orders', item.id, { populate: 'storage,counterparty' });
    const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', {ids: id});
    yield put({ type: UPDATE_SALES_ORDER_RECEIVED, order: result, id });
    if (totals[0]) {
      yield put({ type: SALES_ORDER_UPDATE, item: {
        base_total: totals[0].total || 0,
        sub_total: totals[0].total || 0,
        total: totals[0].total * item.currency_rate,
        id,
      }});
    }
  } catch (error) {
    yield put({ type: UPDATE_SALES_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order update']});
  }
}

function* updateOrderItem({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('sales_order_items', id, data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', {ids: data.s_o_id});
    const order: GenericEntityResponse = yield restClient.get('sales_orders', data.s_o_id, { populate: 'items' });
    yield put({ type: SALES_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: data.s_o_id,
    }});
    yield put({ type: UPDATE_SALES_ORDER_ITEM_RECEIVED, item: {...result, product}, id });
  } catch (error) {
    // yield put({ type: SALES_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order item update']});
  }
}

function* createOrderItem({data}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.create('sales_order_items', data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', { ids: data.s_o_id });
    const order: GenericEntityResponse = yield restClient.get('sales_orders', data.s_o_id, { populate: 'items' });
    yield put({ type: SALES_ORDER_UPDATE, item: {
        base_total: totals[0].total || 0,
        sub_total: totals[0].total || 0,
        total: totals[0].total * order.currency_rate,
        id: data.s_o_id,
      } });
    yield put({ type: CREATE_SALES_ORDER_ITEM_RECEIVED, item: {...result, product} });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order item create']});
  }
}

function* deleteOrderItem({id, order_id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('sales_order_items', id);
    const totals: GenericEntityResponse[] = yield restClient.get('sales_orders', 'totals', {ids: order_id});
    const order: GenericEntityResponse = yield restClient.get('sales_orders', order_id, 'populate=items');
    yield put({ type: SALES_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: order_id,
    }});
    yield put({ type: DELETE_SALES_ORDER_ITEM_RECEIVED, id });
  } catch (error) {
    // yield put({ type: SALES_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not delete order item']});
  }
}

function* setOrderProcessed({data, filter}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('sales_orders', 'processed', data);

    const ordersTotals: GenericEntityResponse = yield restClient.get('sales_orders', 'sum_totals', generateFilter(filter, true));
    yield put({ type: SALES_ORDER_SET_TOTALS, totals: ordersTotals });

    yield put({ type: SALES_ORDER_PROCESSED_RECEIVED, order: result });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

export default function* actionWatcherSalesOrder() {
  yield takeLatest(GET_SALES_ORDERS, getOrders);
  yield takeLatest(GET_SALES_ORDER, getOrder);
  yield takeLatest(CREATE_SALES_ORDER, createOrder);
  yield takeLatest(UPDATE_SALES_ORDER, updateOrder);
  yield takeLatest(CREATE_SALES_ORDER_ITEM, createOrderItem);
  yield takeLatest(UPDATE_SALES_ORDER_ITEM, updateOrderItem);
  yield takeLatest(DELETE_SALES_ORDER_ITEM, deleteOrderItem);
  yield takeLatest(SALES_ORDER_PROCESSED, setOrderProcessed);
  yield takeLatest(DELETE_SALES_ORDER, deleteOrder);
}
