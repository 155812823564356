import { UPDATE_STARTED_DATA_ITEM, DELETE_STARTED_DATA_ITEM } from './../types/StartedDataTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_ACCOUNTS,
  ACCOUNTS_RECEIVED,
  CREATE_ACCOUNT_RECEIVED,
  UPDATE_ACCOUNT_RECEIVED,
  DELETE_ACCOUNT_RECEIVED,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
} from '../types/AccountTypes';
import { CREATE_STARTED_DATA_ITEM } from '../types/StartedDataTypes';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_ACCOUNTS_FILTERS } from '../types/Constants';

function* getAccounts({filter}: any) {
  setValueByKey(STORAGE_ACCOUNTS_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('accounts', '', generateFilter(filter));
    yield put({ type: ACCOUNTS_RECEIVED, data, count, filter });
  } catch (error) {
    yield put({ type: ACCOUNTS_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not accounts']});
  }
}

function* createAccount({data}: any) {

  try {
    const result: GenericEntityResponse = yield restClient.post('accounts', data);
    yield put({ type: CREATE_ACCOUNT_RECEIVED, data: result });
    yield put({ type: CREATE_STARTED_DATA_ITEM, name: 'accounts', item: {id: result.id, name: result.name} });
  } catch (error) {
    yield put({ type: CREATE_ACCOUNT_RECEIVED, data: {} });
    yield put({ type: ERROR, messages: ['Not account create']});
  }
}

function* updateAccount({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('accounts', id, data);
    yield put({ type: UPDATE_ACCOUNT_RECEIVED, data: result });
    yield put({ type: UPDATE_STARTED_DATA_ITEM, name: 'accounts', item: {id: result.id, name: result.name}, id });
  } catch (error) {
    yield put({ type: UPDATE_ACCOUNT_RECEIVED, data: {} });
    yield put({ type: ERROR, messages: ['Not account update']});
  }
}

function* deleteAccount({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('accounts', id);
    yield put({ type: DELETE_ACCOUNT_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'accounts', id });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not account delete']});
  }
}

export default function* actionWatcherAccount() {
  yield takeLatest(GET_ACCOUNTS, getAccounts);
  yield takeLatest(CREATE_ACCOUNT, createAccount);
  yield takeLatest(UPDATE_ACCOUNT, updateAccount);
  yield takeLatest(DELETE_ACCOUNT, deleteAccount);
}
