import ProductDialogModal from './components/ProductDialogModal/ProductDialogModal';
import { Button } from 'antd';
import { useState } from 'react';

function ProductDialog({ onDoubleClick }: any) {
  const [showModal, setShowModal] = useState(false);

  const _onDoubleClick = (id: any) => {
    setShowModal(false);
    onDoubleClick(id);
  };

  return (
    <div>
      <Button style={{ margin: '0 5px' }} onClick={() => setShowModal(true)}>
        {' '}
        . . .{' '}
      </Button>
      <ProductDialogModal
        open={showModal}
        onDoubleClick={(id: any) => _onDoubleClick(id)}
        onCancel={() => setShowModal(false)}
      />
    </div>
  );
}

export default ProductDialog;
