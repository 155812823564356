import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik } from 'formik';
import validationFields from '../../../../lib/validate';
import { AppState } from '../../../../reducers';
import TransferOrderFormContent from './TransferOrderFormContent';
import { setShowModal } from '../../TransferOrderActions';
import { getDefaultValues } from '../../../DefaultValue/DefaultValueActions';
import moment from 'moment';
import './TransferOrderForm.scss';
import { useEffect } from 'react';

function TransferOrderForm({
  loading,
  order,
  storages,
  isReadingMode,
  currency,
  types,
  default_types,
  is_mobile,
  onChange,
  getDefaultValues,
  setShowModal,
}: any) {
  useEffect(() => {
    getDefaultValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  order.currency_rate = order.currency_rate ? order.currency_rate : '1.00';

  order.discount = order.discount ? order.discount : '0.00';
  order.currency_name = order.currency_name ? order.currency_name : default_types.currency?.toString();

  order.doc_type = order.doc_type ? order.doc_type : default_types.transfer_order?.toString();

  order.date = order.date ? order.date : moment().format('YYYY-MM-DD');

  order.note = order.note ? order.note : '';

  return (
    <Formik
      initialValues={order}
      enableReinitialize={true}
      validate={(values: any) => {
        const fields: any = [
          { name: 'date', rules: ['required'] },
          { name: 'storage_from_id', rules: ['required'] },
          { name: 'storage_to_id', rules: ['required'] },
          { name: 'doc_type', rules: ['required'] },
          { name: 'discount', rules: [{ name: 'gte', value: 0 }, { name: 'lte', value: 100 }, 'required', 'numeric'] },
          { name: 'currency_name', rules: ['required'] },
          { name: 'currency_rate', rules: [{ name: 'gte', value: 0 }, 'required', 'numeric'] },
        ];
        return validationFields(fields, values);
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (onChange) {
          onChange(values);
        }
        setSubmitting(loading);
      }}
    >
      {props => (
        <TransferOrderFormContent
          {...props}
          loading={loading}
          is_mobile={is_mobile}
          isReadingMode={isReadingMode}
          setShowModal={setShowModal}
          storages={storages.map((item: any) => ({ title: item.name, value: item.id }))}
          types={types.map((item: any) => ({ title: item.short_title, value: item.id }))}
          currency={currency.map((item: any) => ({ title: item.short_title, value: item.id }))}
        />
      )}
    </Formik>
  );
}

const mapStateToProps = (state: AppState) => ({
  isReadingMode: state.transfer_order.isReadingMode,
  loading: state.transfer_order.loading,
  storages: state.started_data.storages,
  types: state.started_data.dictionaries.filter((item: any) => item.d_type === 'transfer_order'),
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  default_types: state.default_values.values,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowModal,
      getDefaultValues,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderForm);
