import {
  GET_COUNTERPARTIES,
  COUNTERPARTIES_RECEIVED,
  CREATE_COUNTERPARTY_RECEIVED,
  UPDATE_COUNTERPARTY_RECEIVED,
  DELETE_COUNTERPARTY_RECEIVED,
} from '../types/CounterpartyTypes';
import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_COUNTERPARTY_FILTERS } from '../types/Constants';

export interface CounterpartyState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  total_count: number;
}

const defaultState: CounterpartyState = {
  loading: false,
  list_data: [],
  total_count: 0,
  filter: getValueByKey(STORAGE_COUNTERPARTY_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 1000,
    },
    {
      attribute: 'sort',
      value: '-createdAt',
    },
  ]),
};

export default (state: CounterpartyState = defaultState, action: any) => {
  let tmpList: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_COUNTERPARTIES:
      return {...state, loading: true};
    case COUNTERPARTIES_RECEIVED:
      const data = action.data.map((item: any) => ({...item, c_type: item.c_type.split(',')}));
      const total_count = action.count > -1 ? action.count : (state.total_count || 0);
      return {...state, loading: false,
        list_data: data,
        filter: action.filter && action.filter.length ? action.filter : state.filter,
        total_count,
      };
    case CREATE_COUNTERPARTY_RECEIVED:
      return {...state, list_data: [action.item, ...state.list_data ], total_count: state.total_count + 1 };
    case UPDATE_COUNTERPARTY_RECEIVED:
      return {...state, list_data: state.list_data.map((item: any) => item.id === action.item.id ? action.item : item)};
    case DELETE_COUNTERPARTY_RECEIVED:
        tmpList = tmpList.filter((item: any) => item.id !== action.id);
        return {...state, list_data: tmpList, total_count: state.total_count - 1};
    default:
       return state;
  }
};
