import { Tree } from 'antd';

interface CategoryTreeProps {
  onSelect: any;
  selectedKeys: any;
  treeData: any;
}

function CategoryTree({ treeData, onSelect, selectedKeys }: CategoryTreeProps) {

  return (
    <Tree
      defaultExpandAll={true}
      onSelect={onSelect}
      selectedKeys={(selectedKeys).toString().split()} // need array of strings
      treeData={treeData}
    />
  );

}

export default (CategoryTree);
