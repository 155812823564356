export default {
  'Products': 'Товари',
  'Orders': 'Накладні',
  'Catalog': 'Каталог',
  'Purchase Order': 'Прихідні',
  'Sales Order': 'Розхідні',
  'Transfer Order': 'Переміщення',
  'Bill': 'Прихідний ордер',
  'Payment': 'Розхідний ордер',
  'Categories': 'Категорії',
  'Dictionaries': 'Словники',
  'Accounts': 'Каси',
  'Counterparties': 'Контрагенти',
  'Transfer' : 'Пeрeміщення',
  'Reports' : 'Звіти',
  'Reports account' : 'Рух коштів',
  'Reports counterparty' : 'Взаєморозрахунок',
  'Reports storage' : 'Залишок по складу',
  'Directories' : 'Довідники',
  'Money' : 'Гроші',
  'Storages' : 'Склади',
  'Logout': 'Вихід',
  'Default value': 'Стандартні значення',
};
