import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table } from 'antd';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { AppState } from '../../../../reducers';

function ReportCounterpartyDetailsData({ data, loading }: any) {
  const columns = [
    {
      title: I18n.t('report.Date'),
      dataIndex: 'date',
      key: 'date',
      render: (date: any) => {
        return date ? moment(date).format('L') : '';
      },
    },
    {
      title: I18n.t('report.Type'),
      render: (row: any) => {
        switch (row.type) {
          case 'bill':
            return I18n.t('report.Bill');
          case 'payment':
            return I18n.t('report.Payment');
          case 'purchase_order':
            return I18n.t('report.Purchase order');
          case 'sales_order':
            return I18n.t('report.Sales order');
          default:
            return '';
        }
      },
    },
    {
      title: I18n.t('report.Incoming'),
      align: 'right' as any,
      render: (row: any) => {
        if (row.type === 'purchase_order') {
          return row.quantity.formatNumber();
        }
        return '';
      },
    },
    {
      title: I18n.t('report.Outcoming'),
      align: 'right' as any,
      render: (row: any) => {
        if (row.type === 'sales_order') {
          return row.quantity.formatNumber();
        }
        return '';
      },
    },
    {
      title: I18n.t('report.Price'),
      align: 'right' as any,
      render: (row: any) => {
        return row.price.formatNumber();
      },
    },
    {
      title: I18n.t('report.Total'),
      align: 'right' as any,
      render: (row: any) => {
        if (row.type === 'purchase_order') {
          return (row.quantity * row.price).formatNumber();
        }
        if (row.type === 'sales_order') {
          return (-1 * row.quantity * row.price).formatNumber();
        }
        return '';
      },
    },
    // {
    //   title: I18n.t('report.Note'),
    //   dataIndex: 'note',
    //   key: 'note',
    // },
  ];

  return (
    <div style={{ overflow: 'auto', width: '100%' }}>
      <Table columns={columns} dataSource={data} bordered pagination={false} loading={loading} />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report_details,
  filter: state.report.filter,
  loading: state.report.loading,
  counterparties: state.started_data.counterparties,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReportCounterpartyDetailsData);
