export const GET_SALES_ORDERS = 'GET_SALES_ORDERS';
export const SALES_ORDERS_RECEIVED = 'SALES_ORDERS_RECEIVED';

export const SET_SALES_ORDERS_FILTER = 'SET_SALES_ORDERS_FILTER';

export const GET_SALES_ORDER = 'GET_SALES_ORDER';
export const SALES_ORDER_RECEIVED = 'SALES_ORDER_RECEIVED';

export const CREATE_SALES_ORDER_ITEM = 'CREATE_SALES_ORDER_ITEM';
export const UPDATE_SALES_ORDER_ITEM = 'UPDATE_SALES_ORDER_ITEM';
export const DELETE_SALES_ORDER_ITEM = 'DELETE_SALES_ORDER_ITEM';
export const UPDATE_SALES_ORDER_ITEM_RECEIVED = 'UPDATE_SALES_ORDER_ITEM_RECEIVED';
export const CREATE_SALES_ORDER_ITEM_RECEIVED = 'CREATE_SALES_ORDER_ITEM_RECEIVED';
export const DELETE_SALES_ORDER_ITEM_RECEIVED = 'DELETE_SALES_ORDER_ITEM_RECEIVED';
export const SALES_ORDER_ITEMS_RECEIVED = 'SALES_ORDER_ITEMS_RECEIVED';

export const CREATE_SALES_ORDER = 'CREATE_SALES_ORDER';
export const UPDATE_SALES_ORDER = 'UPDATE_SALES_ORDER';
export const CREATE_SALES_ORDER_RECEIVED = 'CREATE_SALES_ORDER_RECEIVED';
export const UPDATE_SALES_ORDER_RECEIVED = 'UPDATE_SALES_ORDER_RECEIVED';

// export const SALES_ORDER_DATA_CREATE = 'SALES_ORDER_DATA_CREATE';
// export const SALES_ORDER_DATA_CREATE_RECEIVED = 'SALES_ORDER_DATA_CREATE_RECEIVED';
export const SALES_ORDER_CLEAR_DATA = 'SALES_ORDER_CLEAR_DATA';

export const SALES_ORDER_PROCESSED = 'SALES_ORDER_PROCESSED';
export const SALES_ORDER_PROCESSED_RECEIVED = 'SALES_ORDER_PROCESSED_RECEIVED';
export const SET_SHOWMODAL_SALES_ORDER = 'SET_SHOWMODAL_SALES_ORDER';
export const SET_SALES_ORDER_READING_MODE = 'SET_SALES_ORDER_READING_MODE';
export const SALES_ORDER_UPDATE = 'SALES_ORDER_UPDATE';
export const SALES_ORDER_SET_TOTALS = 'SALES_ORDER_SET_TOTALS';
export const DELETE_SALES_ORDER = 'DELETE_SALES_ORDER';
