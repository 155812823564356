import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import TransferOrderList from './TransferOrderList';
import TransferOrderView from './TransferOrderView';
import TransferOrderCreate from './TransferOrderCreate';
import TransferOrderUpdate from './TransferOrderUpdate';
import './TransferOrder.scss';

function TransferOrder({ loading, loading_counterparty, loading_storage }: any) {
  return (
    <Spin spinning={loading || loading_storage || loading_counterparty}>
      <Switch>
        <Route exact path="/transfer_order/list" component={TransferOrderList} />
        <Route exact path="/transfer_order/view/:id" component={TransferOrderView} />
        <Route exact path="/transfer_order/update/:id" component={TransferOrderUpdate} />
        <Route exact path="/transfer_order/create" component={TransferOrderCreate} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.transfer_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(TransferOrder);
