export const GET_STORAGES = 'GET_STORAGES';
export const STORAGES_RECEIVED = 'STORAGES_RECEIVED';

export const CREATE_STORAGE = 'CREATE_STORAGE';
export const UPDATE_STORAGE = 'UPDATE_STORAGE';
export const DELETE_STORAGE = 'DELETE_STORAGE';

export const CREATE_STORAGE_RECEIVED = 'CREATE_STORAGE_RECEIVED';
export const UPDATE_STORAGE_RECEIVED = 'UPDATE_STORAGE_RECEIVED';
export const DELETE_STORAGE_RECEIVED = 'DELETE_STORAGE_RECEIVED';
