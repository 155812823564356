import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Col } from 'antd';
import Filter from '../components/Filter/Filter';
import { I18n } from 'react-redux-i18n';
import { getReportStorage, clearReport } from './ReportActions';
import { AppState } from '../../reducers';
import findIndex from 'lodash.findindex';
import ReportStorageTable from './components/ReportStorage/ReportStorageTable';
import ReportStorageCategoryTree from './components/ReportStorage/ReportStorageCategoryTree';
import { createNewTreeData } from '../../lib/helper';
import cloneDeep from 'lodash.clonedeep';

function ReportProduct({ categories, filter, storages, getReportStorage, clearReport }: any) {
  const [selectedCategoryId, setSelectedCategoryId] = useState(() => {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    let category: number = JSON.parse(retrievedObject).category || 0;
    const product_limit: number = JSON.parse(retrievedObject).product_limit || 5;

    if (!category && Array.isArray(categories)) {
      category = (categories.find((_category: any) => _category.parent_id === 0) || {}).id || 0;
    }

    const _filter: any = cloneDeep(filter);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      _filter[indexLimit].value = product_limit;
    } else {
      _filter.push({ attribute: 'limit', value: product_limit });
    }

    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      _filter[indexSkip].value = 0;
    }

    const indexCategory = findIndex(filter, ['attribute', 'category_id']);
    if (indexCategory > -1) {
      if (category) {
        _filter[indexCategory].value = category;
      } else {
        _filter.splice(indexCategory, 1);
      }
    } else {
      if (category) {
        _filter.push({ attribute: 'category_id', value: category });
      }
    }

    getReportStorage(_filter);

    return category;
  });
  // public storageId: any = '';

  useEffect(() => {
    clearReport();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelect = useCallback((id: any) => {
    let _filter = filter.filter((item: any) => item.attribute !== 'skip');

    if (id[0]) {
      if (id[0] !== '0') {
        _filter.push({ attribute: 'category_id', value: id });
      } else {
        _filter = _filter.filter((item: any) => item.attribute !== 'category_id');
      }
    }
    getReportStorage(_filter);
    setSelectedCategoryId(parseInt(id, 10));
  }, [filter, getReportStorage]);

  // filter.forEach((item: any) => {
  //   if (item.attribute === 'storage_id') {
  //     this.storageId = item.value;
  //   }
  // });

  const treeData: any = useMemo(() => [
    { value: '0', title: I18n.t('report.All Categories'), key: '0', children: createNewTreeData(categories) },
  ], [categories]);

  return (
    <div>
      <Row>
        <Col span={5}>
          <Row style={{ marginBottom: 10 }}>
            <Filter
              onChange={(values: any) => {
                getReportStorage(values);
              }}
              initialValues={filter}
              attributes={[
                {
                  name: 'storage_id',
                  title: I18n.t('report.Storage'),
                  hyper_type: 'select',
                  options: storages.map((item: any) => {
                    return {
                      title: item.name,
                      value: item.id,
                    };
                  }),
                },
              ]}
            />
          </Row>
          <Row style={{ overflow: 'auto' }}>
            <ReportStorageCategoryTree selectedKeys={selectedCategoryId} treeData={treeData} onSelect={onSelect} />
          </Row>
        </Col>
        <Col span={19} style={{ overflow: 'auto' }} className="small-table no-editable">
          <ReportStorageTable />
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.report.filter,
  categories: state.started_data.categories,
  storages: state.started_data.storages,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportStorage,
      clearReport,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportProduct);
