import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import Filter from '../components/Filter/Filter';
import {
  createCounterparty,
  updateCounterparty,
  deleteCounterparty,
  getCounterparties,
} from './CounterpartyActions';
import SelectField from '../components/Fields/SelectField';
import findIndex from 'lodash.findindex';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';

class CounterpartyList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected_type: '',
    };
  }

  onChangeFilter = (filter: any) => {
    this.props.getCounterparties(filter);
    let value = '';
    filter.map((item: any) => {
      value = item.attribute === 'c_type' ? item.value : '';
    });
    this.setState({selected_type : value});
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'c_type') {
          options = this.props.dictionaries.map((item: any) => ({title: item.title, value: item.id.toString()}));
        }
        return <SelectField
          {...props}
          mode = "multiple"
          options={options}
        />;
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'c_type':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const counterparty_limit: number = JSON.parse(retrievedObject).counterparty_limit;
    const filter: any = cloneDeep(this.props.filter);

    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    if (indexLimit > -1) {
      filter[indexLimit].value = counterparty_limit || 1000;
    } else {
      filter.push({ attribute: 'limit', value: 1000 });
    }

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }

    this.props.getCounterparties(filter);
  }

  updateItem = (data: any) => {
    this.props.updateCounterparty(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteCounterparty(data);
  }

  createItem = (data: any) => {
    this.props.createCounterparty({...data});
  }

  onChange = (pagination: any, filters: any, sorter: any) => {

    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      if (indexSkip > -1) {
        filter[indexSkip].value = skip;
      } else {
        filter.push({attribute: 'skip', value: skip });
      }
    }

    if (!Object.keys(sorter).length) {
      this.props.getCounterparties(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getCounterparties(filter);
  }

  public render() {
    const { data, dictionaries, filter, total_count} = this.props;
    const tableData: any [] = data.map((item: any) => {
      if ( findIndex(dictionaries, ['id', parseInt(item.c_type, 10)]) > -1) {
        const index = findIndex(dictionaries, ['id', parseInt(item.c_type, 10)]);
        if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
          item.row_color = dictionaries[index].style.color;
        }
      } else {
        delete item.row_color;
      }
    });

    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    const pagination = (total_count <= limit)
      ? false
      : {
        total : total_count + Math.ceil(total_count / limit),
        pageSize: limit + 1,
      };

    const validateRules = [
      {name: 'name', rules: ['required']},
      {name: 'c_type', rules: ['array']},
      {name: 'discount', rules: ['required', 'numeric']},
    ];

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        render: (item: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{item}</div>,
        }),
      },
      {
        title: I18n.t('counterparty.Name'),
        dataIndex: 'name',
        editable: true,
        render: (item: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{item}</div>,
        }),
      },
      {
        title: I18n.t('counterparty.Type'),
        dataIndex: 'c_type',
        editable: true,
        render: (values: [],record: any) => {
          const res: any = [];
          if (values && Array.isArray(values)) {
            values.map((value: any) => {
              const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
              res.push((index > -1) && value && dictionaries[index].title || '');
            });
            return {
              props: { style: { background: record.row_color} },
              children: <div>{(res).join(', ')}</div>,
            };
          }
          return {
            props: { style: { background: record.row_color} },
            children: <div />,
          };
        },
      },
      {
        title: I18n.t('counterparty.Discount'),
        dataIndex: 'discount',
        width: '15%',
        editable: true,
        render: (item: any, record: any) => ({
          props: { style: { background: record.row_color} },
          children: <div>{!isNaN(parseInt(item, 10)) ? (parseFloat(item).toFixed(2) + ' %') : (item + ' %')}</div>,
        }),
      },
    ];

    const tData = data.map((item: any) => ({...item, c_type: item.c_type.filter((type: any) => !!type)}));

    return (
      <div>
        <Filter
          onChange={this.onChangeFilter}
          initialValues={filter}
          attributes={[
            {
              name: 'c_type',
              title: I18n.t('counterparty.Type'),
              hyper_type: 'select',
              options: dictionaries.map((item: any) => {
                  return {
                    title: item.title,
                    value: item.id,
                  };
              }),
          },
         ]}
        />
        <Row style = {{overflow: 'auto'}} className = "small-table no-status simple-actions">
          <OrderItemsEditable
            onChange = {this.onChange}
            insertNewRow="first"
            btnCreateDisabled={false}
            items={tData}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            validateRules={validateRules}
            columns={columns}
            onTableCell={this.onTableCell}
            initionalNewRowValues={this.state.selected_type ?
              {c_type: this.state.selected_type.toString(), discount: 0} :
              {discount: 0}
            }
            btnCreateShow={false}
            showNewLine={true}
            actionUpdateShow={(item: any) => !item.processed}
            actionDeleteShow={(item: any) => !item.processed}
            pagination={pagination}
            operationsOnLeft = {true}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.counterparty.filter,
  data: state.counterparty.list_data,
  total_count: state.counterparty.total_count,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'counterparty'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createCounterparty,
      updateCounterparty,
      deleteCounterparty,
      getCounterparties,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CounterpartyList);
