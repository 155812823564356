import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Switch, Route } from 'react-router-dom';
import { DefaultLayout, LightLayout } from '../containers/Layout';
import { useEffect } from 'react';

const getMe = () => ({
  type: 'ME',
});

function DefaultRouter({ logined, loading, getMe }: any) {
  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }
  return (
    <Switch>
      <Route path="/" component={logined ? DefaultLayout : LightLayout} />
    </Switch>
  );
}

const mapStateToProps = (state: any) => ({
  logined: state.auth.logined,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getMe,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DefaultRouter);
