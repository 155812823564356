import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import { I18n } from 'react-redux-i18n';
import {
  getStorages,
  createStorage,
  updateStorage,
  deleteStorage,
} from './StorageActions';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import SelectField from '../components/Fields/SelectField';
import Filter from '../components/Filter/Filter';
import { find } from 'lodash';

class StorageList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      selected_type: '',
    };
  }
  

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const storage_limit: number = JSON.parse(retrievedObject).storage_limit;
    const filter: any = cloneDeep(this.props.filter);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      filter[indexLimit].value = storage_limit || 1000;
    } else {
      filter.push({ attribute: 'limit', value: 1000 });
    }

    this.setState({
      selected_type: (filter.find((item: any) => item.attribute === 'storage_type') || { value: '' }).value,
    })

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }
    this.props.getStorages(filter);
  }

  onChangeFilter = (filter: any) => {
    this.props.getStorages(filter);
    let value = '';
    filter.forEach((item: any) => {
      value = item.attribute === 'storage_type' ? item.value : '';
    });
    this.setState({selected_type : value});
  }

  updateItem = (data: any) => {
    this.props.updateStorage(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteStorage(data);
  }

  createItem = (data: any) => {
    this.props.createStorage({...data});
  }

  onChange = (pagination: any, filters: any, sorter: any) => {

    const filter: any = cloneDeep(this.props.filter);

    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      (indexSkip > -1) ? filter[indexSkip].value = skip : filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      this.props.getStorages(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getStorages(filter);
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'storage_type') {
          options = this.props.storage_types.map((item: any) => ({
            value: item.id.toString(),
            title: item.title,
          }));
        }
        return <SelectField
          {...props}
          options={options}
        />;
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'storage_type':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };

      default:
        return {
          inputType: 'text',
        };
    }
  }

  public render() {
    const { storages, filter, total_count, storage_types } = this.props;

    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    const pagination = (total_count <= limit)
      ? false
      : {
        total : total_count + Math.ceil(total_count / limit),
        pageSize: limit + 1,
      };

    const validateRules = [
      {name: 'name', rules: ['required']},
      {name: 'storage_type', rules: ['required']}
    ];

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: I18n.t('storage.Name'),
        dataIndex: 'name',
        editable: true,
      },
      {
        title: I18n.t('storage.Storage type'),
        dataIndex: 'storage_type',
        editable: true,
        render: (value: any, record: any) => {
          const data = (find(storage_types, ['id', parseInt(value, 10)]) || { short_title: '' }).short_title;
          return {
            props: { style: { background: record.row_color } },
            children: <div>{data}</div>,
          };
        },
      },
    ];

    return (
      <div className = "small-table simple-actions">
        <Filter
          onChange={this.onChangeFilter}
          initialValues={filter}
          attributes={[
            {
              name: 'storage_type',
              title:I18n.t('storage.Storage type'),
              hyper_type: 'select',
              options: storage_types.map((item: any) => {
                return {
                  title: item.title,
                  value: item.id.toString(),
                };
              }),
            },
          ]}
        />
        <div style={{ height: 16 }} />
        <Row style = {{overflow: 'auto'}} className = "small-table no-status simple-actions">
          <OrderItemsEditable
            onChange={this.onChange}
            insertNewRow="first"
            btnCreateDisabled={false}
            items={storages}
            create={this.createItem}
            update={this.updateItem}
            delete={this.deleteItem}
            validateRules={validateRules}
            onTableCell={this.onTableCell}
            columns={columns}
            initionalNewRowValues={{storage_type: this.state.selected_type || undefined}}
            showNewLine={true}
            btnCreateShow={false}
            pagination={pagination}
            actionUpdateShow={(item: any) => !item.processed}
            actionDeleteShow={(item: any) => !item.processed}
            operationsOnLeft = {true}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.storage.filter,
  total_count: state.storage.total_count,
  storages: state.storage.list_data,
  storage_types: state.started_data.dictionaries.filter((dictionary: any) => dictionary.d_type === 'storage_type') || []
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createStorage,
      updateStorage,
      deleteStorage,
      getStorages,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(StorageList);
