import {
    GET_ACCOUNTS,
    ACCOUNTS_RECEIVED,
    CREATE_ACCOUNT_RECEIVED,
    UPDATE_ACCOUNT_RECEIVED,
    DELETE_ACCOUNT_RECEIVED,
  } from '../types/AccountTypes';
import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_ACCOUNTS_FILTERS } from '../types/Constants';

export interface AccountState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  total_count: number;
}

const defaultState: AccountState = {
    loading: false,
    list_data: [],
    total_count: 0,
    filter: getValueByKey(STORAGE_ACCOUNTS_FILTERS ,[
      {
        attribute: 'include_count',
        value: true,
      },
      {
        attribute: 'limit',
        value: 1000,
      },
      {
        attribute: 'sort',
        value: '-createdAt',
      },
    ]),
  };

export default (state: AccountState = defaultState, action: any) => {
    let tmpList: any = cloneDeep(state.list_data);
    switch (action.type) {
      case GET_ACCOUNTS:
        return {...state, loading: true};
      case ACCOUNTS_RECEIVED:
        const total_count = action.count > -1 ? action.count : state.total_count;
        return {...state, loading: false,
          list_data: action.data,
          filter: action.filter || state.filter,
          total_count,
        };
      case CREATE_ACCOUNT_RECEIVED:
        return {...state, list_data: [action.data , ...state.list_data ], total_count: state.total_count + 1 };
      case UPDATE_ACCOUNT_RECEIVED:
        return {...state,
            list_data: state.list_data.map((item: any) => item.id === action.data.id ? action.data : item )};
      case DELETE_ACCOUNT_RECEIVED:
          tmpList = tmpList.filter((item: any) => item.id !== action.id);
          return {...state, list_data: tmpList, total_count: state.total_count - 1};
      default:
         return state;
    }
  };
