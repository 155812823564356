import * as React from 'react';
import { Form, Select } from 'antd';

class SelectField extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      options: [],
    };
  }

  componentDidMount() {
    //
  }

  public render() {
    const {
      errors,
      touched,
      handleBlur,
      handleChange,
      inputProps,
      title,
      placeholder,
      isSubmit,
      name,
      values,
    } = this.props;
    const options = this.props.options || [];
    if (this.state.options.length) {
      this.state.options.forEach((element: any) => {
        options.push(element);
      });
    }

    const validateStatus = errors[name] && (touched[name] || isSubmit) ? 'error' : '';

    return (
      <Form.Item
        validateStatus={validateStatus}
      >
        <Select
            style={{ width: '100%' }}
            {...inputProps}
            showSearch
            placeholder={placeholder || title}
            optionFilterProp="children"
            allowClear={true}
            value={values[name]}
            onBlur={(value: any) => {
              handleBlur({target: {name, value}});
            }}
            onInputKeyDown ={(e: any) => {
              if (e.keyCode == 13) {
                this.setState({options: [...this.state.options, {title: e.target.value, value: e.target.value}]});
                handleChange({
                  target: {
                    name,
                    value: e.target.value,
                  },
                });
              }
            }}
            onChange={(value: any) => {
              handleChange({
                target: {
                  name,
                  value,
                },
              });
            }}
            filterOption={(input: any, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {options.map((option: any, index: number) =>
              <Select.Option key = {name + '-' + index } value={option.value}>
                {option.title}
              </Select.Option>)}
          </Select>
      </Form.Item>
    );
  }
}

export default SelectField;
