import {
    GET_DIALOG_PRODUCTS,
    SET_SELECTED_DIALOG_CATEGORY_ID,
  } from '../../../types/ProductDialogTypes';

export const getDialogProducts = (filter: any = []) => ({
  type: GET_DIALOG_PRODUCTS,
  filter,
});

export const setSelectedDialogCategoryId = (id: any) => ({
  type: SET_SELECTED_DIALOG_CATEGORY_ID,
  id,
});
