import { Table, Popconfirm, Button } from 'antd';
import EditableFormRow from './EditableFormRow';
import EditableCell from './EditableCell';
import './OrderItemsEditable.scss';
import { I18n } from 'react-redux-i18n';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { createContext, useEffect, useState } from 'react';

const EditableContext = createContext({});

export interface PropsComponent {
  delete: any;
  create: any;
  update: any;
  btnCreateDisabled?: boolean;
  products?: any[];
  columns: any[];
  validateRules: any[];
  onTableCell?: any;
  insertNewRow?: string;
  initionalNewRowValues?: any;
  btnCreateShow?: boolean;
  propsTable?: any;
  showNewLine?: boolean;
  showOperations?: boolean;

  // is not used but passed
  enableReinitialize?: boolean;

  actionDeleteShow?: (record: any) => boolean;
  actionUpdateShow?: (record: any) => boolean;
  operationsOnLeft?: boolean;
  onChange?: (...args: any[]) => any;
  pagination?: any;
  footer?: any;
  expandedRowRender?: any;
  changeStatus?: (...args: any[]) => any;
  items: any[];
}

const defaultItem: any = {
  id: 'new',
};

function OrderItemsEditable({
  delete: deleteRecord = () => {
    //
  },
  create = () => {
    //
  },
  update = () => {
    //
  },
  btnCreateDisabled = true,
  columns = [],
  validateRules = [],
  onTableCell = () => ({}),
  insertNewRow = 'last',
  initionalNewRowValues,
  btnCreateShow = true,
  propsTable = {},
  showNewLine,
  showOperations = true,
  actionDeleteShow = () => true,
  actionUpdateShow = () => true,

  operationsOnLeft,
  onChange,
  pagination,
  footer,
  expandedRowRender,
  changeStatus,
  items = [],
}: PropsComponent) {
  const [editingKey, setEditingKey] = useState('');
  const [editingData, setEditingData] = useState({});
  const [newData, setNewData] = useState(initionalNewRowValues || {});
  const [isInserting, setIsInserting] = useState(!!showNewLine);

  useEffect(() => {
    setIsInserting(!!showNewLine);
  }, [showNewLine]);
  useEffect(() => {
    setNewData(initionalNewRowValues || {});
  }, [initionalNewRowValues]);

  const isEditing = (record: any) => record.id === editingKey || record.id === 'new';

  const save = (values: any) => {
    if (!values.id || values.id === 'new') {
      create(values);
      setNewData({});
    } else {
      update(values);
    }
    setEditingKey('');
    setIsInserting(!!showNewLine);
  };

  const cancel = (data: any) => {
    if (data.id === 'new') {
      setIsInserting(!!showNewLine);
      setNewData({});
    } else {
      setEditingKey('');
      setIsInserting(!!showNewLine);
    }
  };

  const edit = (data: any) => {
    if (data.id === 'new') {
      setNewData(data);
    } else {
      setEditingKey(data.id);
      setEditingData(data);
    }
  };

  const add = () => {
    setIsInserting(true);
    setNewData(initionalNewRowValues);
  };

  const customExpandIcon = (props: any) => {
    return props.expanded ? (
      <Button
        type="link"
        icon={<CaretUpOutlined />}
        onClick={(e: any) => {
          props.onExpand(props.record, e);
        }}
      />
    ) : (
      <Button
        type="link"
        icon={<CaretDownOutlined />}
        onClick={(e: any) => {
          props.onExpand(props.record, e);
        }}
      />
    );
  };

  let columnsTmp: any = [...columns];

  if (showOperations) {
    const operations = {
      title: I18n.t('order_item_editable.Operation'),
      dataIndex: 'operation',
      render: (text: any, record: any) => {
        const editable = isEditing(record);
        return {
          props: { style: { background: record.row_color } },
          children: (
            <div style={{ padding: '6px 0' }}>
              {editable ? (
                <span style={{ whiteSpace: 'nowrap' }}>
                  <EditableContext.Consumer>
                    {(formik: any) => <Button icon={<SaveOutlined />} type="link" onClick={formik.handleSubmit} />}
                  </EditableContext.Consumer>
                  <span> </span>
                  <Button icon={<CloseOutlined />} type="link" onClick={() => cancel(record)} />
                </span>
              ) : (
                <span style={{ whiteSpace: 'nowrap' }}>
                  {operationsOnLeft &&
                    changeStatus &&
                    (record.processed ? (
                      <Button type="link" icon={<StopOutlined />} onClick={() => changeStatus(record.id, false)} />
                    ) : (
                      <Button type="link" icon={<CheckOutlined />} onClick={() => changeStatus(record.id, true)} />
                    ))}
                  {actionUpdateShow(record) && (
                    <Button type="link" icon={<EditOutlined />} onClick={() => edit(record)} />
                  )}
                  {actionDeleteShow(record) && (
                    <Popconfirm
                      title={I18n.t('order_item_editable.Sure to delete?')}
                      onConfirm={() => deleteRecord(record.id)}
                      cancelText={I18n.t('order_item_editable.Cancel')}
                      okText={I18n.t('order_item_editable.Ok')}
                    >
                      <Button type="link" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  )}
                </span>
              )}
            </div>
          ),
        };
      },
    };

    operationsOnLeft ? columnsTmp.unshift(operations) : columnsTmp.push(operations);
  }

  columnsTmp = columnsTmp.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any, _index: number) => ({
        ...(col.onCell ? col.onCell(record, _index) : onTableCell(col, record)),
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const components: any = {
    body: {
      row: (props: any) => (
        <EditableFormRow
          {...props}
          newData={newData}
          editingData={editingData}
          save={save}
          EditableContext={EditableContext}
          validateRules={validateRules}
        />
      ),
      cell: (props: any) => <EditableCell {...props} EditableContext={EditableContext} />,
    },
  };

  const dataSource = isInserting
    ? // eslint-disable-next-line no-mixed-operators
      (insertNewRow === 'first' && [defaultItem, ...items]) || [...items, defaultItem]
    : items;

  return (
    <div style={{ width: '100%' }}>
      {btnCreateShow && (
        <Button
          key="btn-create"
          type="primary"
          ghost
          style={{ marginBottom: '5px' }}
          // shape="circle"
          icon="plus"
          disabled={btnCreateDisabled}
          className=""
          onClick={() => add()}
        >
          New line
        </Button>
      )}
      <Table
        {...propsTable}
        bordered={true}
        onChange={onChange}
        components={components}
        className={'order-items'}
        key="item-order"
        dataSource={dataSource}
        columns={columnsTmp}
        rowClassName={(record: any) => 'editable-row'}
        rowKey={(record: any) => record.id}
        pagination={pagination && { showSizeChanger: false, ...pagination }}
        footer={footer}
        expandedRowRender={expandedRowRender}
        expandIcon={(props: any) => (expandedRowRender && props.record.createdAt ? customExpandIcon(props) : <div />)}
      />
    </div>
  );
}

export default OrderItemsEditable;
