import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import SalesOrderList from './SalesOrderList';
import SalesOrderView from './SalesOrderView';
import SalesOrderCreate from './SalesOrderCreate';
import SalesOrderUpdate from './SalesOrderUpdate';
import './SalesOrder.scss';

function SalesOrder({ loading, loading_counterparty, loading_storage }: any) {
  return (
    <Spin spinning={loading || loading_storage || loading_counterparty}>
      <Switch>
        <Route exact path="/sales_order/list" component={SalesOrderList} />
        <Route exact path="/sales_order/view/:id" component={SalesOrderView} />
        <Route exact path="/sales_order/update/:id" component={SalesOrderUpdate} />
        <Route exact path="/sales_order/create" component={SalesOrderCreate} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.sales_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(SalesOrder);
