import {
  GET_PAYMENTS, UPDATE_PAYMENT, CREATE_PAYMENT, DELETE_PAYMENT
} from '../../types/PaymentTypes';

export const getPayments = (filter: any = []) => ({
  type: GET_PAYMENTS,
  filter,
});

export const updatePayment = (id: number, data: any) => ({
  type: UPDATE_PAYMENT,
  data,
  id,
});

export const createPayment = (data: any, filter: any) => ({
  type: CREATE_PAYMENT,
  data,
  filter,
});

export const deletePayment = (id: any) => ({
  type: DELETE_PAYMENT,
  id,
});
