import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import './Home.scss';
import { getNews } from './HomeActions';
import { I18n } from 'react-redux-i18n';

function Home() {

  return (
      <div className="home">
        {I18n.t('home')}
      </div>
  );
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getNews,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
