import * as React from 'react';
import { Layout, Spin } from 'antd';
import SideBar from './components/Menu/Menu';
import Error from '../../components/Error/Error';
import { Route, Switch, Redirect } from 'react-router';
import Product from '../../Product/Product';
import PurchaseOrder from '../../PurchaseOrder/PurchaseOrder';
import Home from '../../Home/Home';
import Category from '../../Category/Category';
import SalesOrder from '../../SalesOrder/SalesOrder';
import Dictionary from '../../Dictionary/Dictionary';
import Bill from '../../Bill/Bill';
import Account from '../../Account/Account';
import Counterparty from '../../Counterparty/Counterparty';
import Payment from '../../Payment/Payment';
import Transfer from '../../Transfer/Transfer';
import Report from '../../Report/Report';
import Storage from '../../Storage/Storage';
import DefaultValue from '../../DefaultValue/DefaultValue';
import { getStartedData, isMobile } from '../StartedDataAction';
import { AppState } from '../../../reducers';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isMobileDevice } from 'react-select/lib/utils';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import TransferOrder from '../../TransferOrder/TransferOrder';

const { Header, Content } = Layout;

class Default extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    this.props.getStartedData();
    this.props.isMobile();
    this.setState({collapsed: isMobileDevice()});
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.is_mobile && !this.props.is_mobile ) {
      this.setState({collapsed: true});
    }
  }

  public render() {
    const {loading} = this.props;

    return (
      <div>
        <Error/>
        <Spin spinning={ loading }>
          <Layout  style={{minHeight: '100vh'}}>
            <Layout style={{ flexDirection: 'row' }}>
              <SideBar
                collapsed={this.state.collapsed}
                isMobile={this.props.is_mobile}
                location = {this.props.location}
                hideMenu = {() => this.props.is_mobile ? this.setState({ collapsed: true}) : ''}
              />
              <Layout style= {{  }}>
                <Header>
                  {this.state.collapsed && <MenuUnfoldOutlined
                    className="trigger"
                    style = {{color: 'white'}}
                    onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                  />}
                  {this.state.collapsed && <MenuFoldOutlined
                    className="trigger"
                    style = {{color: 'white'}}
                    onClick={() => this.setState({ collapsed: !this.state.collapsed })}
                  />}
                </Header>
                <Content style={{ // section
                  background: '#fff', padding: 24, minHeight: 280, margin: this.props.is_mobile ? '0' : '0px 24px 24px',
                }}
                >
                  <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/product" component={Product}/>
                    <Route path="/purchase_order" component={PurchaseOrder}/>
                    <Route path="/category" component={Category}/>
                    <Route path="/dictionary" component={Dictionary}/>
                    <Route path="/sales_order" component={SalesOrder}/>
                    <Route path="/transfer_order" component={TransferOrder}/>
                    <Route path="/bill" component={Bill}/>
                    <Route path="/payment" component={Payment}/>
                    <Route path="/account" component={Account}/>
                    <Route path="/counterparty" component={Counterparty}/>
                    <Route path="/transfer" component={Transfer}/>
                    <Route path="/report" component={Report}/>
                    <Route path="/storage" component={Storage}/>
                    <Route path="/default_value" component={DefaultValue}/>
                    <Route render={() => {
                      return <Redirect to="/"/>;
                    }}/>
                  </Switch>
                </Content>
                {/* <Footer  style={{ textAlign: 'center' }}>
                  Ant Design ©2018 Created by Ant UED
                </Footer> */}
              </Layout>
            </Layout>
          </Layout>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  loading: state.started_data.loading,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    getStartedData,
    isMobile,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Default);
