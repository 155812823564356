import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Modal } from 'antd';
import { AppState } from '../../../../reducers';
import { setShowModal, setReadingMode, clearOrder } from '../../SalesOrderActions';
import SalesOrderCreateContent from '../SalesOrderCreateContent/SalesOrderCreateContent';

function ModalComponent({ show_modal, title, loading, is_mobile, setShowModal, setReadingMode, clearOrder }: any) {

  const handleOk = () => {
    setShowModal(false);
    setReadingMode(false);
  }

  const handleCancel = () => {
    setShowModal(false);
    setReadingMode(false);
    clearOrder();
  }

  return (
    <Modal
      mask={true}
      maskClosable={false}
      title={title}
      width={is_mobile ? '100%' : '90%'}
      open={show_modal && !loading}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
      forceRender
    >
      <SalesOrderCreateContent/>
    </Modal>
  );
}

const mapStateToProps = (state: AppState) => ({
  show_modal: state.sales_order.show_modal,
  loading: state.sales_order.loading,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowModal,
      setReadingMode,
      clearOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ModalComponent);
