export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const PRODUCT_RECEIVED = 'PRODUCT_RECEIVED';
export const PRODUCTS_RECEIVED = 'PRODUCTS_RECEIVED';
export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT_RECEIVED = 'DELETE_PRODUCT_RECEIVED';
export const CREATE_PRODUCT_RECEIVED = 'CREATE_PRODUCT_RECEIVED';
export const UPDATE_PRODUCT_RECEIVED = 'UPDATE_PRODUCT_RECEIVED';

export const SET_SELECTED_CATEGORY_ID = 'SET_SELECTED_CATEGORY_ID';
export const SET_PRODUCT_SHOW_MODAL = 'SET_PRODUCT_SHOW_MODAL';
