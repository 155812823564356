import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrder, setReadingMode } from './PurchaseOrderActions';
import { AppState } from '../../reducers';
import PurchaseOrderCreateContent from './components/PurchaseOrderCreateContent/PurchaseOrderCreateContent';
import { useEffect } from 'react';

function PurchaseOrderView({ match, getOrder, setReadingMode }: any) {
  useEffect(() => {
    if (match?.params?.id) {
      getOrder(match.params.id, []);
    }
    setReadingMode(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PurchaseOrderCreateContent />;
}

const mapStateToProps = (state: AppState) => ({
  items: state.purchase_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setReadingMode,
      getOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderView);
