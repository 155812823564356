// import purchase_order_en from '../containers/PurchaseOrder/translate/en';
// import purchase_order_ru from '../containers/PurchaseOrder/translate/ru';
import general_ua from './general.ua';
import errors_ua from './errors.ua';
import purchase_order_ua from '../containers/PurchaseOrder/translate/ua';
import sales_order_ua from '../containers/SalesOrder/translate/ua';
import transfer_order_ua from '../containers/TransferOrder/translate/ua';
import product_ua from '../containers/Product/translate/ua';
import account_ua from '../containers/Account/translate/ua';
import layout_ua from '../containers/Layout/translate/ua';
import payment_ua from '../containers/Payment/translate/ua';
import bill_ua from '../containers/Bill/translate/ua';
import dictionary_ua from '../containers/Dictionary/translate/ua';
import category_ua from '../containers/Category/translate/ua';
import counterparty_ua from '../containers/Counterparty/translate/ua';
import product_dialog_ua from '../containers/components/ProductDialog/translate/ua';
import transfer_ua from '../containers/Transfer/translate/ua';
import report_ua from '../containers/Report/translate/ua';
import storage_ua from '../containers/Storage/translate/ua';
import order_item_editable_ua from '../containers/components/OrderItemsEditable/translate/ua';
import filter_form_ua from '../containers/components/Filter/translate/ua';
import default_values_ua from '../containers/DefaultValue/translate/ua';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // en: {
  //   // purchase_order: purchase_order_en,
  // },
  // ru: {
  //   purchase_order: purchase_order_ru,
  // },
  ua: {
    general: general_ua,
    errors: errors_ua,
    purchase_order: purchase_order_ua,
    sales_order: sales_order_ua,
    transfer_order: transfer_order_ua,
    product: product_ua,
    account: account_ua,
    layout: layout_ua,
    payment: payment_ua,
    bill: bill_ua,
    dictionary: dictionary_ua,
    counterparty: counterparty_ua,
    category: category_ua,
    product_dialog: product_dialog_ua,
    transfer: transfer_ua,
    report: report_ua,
    storage: storage_ua,
    order_item_editable: order_item_editable_ua,
    filter_form: filter_form_ua,
    default_values: default_values_ua,
  },
};
