import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PaymentTable from './components/PaymentTable/PaymentTable';
import { getPayments } from './PaymentActions';
import { AppState } from '../../reducers';
import Filter from '../components/Filter/Filter';
import { I18n } from 'react-redux-i18n';
import { getDefaultValues } from '../DefaultValue/DefaultValueActions';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import { Space } from 'antd';
import './style.scss';

function PaymentList({ filter, counterparts, accounts, dictionaries, is_mobile, getPayments, getDefaultValues }: any) {
  useEffect(() => {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const payment_limit: number = JSON.parse(retrievedObject).payment_limit;
    const _filter: any = cloneDeep(filter);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      _filter[indexLimit].value = payment_limit || 10;
    } else {
      _filter.push({ attribute: 'limit', value: 10 });
    }

    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      _filter[indexSkip].value = 0;
    }

    getPayments(_filter);
    getDefaultValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%', paddingBottom: is_mobile ? '100px' : '' }}>
      <Filter
        onChange={getPayments}
        initialValues={filter}
        attributes={[
          {
            name: 'date',
            title: I18n.t('payment.Date'),
            hyper_type: 'date',
            comparison: 'date_is_within',
          },
          // {name: 'order_name', title: 'Name ddf', hyper_type: 'input', comparison: 'contains'},
          {
            name: 'counterparty_id',
            title: I18n.t('payment.Counterparty'),
            hyper_type: 'select',
            options: counterparts.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          {
            name: 'account_id',
            title: I18n.t('payment.Account'),
            hyper_type: 'select',
            options: accounts.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          {
            name: 'p_type',
            title: I18n.t('payment.Type'),
            hyper_type: 'select',
            options: dictionaries
              .filter((item: any) => item.d_type === 'payment')
              .map((item: any) => {
                return {
                  title: item.title,
                  value: item.id,
                };
              }),
          },
        ]}
      />
      <PaymentTable />
    </Space>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.payment.filter,
  counterparts: state.started_data.counterparties,
  accounts: state.started_data.accounts,
  dictionaries: state.started_data.dictionaries,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPayments,
      getDefaultValues,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
