import {
  GET_SALES_ORDERS,
  SALES_ORDERS_RECEIVED,
  SET_SALES_ORDERS_FILTER,
  GET_SALES_ORDER,
  SALES_ORDER_RECEIVED,
  CREATE_SALES_ORDER,
  // SALES_ORDER_DATA_CREATE,
  // SALES_ORDER_DATA_CREATE_RECEIVED,
  CREATE_SALES_ORDER_ITEM_RECEIVED,
  UPDATE_SALES_ORDER_ITEM_RECEIVED,
  DELETE_SALES_ORDER_ITEM_RECEIVED,
  SALES_ORDER_ITEMS_RECEIVED,
  SALES_ORDER_CLEAR_DATA,
  SALES_ORDER_PROCESSED,
  SALES_ORDER_PROCESSED_RECEIVED,
  SET_SHOWMODAL_SALES_ORDER,
  SET_SALES_ORDER_READING_MODE,
  SALES_ORDER_UPDATE,
  SALES_ORDER_SET_TOTALS,
  CREATE_SALES_ORDER_RECEIVED,
  UPDATE_SALES_ORDER_RECEIVED,
} from '../types/SalesOrderTypes';
import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_SALES_ORDERS_FILTERS } from '../types/Constants';
import moment from 'moment';

export interface SalesOrdersState {
  loading: boolean;
  logined: boolean;
  list_data: any[];
  filter: any[];
  items: any[];
  order: any;
  totals: any;
  total_count: number;
  show_modal: boolean;
  isReadingMode: boolean;
  created_order_id: number;
}

const defaultState: SalesOrdersState = {
  loading: false,
  logined: true,
  list_data: [],
  order: {},
  totals: {
    total: 0,
  },
  items: [],
  total_count: 0,
  show_modal: false,
  isReadingMode: false,
  created_order_id: 0,
  filter: getValueByKey(STORAGE_SALES_ORDERS_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 10,
    },
    {
      attribute: 'populate',
      value: 'storage,counterparty',
    },
    {
      attribute: 'sort',
      value: '-date',
    },
    {
      attribute: 'date',
      comparison: 'date_is_within',
      value: {
        from: moment().toJSON(),
        to: moment().toJSON(),
      }
    }
  ]),
};

export default (state: SalesOrdersState = defaultState, action: any) => {
  let tmpItems: any = cloneDeep(state.items);
  let tmpListData: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_SALES_ORDERS:
      return {...state, loading: true, created_order_id : 0 };
    case SALES_ORDER_SET_TOTALS:
      return {...state, totals: action.totals};
    case CREATE_SALES_ORDER_RECEIVED:
      return {...state,
        list_data: [action.order, ...state.list_data],
        created_order_id : action.order.id,
        loading: false,
        order: action.order,
        total_count: state.total_count + 1 ,
      };
    case UPDATE_SALES_ORDER_RECEIVED:
      return {...state,
        order: action.order,
        list_data: state.list_data.map((order: any) => order.id === action.id ? action.order : order),
        created_order_id : 0,
        loading: false};
    case GET_SALES_ORDER:
      return {...state, loading: true};
    case SET_SALES_ORDERS_FILTER:
      return {...state, filter: action.filter};
    case SALES_ORDER_UPDATE:
      tmpListData = tmpListData.map((item: any) => {
        if (item.id === action.item.id) {
          item = Object.assign({}, item, action.item);
        }
        return item;
      });
      return {...state, list_data: tmpListData};
    case SALES_ORDERS_RECEIVED:
      return {...state,
        loading: false,
        list_data: action.data,
        total_count: action.count,
        totals: action.totals,
      };
    case SALES_ORDER_RECEIVED:
      return {...state, loading: false, order: action.order};
    case CREATE_SALES_ORDER_ITEM_RECEIVED:
      return {...state, items: [...state.items, action.item]};
    case UPDATE_SALES_ORDER_ITEM_RECEIVED:
      return {...state, items: state.items.map((item: any) => item.id === action.id ? action.item : item)};
    case DELETE_SALES_ORDER_ITEM_RECEIVED:
      tmpItems = tmpItems.filter((item: any) => item.id !== action.id);
      return {...state, items: tmpItems};
    case SALES_ORDER_ITEMS_RECEIVED:
      return {...state, items: action.items};
    case SALES_ORDER_CLEAR_DATA:
      return defaultState;
    case SALES_ORDER_PROCESSED:
      return {...state, loading: true};
    case SALES_ORDER_PROCESSED_RECEIVED:
      return {...state,
        loading: false,
        list_data: tmpListData.map((item: any) =>
          item.id === action.order.id ? Object.assign(item, {processed: action.order.processed}) : item,
        ),
      };
    case SET_SHOWMODAL_SALES_ORDER:
      return {...state, show_modal: action.status};
    case SET_SALES_ORDER_READING_MODE:
      return {...state, isReadingMode: action.status};
    default:
       return state;
  }
};
