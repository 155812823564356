export default {
    'Date': 'Дата',
    'Processed': 'Проведено',
    'Total': 'Сума у гривнях',
    'Base total': 'Базова сума',
    'Counterparty': 'Контрагенти',
    'Account': 'Рахунок',
    'Operation': 'Дія',
    'Type': 'Тип',
    'Note': 'Примітка',
    'Save': 'Зберегти',
    'Cancel': 'Закрити',
    'Currency name': 'Валюта',
    'Currency rate': 'Курс',
    'Summary Total': 'Сума',
};
