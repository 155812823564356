import moment from 'moment';

export let periods = [
    {
        name: 'Today',
        from: moment().toJSON(),
        to: moment().toJSON(),
    },
    {
        name: 'Yesterday',
        from: moment().add(-1, 'd').toJSON(),
        to: moment().add(-1, 'd').toJSON(),
    },
    {
        name: 'This week',
        from: moment().startOf('week').format('dddd') === 'Sunday' ?
            moment().startOf('week').add(1, 'd').toJSON() :
            moment().startOf('week').toJSON(),
        to: moment().toJSON(),
    },
    {
        name: 'Previous week',
        from: moment().startOf('week').format('dddd') === 'Sunday' ?
            moment().subtract(1, 'weeks').startOf('week').add('d', 1).toJSON() :
            moment().subtract(1, 'weeks').startOf('week').toJSON(),

        to: moment().startOf('week').format('dddd') === 'Sunday' ?
            moment().subtract(1, 'weeks').endOf('week').add('d', 1).toJSON() :
            moment().subtract(1, 'weeks').endOf('week').toJSON(),
    },
    {
        name: 'Last 7 days',
        from: moment().subtract(1, 'week').toJSON(),
        to: moment().toJSON(),
    },
    {
        name: 'This month',
        from: moment().startOf('months').toJSON(),
        to: moment().toJSON(),
    },
    {
        name: 'Previous month',
        from: moment().subtract(1, 'months').startOf('months').toJSON(),
        to: moment().subtract(1, 'months').endOf('months').toJSON(),
    },
    {
        name: 'Last 30 days',
        from: moment().subtract(30, 'd').toJSON(),
        to: moment().toJSON(),
    },
];
