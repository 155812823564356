import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row, Space } from 'antd';
import Filter from '../components/Filter/Filter';
import { I18n } from 'react-redux-i18n';
import { getReportAccount, clearReport } from './ReportActions';
import { AppState } from '../../reducers';
import ReportAccountData from './components/ReportAccountData';
import moment from 'moment';

class ReportAccount extends React.Component<any, any> {
  public dateFrom: any = moment().toJSON();
  public dateTo: any = moment().toJSON();

  componentDidMount() {
    this.props.getReportAccount([
      {attribute: 'date_from', value: this.dateFrom},
      {attribute: 'date_to', value: this.dateTo},
    ]);
  }

  disabledStartDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!startValue || !endValue) {
      return false;
    }
    return !startValue || startValue.isAfter(endValue);
  }

  disabledEndDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!endValue || !startValue) {
      return false;
    }
    return !endValue || endValue.isBefore(startValue);
  }

  public render() {
    const {filter} = this.props;
    return (
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row>
          <Filter
            onChange={(values: any) => {
              this.dateFrom = (values.find((item: any) => item.attribute === 'date_from') || { value: '' }).value;
              this.dateTo = (values.find((item: any) => item.attribute === 'date_to') || { value: '' }).value;
              this.props.getReportAccount(values);
            }}
            rules={[
              { name: 'date_from', rules: ['required'] },
              { name: 'date_to', rules: ['required'] },
            ]}
            initialValues={filter}
            attributes={[
              {
                name: 'date_from',
                title: I18n.t('report.Date from'),
                label: I18n.t('report.Date from'),
                hyper_type: 'date',
                inputProps: {
                  allowClear: false,
                  disabledDate: (startValue: any) => {
                    return this.disabledStartDate(startValue, this.dateTo);
                  },
                },
              },
              {
                name: 'date_to',
                title: I18n.t('report.Date to'),
                label: I18n.t('report.Date to'),
                hyper_type: 'date',
                inputProps: {
                  allowClear: false,
                  disabledDate: (endValue: any) => {
                    return this.disabledEndDate(this.dateFrom, endValue);
                  },
                },
              },
            ]}
            showClear={false}
          />
        </Row>
        <Row>
          <ReportAccountData/>
        </Row>
      </Space>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.report.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportAccount,
      clearReport,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportAccount);
