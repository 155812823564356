import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import BillTable from './components/BillTable/BillTable';
import { getBills } from './BillActions';
import { AppState } from '../../reducers';
import Filter from '../components/Filter/Filter';
import { I18n } from 'react-redux-i18n';
import { getDefaultValues } from '../DefaultValue/DefaultValueActions';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import { Space } from 'antd';

function BillList({
  filter,
  counterparts,
  accounts,
  dictionaries,
  default_type,
  is_mobile,

  getBills,
  getDefaultValues,
}: any) {

  useEffect(() => {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const bill_limit: number = JSON.parse(retrievedObject).bill_limit;
    const _filter: any = cloneDeep(filter);

    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    if (indexLimit > -1) {
      _filter[indexLimit].value = bill_limit || 5;
    } else {
      _filter.push({ attribute: 'limit', value: 5 });
    }

    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      _filter[indexSkip].value = 0;
    }

    getBills(_filter);
    getDefaultValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeFilter = (filter: any) => {
    getBills(filter);
  }

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%', paddingBottom: is_mobile ? '100px' : '' }}>
      <Filter
        onChange={onChangeFilter}
        initialValues={filter}
        attributes={[
          {
            name: 'date',
            title: I18n.t('bill.Date'),
            hyper_type: 'date',
            comparison: 'date_is_within',
          },
          {
            name: 'counterparty_id',
            title: I18n.t('bill.Counterparty'),
            hyper_type: 'select',
            options: counterparts.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          { name: 'account_id',
            title: I18n.t('bill.Account'),
            hyper_type: 'select',
            options: accounts.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          { name: 'b_type',
            title: I18n.t('bill.Type'),
            hyper_type: 'select',
            options: dictionaries.map((item: any) => {
              return {
                title: item.short_title,
                value: item.id,
              };
            }),
          },
        ]}
      />
      <BillTable/>
    </Space>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.bill.filter,
  is_mobile: state.auth.is_mobile,
  counterparts: state.started_data.counterparties,
  accounts: state.started_data.accounts,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'bill'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators( {
    getBills,
    getDefaultValues,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(BillList);
