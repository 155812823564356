// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Required': 'Необхідно заповнити',
  'Not a valid e-mail address': 'Не коректний емейл',
  'Should be {n} symbols': 'Повинно бути {n} символів',
  'Not a valid number': 'Не коректне число',
  'Not a valid integer': 'Не коректне ціле число',
  'The number must be greater {n}': 'Число повинно бути більше ніж {n}',
  'The number must be greater than or equal to {n}': 'Число повинно бути більше або рівне {n}',
  'The number must be less {n}': 'Число повинно бути менше ніж {n}',
  'The number must be less than or equal to {n}': 'Число повинно бути менше або рівне {n}',
};
