export const GET_TRANSFERS = 'GET_TRANSFERS';
export const TRANSFERS_RECEIVED = 'TRANSFERS_RECEIVED';

export const CREATE_TRANSFER = 'CREATE_TRANSFER';
export const UPDATE_TRANSFER = 'UPDATE_TRANSFER';
export const DELETE_TRANSFER = 'DELETE_TRANSFER';
export const CREATE_TRANSFER_RECEIVED = 'CREATE_TRANSFER_RECEIVED';
export const UPDATE_TRANSFER_RECEIVED = 'UPDATE_TRANSFER_RECEIVED';
export const DELETE_TRANSFER_RECEIVED = 'DELETE_TRANSFER_RECEIVED';

export const SET_TRANSFERS_FILTER = 'SET_TRANSFERS_FILTER';
