import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PurchaseOrderTable from './components/PurchaseOrderTable/PurchaseOrderTable';
import { getOrders, setShowModal, clearOrder, clearOrderItems } from './PurchaseOrderActions';
import { AppState } from '../../reducers';
import Filter from '../components/Filter/Filter';
import { Button } from 'antd';
import Modal from './components/Modal/Modal';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import { useCallback, useEffect } from 'react';

function PurchaseOrderList({
  filter,
  counterparts,
  storages,
  types,
  getOrders,
  clearOrder,
  clearOrderItems,
  setShowModal,
}: any) {
  useEffect(() => {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const p_o_limit: number = JSON.parse(retrievedObject).p_o_limit;
    const _filter: any = cloneDeep(filter);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      _filter[indexLimit].value = p_o_limit || 10;
    } else {
      _filter.push({ attribute: 'limit', value: 10 });
    }

    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      _filter[indexSkip].value = 0;
    }

    getOrders(_filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFilter = useCallback(
    (filter: any) => {
      getOrders(filter);
    },
    [getOrders]
  );

  return (
    <div>
      <Modal title={I18n.t('purchase_order.Purchase Order Title')} />
      <Filter
        onChange={onChangeFilter}
        initialValues={filter}
        attributes={[
          {
            name: 'date',
            title: I18n.t('purchase_order.Date'),
            hyper_type: 'date',
            comparison: 'date_is_within',
          },
          // {name: 'order_name', title: 'Name ddf', hyper_type: 'input', comparison: 'contains'},
          {
            name: 'counterparty_id',
            title: I18n.t('general.Counterparty'),
            hyper_type: 'select',
            options: counterparts.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          {
            name: 'storage_id',
            title: I18n.t('purchase_order.Storage'),
            hyper_type: 'select',
            options: storages.map((item: any) => {
              return {
                title: item.name,
                value: item.id,
              };
            }),
          },
          {
            name: 'p_o_type',
            title: I18n.t('sales_order.Type'),
            hyper_type: 'select',
            options: types.map((item: any) => {
              return {
                title: item.short_title,
                value: item.id,
              };
            }),
          },
        ]}
      />
      <Button
        style={{
          margin: '16px 0',
        }}
        onClick={() => {
          clearOrder();
          clearOrderItems();
          setShowModal(true);
        }}
      >
        {I18n.t('purchase_order.Create')}
      </Button>
      <PurchaseOrderTable />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.purchase_order.filter,
  is_mobile: state.auth.is_mobile,
  storages: state.started_data.storages,
  counterparts: state.started_data.counterparties,
  types: state.started_data.dictionaries.filter((item: any) => item.d_type === 'purchase_order'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrders,
      setShowModal,
      clearOrder,
      clearOrderItems,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);
