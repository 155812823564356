import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../reducers';
import { Row } from 'antd';
import { getReportCounterpartyDetails } from './ReportActions';
import ReportCounterpartyDetailsData from './components/ReportCounterpartyDetailsData';
import { getAllUrlParams } from '../../lib/helper';
import { useEffect } from 'react';

function ReportCounterparty({ filter, counterparties, location, getReportCounterpartyDetails }: any) {
  useEffect(() => {
    const params = getAllUrlParams(location.search);
    getReportCounterpartyDetails(
      Object.keys(params).map((key: any) => {
        return {attribute: key, value: params[key]};
    }));
  }, [getReportCounterpartyDetails, location.search])

  return (
    <div>
      <Row>
        <ReportCounterpartyDetailsData/>
      </Row>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.report.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportCounterpartyDetails,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportCounterparty);
