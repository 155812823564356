import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import { STARTED_DATA_RECEIVED, GET_STARTED_DATA } from '../types/StartedDataTypes';
import moment from 'moment';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';

function* getStartedData() {
  try {
    const updates: GenericEntityResponse = yield restClient.get('auth/updates', '', '');
    const tableUpdates: GenericEntityResponse = JSON.parse(localStorage.getItem('tableUpdates') || '{}');

    let products: any = JSON.parse(localStorage.getItem('product') || '[]');
    if (! (tableUpdates.product && products.length
      && moment(updates.product).isSameOrBefore(tableUpdates.product))) {
        const tmpProducts: GenericEntitiesResponse = yield restClient.get('products', '',
          {fields: 'id,title,price,published', published: 'true', limit: 10000},
        );
        products = tmpProducts.data;
        localStorage.setItem('product', JSON.stringify(tmpProducts.data));
        tableUpdates.product = updates.product;
    }

    let dictionaries: any = JSON.parse(localStorage.getItem('dictionary') || '[]');
    if (! (tableUpdates.dictionary && dictionaries.length
      && moment(updates.dictionary).isSameOrBefore(tableUpdates.dictionary))) {
        const tmpDictionaries: GenericEntitiesResponse = yield restClient.get('dictionaries', '',
          {fields: 'id,slug,d_type,title,short_title,style', limit: 10000},
        );
        dictionaries = tmpDictionaries.data;
        localStorage.setItem('dictionary', JSON.stringify(tmpDictionaries.data));
        tableUpdates.dictionary = updates.dictionary;
    }

    let accounts: any = JSON.parse(localStorage.getItem('account') || '[]');
    if (! (tableUpdates.account && accounts.length
      && moment(updates.account).isSameOrBefore(tableUpdates.account))) {
        const tmpAccounts: GenericEntitiesResponse = yield restClient.get('accounts', '', {fields: 'id,name', limit: 10000});
        accounts = tmpAccounts.data;
        localStorage.setItem('account', JSON.stringify(tmpAccounts.data));
        tableUpdates.account = updates.account;
    }

    let counterparties = JSON.parse(localStorage.getItem('counterparty') || '[]');
    if (! (tableUpdates.counterparty && counterparties.length
      && moment(updates.counterparty).isSameOrBefore(tableUpdates.counterparty))) {
        const tmpCounterparties: GenericEntitiesResponse = yield restClient.get('counterparties', '',
          {fields: 'id,name,discount,c_type', limit: 10000},
        );
        counterparties = tmpCounterparties.data.map((item: any) => {
            item.slug = [];
            dictionaries.map((dictr: any) => {
              const slugs = item.c_type.split(',');
              slugs.map((slug: number) => {
                if (dictr.id == slug) {
                  item.slug.push( dictr.slug );
                }
              });
            });
            return {id: item.id, name: item.name, slug: item.slug, discount: item.discount };
          },
        );
        localStorage.setItem('counterparty', JSON.stringify(tmpCounterparties.data));
        tableUpdates.counterparty = updates.counterparty;
    }

    let storages = JSON.parse(localStorage.getItem('storage') || '[]');
    if (! (tableUpdates.storage && storages.length
      && moment(updates.storage).isSameOrBefore(tableUpdates.storage))) {
        const tmpStorages: GenericEntitiesResponse = yield restClient.get('storages', '', {fields: 'id,name', limit: 10000});
        storages = tmpStorages.data;
        localStorage.setItem('storage', JSON.stringify(tmpStorages.data));
        tableUpdates.storage = updates.storage;
    }

    let categories = JSON.parse(localStorage.getItem('category') || '[]');
    if (! (tableUpdates.category && categories.length
      && moment(updates.category).isSameOrBefore(tableUpdates.category))) {
        const tmpCategories: GenericEntitiesResponse = yield restClient.get('categories', '', {fields: 'id,name,parent_id', limit: 10000});
        categories = tmpCategories.data;
        localStorage.setItem('category', JSON.stringify(tmpCategories.data));
        tableUpdates.category = updates.category;
    }

    localStorage.setItem('tableUpdates', JSON.stringify(tableUpdates));

    yield put({ type: STARTED_DATA_RECEIVED,
        dictionaries,
        accounts,
        counterparties,
        categories,
        storages,
        products,
    });
  } catch (error) {
    yield put({ type: STARTED_DATA_RECEIVED,
      dictionaries: [],
      accounts: [],
      counterparties: [],
      categories: [],
      storages: [],
      products: [],
    });
    yield put({ type: ERROR, messages: ['Not get initial data']});
  }
}

export default function* actionWatcherDictionary() {
  yield takeLatest(GET_STARTED_DATA, getStartedData);
}
