import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import ProductList from './ProductList';

function Product({ loading, loading_category }: any) {

  return (
    <Spin spinning={ loading || loading_category }>
      <Switch>
        <Route exact path="/product/list" component={ProductList}/>
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.product.loading,
  loading_category: state.category.loading,
});

export default connect(mapStateToProps)(Product);
