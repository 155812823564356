export const GET_TRANSFER_ORDERS = 'GET_TRANSFER_ORDERS';
export const TRANSFER_ORDERS_RECEIVED = 'TRANSFER_ORDERS_RECEIVED';

export const SET_TRANSFER_ORDERS_FILTER = 'SET_TRANSFER_ORDERS_FILTER';

export const GET_TRANSFER_ORDER = 'GET_TRANSFER_ORDER';
export const TRANSFER_ORDER_RECEIVED = 'TRANSFER_ORDER_RECEIVED';

export const CREATE_TRANSFER_ORDER_ITEM = 'CREATE_TRANSFER_ORDER_ITEM';
export const UPDATE_TRANSFER_ORDER_ITEM = 'UPDATE_TRANSFER_ORDER_ITEM';
export const DELETE_TRANSFER_ORDER_ITEM = 'DELETE_TRANSFER_ORDER_ITEM';
export const UPDATE_TRANSFER_ORDER_ITEM_RECEIVED = 'UPDATE_TRANSFER_ORDER_ITEM_RECEIVED';
export const CREATE_TRANSFER_ORDER_ITEM_RECEIVED = 'CREATE_TRANSFER_ORDER_ITEM_RECEIVED';
export const DELETE_TRANSFER_ORDER_ITEM_RECEIVED = 'DELETE_TRANSFER_ORDER_ITEM_RECEIVED';
export const TRANSFER_ORDER_ITEMS_RECEIVED = 'TRANSFER_ORDER_ITEMS_RECEIVED';

export const CREATE_TRANSFER_ORDER = 'CREATE_TRANSFER_ORDER';
export const CREATE_TRANSFER_ORDER_RECEIVED = 'CREATE_TRANSFER_ORDER_RECEIVED';
export const UPDATE_TRANSFER_ORDER = 'UPDATE_TRANSFER_ORDER';
export const UPDATE_TRANSFER_ORDER_RECEIVED = 'UPDATE_TRANSFER_ORDER_RECEIVED';

// export const TRANSFER_ORDER_DATA_CREATE = 'TRANSFER_ORDER_DATA_CREATE';
// export const TRANSFER_ORDER_DATA_CREATE_RECEIVED = 'TRANSFER_ORDER_DATA_CREATE_RECEIVED';

export const TRANSFER_ORDER_CLEAR_DATA = 'TRANSFER_ORDER_CLEAR_DATA';

export const TRANSFER_ORDER_PROCESSED = 'TRANSFER_ORDER_PROCESSED';
export const TRANSFER_ORDER_PROCESSED_RECEIVED = 'TRANSFER_ORDER_PROCESSED_RECEIVED';
export const SET_SHOWMODAL_TRANSFER_ORDER = 'SET_SHOWMODAL_TRANSFER_ORDER';
export const TRANSFER_ORDER_UPDATE = 'TRANSFER_ORDER_UPDATE';
export const SET_TRANSFER_ORDER_READING_MODE = 'SET_TRANSFER_ORDER_READING_MODE';
export const TRANSFER_ORDER_SET_TOTALS = 'TRANSFER_ORDER_SET_TOTALS';
export const DELETE_TRANSFER_ORDER = 'DELETE_TRANSFER_ORDER';
