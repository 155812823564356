import * as React from 'react';
import { AppState } from '../../../../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TreeSelect, Tree } from 'antd';
import { setSelectedDialogCategoryId, getDialogProducts } from '../../ProductDialogActions';
import { createDataForTree, createNewTreeData } from '../../../../../lib/helper';
import { I18n } from 'react-redux-i18n';

const TreeNode = TreeSelect.TreeNode;

class ProductDialogTree extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  onSelect = (id: any) => {
    if (id[0]) {
      if (id[0] !== '0') {
          this.props.filter.push({attribute: 'category_id', value: id});
          this.props.getDialogProducts(this.props.filter.filter((item: any) => item.attribute !== 'skip'));
      } else {
          this.props.getDialogProducts( this.props.filter.filter((item: any) => item.attribute !== 'category_id' ));
      }
      this.props.setSelectedDialogCategoryId( parseInt(id, 10 ));
    }
  }

  renderTreeNodes = (data: any) => data.map((item: any) => {
    if (item.children) {
      return (
        <TreeNode
          title={item.name}
          key={item.id}
          value={item.id}
          dataRef={item} >
            {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode {...item} dataRef={item} />;
  })

  public render() {
    const {categories, selectedKey} = this.props;
    const treeData: any =  [{id : 0, title: I18n.t('product_dialog.All Categories'), key: '0', children : createNewTreeData(categories)}];

    return (
      <div style = {{ overflow: 'auto'}}>
        <Tree
          defaultExpandAll = {true}
          onSelect = {this.onSelect}
          selectedKeys = {selectedKey.toString().split()}
          treeData={treeData}
        >
        {/* {this.renderTreeNodes(treeData)} */}
        </Tree>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.dialog_product.filter,
  categories: state.started_data.categories,
  selectedKey: state.dialog_product.selected_category_id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators ({
    getDialogProducts,
    setSelectedDialogCategoryId,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialogTree);
