import { useCallback } from 'react';
import { Input, Button, Select, DatePicker, Row, Col, Form } from 'antd';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import locale from 'antd/es/date-picker/locale/uk_UA';
import { I18n } from 'react-redux-i18n';
import histoty from '../../../../lib/histoty';

function PurchaseOrderFormContent({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  counterparties,
  storages,
  // isSubmitting,
  loading,
  isReadingMode,
  currency,
  types,
  initialValues,
  setShowModal,
}: any) {

  let disableButton: boolean = false;
  if ( initialValues.id && isEqual(initialValues, values)) {
    disableButton = true;
  }

  const fieldError = useCallback((field: string) => {
    return errors[field] && touched[field] && { help : errors[field], validateStatus: 'error' };
  }, [errors, touched])

  return (
    <Form
      onFinish={handleSubmit}
      className="purchase-order-form"
      layout="vertical"
    >
      <h3 >{isReadingMode ? I18n.t('purchase_order.IS PROCESSED') : ''} </h3>
      <div className='purchaseCreate-modal'>
      <Row gutter={16}>
        <Col span={12} xs = {24}>
          <Form.Item
            { ...fieldError('date')}
            label={I18n.t('purchase_order.Date')}
          >
            <DatePicker
              locale={locale}
              disabled = {isReadingMode ? true : false}
              defaultValue={values.date && moment(values.date)}
              value={values.date && moment(values.date)}
              placeholder={I18n.t('purchase_order.Date')}
              onOpenChange={(status: any) => {
                if (!status) {
                  handleBlur({target: {name: 'date'}});
                }
              }}
              style={{width: '100%'}}
              onChange={(date) => {
                handleChange({
                  target: {
                    name: 'date',
                    value: date,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12} xs = {24}>
          <Form.Item
            { ...fieldError('counterparty_id')}
            label={I18n.t('general.Counterparty')}
          >
            <Select
              disabled = {isReadingMode ? true : false}
              showSearch
              style={{ width: '100%' }}
              placeholder={I18n.t('general.Counterparty')}
              optionFilterProp="children"
              allowClear={true}
              value={values.counterparty_id}
              onBlur={(value) => {handleBlur({target: {name: 'counterparty_id', value}}); }}
              onChange={(value: any) => {
                const index: number = counterparties.map((e: any) => e.value ).indexOf(value);
                if (index > -1) {
                  handleChange({ target: {name: 'discount', value: counterparties[index].discount || 0}});
                }
                handleChange({
                  target: {
                    name: 'counterparty_id',
                    value,
                  },
                });
              }}
              filterOption={(input: any, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {counterparties.map((option: any) =>
                <Select.Option key = {option} value={option.value}>{option.title}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8} xs = {12}>
          <Form.Item
            { ...fieldError('storage_id')}
            label={I18n.t('purchase_order.Storage')}
          >
            <Select
              disabled = {isReadingMode ? true : false}
              showSearch
              style={{ width: '100%' }}
              placeholder={I18n.t('purchase_order.Storage')}
              optionFilterProp="children"
              allowClear={true}
              value={values.storage_id}
              onBlur={(value) => {handleBlur({target: {name: 'storage_id', value}}); }}
              onChange={(value: any) => {
                handleChange({
                  target: {
                    name: 'storage_id',
                    value,
                  },
                });
              }}
              filterOption={(input: any, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {storages.map((option: any) =>
                <Select.Option key = {option.value} value={option.value}>{option.title}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs = {12}>
          <Form.Item
            { ...fieldError('p_o_type')}
            label={I18n.t('purchase_order.Type')}
          >
            <Select
              disabled = {isReadingMode ? true : false}
              showSearch
              style={{width: '100%'}}
              placeholder={I18n.t('purchase_order.Type')}
              optionFilterProp="children"
              allowClear={true}
              value = { values.p_o_type}
              onBlur={(value) => {handleBlur({target: {name: 'p_o_type', value}}); }}
              onChange={(value: string) => {
                handleChange({
                  target: {
                    name: 'p_o_type',
                    value,
                  },
                });
              }}
              filterOption={(input: any, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {types.map((option: any) =>
                <Select.Option key={option.value} value={option.value.toString()}>{option.title}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8} xs = {24}>
          <Form.Item
            // validateStatus={'error'}
            // help={'Error'}
            label={I18n.t('purchase_order.Note')}
          >
            <Input
              disabled = {isReadingMode ? true : false}
              placeholder={I18n.t('purchase_order.Note')}
              name={'note'}
              value={values.note}
              onChange={(e) => {
              handleChange({
                target: {
                  name: 'note',
                  value: e.target.value,
                },
              });
            }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            { ...fieldError('currency_name')}
            label={I18n.t('purchase_order.Currency name')}
          >
            <Select
              disabled = {isReadingMode ? true : false}
              showSearch
              style={{ width: '100%' }}
              placeholder={I18n.t('purchase_order.Currency name')}
              optionFilterProp="children"
              allowClear={true}
              value={values.currency_name }
              onBlur={(value ) => { handleBlur({target: {name: 'currency_name', value}}); }}
              onChange={(value ) => {
                handleChange({
                  target: {
                    name: 'currency_name',
                    value,
                  },
                });
              }}
              filterOption={(input: any, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {currency.map((option: any) =>
                <Select.Option key = {option} value={option.value.toString()}>{option.title}</Select.Option>)
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            { ...fieldError('currency_rate')}
            label={I18n.t('purchase_order.Currency rate')}
          >
            <Input
              disabled = {isReadingMode ? true : false}
              placeholder={I18n.t('purchase_order.Currency rate')}
              name={'currency_rate'}
              value={values.currency_rate}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'currency_rate',
                    value: e.target.value,
                  },
                });
            }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            {...fieldError('discount')}
            label={I18n.t('purchase_order.Discount')}
          >
            <Input
              disabled = {isReadingMode ? true : false}
              placeholder={I18n.t('purchase_order.Discount')}
              name={'discount'}
              value={values.discount}
              onBlur={(value) => {handleBlur({target: {name: 'discount', value}}); }}
              onChange={(e) => {
                handleChange({
                  target: {
                    name: 'discount',
                    value: e.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      { !isReadingMode ?
        <Form.Item >
          <Button
            disabled = {disableButton}
            htmlType="submit"
            type="primary"
            loading={loading}>
            {I18n.t('purchase_order.Save')}
          </Button>
          <Button htmlType="button" style={{marginLeft: '10px'}} type="default"
            onClick={() => {
              if (setShowModal) {
                setShowModal(false);
              }
              histoty.push('/purchase_order/list');
            }}>
            {I18n.t('purchase_order.Cancel')}
          </Button>
        </Form.Item>  : ''}
      </div>  
    </Form>
  );
}

export default PurchaseOrderFormContent;
