import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../reducers';
import { Table, Modal } from 'antd';
import moment from 'moment';
import findIndex from 'lodash/findIndex';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import ReportCounterpartyDetailsData from './ReportCounterpartyDetailsData';
import { getReportCounterpartyDetails } from '../ReportActions';

const renderNumber = (value: number) => (value || 0).formatNumber()

function ReportCounterpartyData({
  data,
  filter,
  loading,
  counterparties,
  is_mobile,
  
  getReportCounterpartyDetails,
}: any) {
  const [open, setOpen] = useState(false)

  let date_from;
  let date_to;

  filter.forEach((item: any) => {
    if (item.attribute === 'date_from') {
      date_from = item.value;
    }
    if (item.attribute === 'date_to') {
      date_to = item.value;
    }
  });

  const columns = [
    {
      title: I18n.t('report.Counterparty'),
      dataIndex: 'counterparty_id',
      key: 'counterparty_id',
      render: (counterpartyId: number) => {
        const index = findIndex(counterparties, ['id', counterpartyId]);
        return index > -1 ? counterparties[index].name : counterpartyId;
      },
    },
    {
      title: I18n.t('report.Remainder to', {date: moment(date_from).format('L')}),
      dataIndex: 'remainder',
      key: 'remainder',
      align: 'right' as any,
      onCell: (record: any) => {
        return {
          style: { color: record.remainder < 0 ? 'red' : 'unset' },
        };
      },
      render: renderNumber,
    },
    {
      title: I18n.t('report.Turnover'),
      children: [
        {
          title: I18n.t('report.Incoming'),
          dataIndex: 'incoming',
          key: 'incoming',
          align: 'right' as any,
          render: renderNumber,
        },
        {
          title: I18n.t('report.Outcoming'),
          dataIndex: 'outcoming',
          key: 'outcoming',
          align: 'right' as any,
          render: renderNumber,
        },
      ],
    },
    {
      title: I18n.t('report.Remainder to', {date: moment(date_to).format('L')}),
      align: 'right' as any,
      onCell: (item: any, _record: any) => {
        const balance = (typeof item.remainder === 'number' ? item.remainder : 0) + item.incoming - item.outcoming;
        return {
          style: { color: balance < 0 ? 'red' : 'unset' },
          // className: 'example-class-in-td',
        };
      },
      render: (item: any) => (
        ((typeof item.remainder === 'number' ? item.remainder : 0) + item.incoming - item.outcoming).formatNumber()
      ),
    },
  ];

  return (
    <>
      <Modal
        title={I18n.t('report.Intercompany by counterparty')}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        width={is_mobile ? '100%' : '90%'}
        // onCancel={this.handleCancel}
      >
        <ReportCounterpartyDetailsData/>
      </Modal>
      <div style = {{overflow: 'auto', width: '100%'}} className = "small-table">
        <Table
          columns={columns}
          dataSource={data}
          bordered
          loading={loading}
          pagination={false}
          rowKey={(record: any) => record.counterparty_id}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event: any) => {
                // let url = '/report/counterparty_details?counterparty_id=' + record.counterparty_id;
                // filter.forEach((item: any) => {
                //   url += '&' + item.attribute + '=' + item.value;
                // });
                // this.props.history.push(url);
                getReportCounterpartyDetails([...filter,
                  {attribute: 'counterparty_id', value: record.counterparty_id},
                ]);
                setOpen(true);
              },
            };
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.report.report,
  filter: state.report.filter,
  loading: state.report.loading,
  counterparties: state.started_data.counterparties,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportCounterpartyDetails,
    },
    dispatch,
);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportCounterpartyData) as any);
