import { TreeSelect } from 'antd';
import { I18n } from 'react-redux-i18n';


interface CategoryTreeSelectProps {
  treeData: any;
  value: any;
  onSelect: any;
}

function CategoryTreeSelect({ value, onSelect, treeData }: CategoryTreeSelectProps) {

  return (
    <TreeSelect
      treeDefaultExpandAll
      defaultValue = {0}
      placeholder= {I18n.t('category.Please Select')}
      style={{ width: '100%', marginBottom: 10 }}
      dropdownStyle={{ overflow: 'auto' }}
      value={value}
      onSelect={onSelect}
      treeData={treeData}
    />
  );
}

export default (CategoryTreeSelect);
