import {
  GET_REPORT_ACCOUNT_DETAILS,
  GET_REPORT_STORAGE_DETAILS,
  GET_REPORT_STORAGE_DETAILS_RECEIVED,
} from './../types/ReportTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import {
  GET_REPORT_ACCOUNT,
  GET_REPORT_RECEIVED,
  GET_REPORT_COUNTERPARTY,
  GET_REPORT_COUNTERPARTY_DETAILS,
  GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED,
  GET_REPORT_STORAGE,
  GET_REPORT_ACCOUNT_DETAILS_RECEIVED,
} from '../types/ReportTypes';
import generateFilter from '../lib/genFilter';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';

function* getReportAccount({filter}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.get('reports', 'account', generateFilter(filter));

    yield put({ type: GET_REPORT_RECEIVED, data: result, filter });
  } catch (error) {
    yield put({ type: GET_REPORT_RECEIVED, data: [], filter });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* getReportCounterparty({filter}: any) {
  try {
    const accountResult: GenericEntityResponse = yield restClient.get('reports', 'account', generateFilter(filter));
    const result: GenericEntityResponse = yield restClient.get('reports', 'counterparty', generateFilter(filter));

    yield put({ type: GET_REPORT_RECEIVED, data: result, filter });
  } catch (error) {
    yield put({ type: GET_REPORT_RECEIVED, data: [], filter });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* getReportCounterpartyDetails({filter}: any) {
  try {
    const accountResult: GenericEntityResponse[] = yield restClient.get('reports', 'counterparty', generateFilter(filter));
    const result: GenericEntityResponse = yield restClient.get('reports', 'counterparty_datail', generateFilter(filter));

    if (accountResult.length) {
      result.push(accountResult[0]);
    }

    yield put({ type: GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED, data: result });
  } catch (error) {
    yield put({ type: GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* getReportAccountDetails({filter}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.get('reports', 'account_detail', generateFilter(filter));

    yield put({ type: GET_REPORT_ACCOUNT_DETAILS_RECEIVED, data: result });
  } catch (error) {
    yield put({ type: GET_REPORT_ACCOUNT_DETAILS_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not report account details']});
  }
}

function* getReportStorageDetails({ filter }: any) {
  try {
    const result: GenericEntityResponse = yield restClient.get('reports', 'storage_detail', generateFilter(filter));

    yield put({ type: GET_REPORT_STORAGE_DETAILS_RECEIVED, data: result });
  } catch (error) {
    yield put({ type: GET_REPORT_STORAGE_DETAILS_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Failed storage details report']});
  }
}

function* getReportStorage({filter}: any) {
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('reports', 'storage', generateFilter(filter));
    yield put({
      type: GET_REPORT_RECEIVED,
      data: (data || []).filter(item => item.count !== 0),
      filter,
      count,
    });
  } catch (error) {
    yield put({ type: GET_REPORT_RECEIVED, data: [], filter, count: 0 });
    yield put({ type: ERROR, messages: ['Not storage report']});
  }
}

// function* getReportStorageData({filter}: any) {
//   try {
//     const categories = yield restClient.get('categories', '', '');
//     const storages = yield restClient.get('storages', '', '');

//     yield put({ type: GET_CATEGORIES, data: categories });
//     yield put({ type: GET_STORAGES, data: storages });
//   } catch (error) {
//     yield put({ type: GET_CATEGORIES, data: [] });
//     yield put({ type: GET_STORAGES, data: [] });
//     yield put({ type: ERROR, messages: ['Not report details']});
//   }
// }

export default function* actionWatcherReport() {
  yield takeLatest(GET_REPORT_ACCOUNT, getReportAccount);
  yield takeLatest(GET_REPORT_COUNTERPARTY, getReportCounterparty);
  yield takeLatest(GET_REPORT_COUNTERPARTY_DETAILS, getReportCounterpartyDetails);
  yield takeLatest(GET_REPORT_STORAGE, getReportStorage);
  yield takeLatest(GET_REPORT_ACCOUNT_DETAILS, getReportAccountDetails);
  yield takeLatest(GET_REPORT_STORAGE_DETAILS, getReportStorageDetails);
}
