import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import { PAYMENTS_RECEIVED,
  GET_PAYMENTS,
  UPDATE_PAYMENT_RECEIVED,
  CREATE_PAYMENT,
  UPDATE_PAYMENT,
  DELETE_PAYMENT,
  DELETE_PAYMENT_RECEIVED,
} from '../types/PaymentTypes';
import generateFilter from '../lib/genFilter';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_PAYMENTS_FILTERS } from '../types/Constants';

function* getPayments({filter}: any) {
  setValueByKey(STORAGE_PAYMENTS_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('payments', '', generateFilter(filter));
    const totals: GenericEntityResponse = yield restClient.get('payments', 'sum_totals', generateFilter(filter, true));
    yield put({ type: PAYMENTS_RECEIVED, data, count, filter, totals });
  } catch (error) {
    yield put({ type: PAYMENTS_RECEIVED, data: [], filter, count: 0 });
    yield put({ type: ERROR, messages: ['Not payments']});
  }
}

function* updatePayment({data, id}: any) {
  if (data.base_total && data.currency_rate) {
    data.total = data.base_total * data.currency_rate;
  }

  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.update('payments', id, data);
    const totals: GenericEntityResponse = yield restClient.get('payments', 'sum_totals', {ids: id});
    yield put({ type: UPDATE_PAYMENT_RECEIVED, data: result, id, totals});
  } catch (error) {
    // yield put({ type: PAYMENT_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order data update']});
  }
}

function* createPayment({data, filter}: any) {
  data.total = data.base_total * data.currency_rate;

  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.create('payments', data);
    const res: GenericEntitiesResponse = yield restClient.get('payments', '', generateFilter(filter));
    const totals: GenericEntityResponse = yield restClient.get('payments', 'sum_totals', generateFilter(filter, true));

    yield put({ type: PAYMENTS_RECEIVED, data: res.data, count: res.count, filter, totals });
  } catch (error) {
    // yield put({ type: PAYMENT_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order data create']});
  }
}

function* deletePayment({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('payments', id);
    const totals: GenericEntityResponse = yield restClient.get('payments', 'sum_totals', {ids: id});
    yield put({ type: DELETE_PAYMENT_RECEIVED, id, totals});
  } catch (error) {
    // yield put({ type: PAYMENT_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not delete order data']});
  }
}

export default function* actionWatcherPayment() {
  yield takeLatest(GET_PAYMENTS, getPayments);
  yield takeLatest(CREATE_PAYMENT, createPayment);
  yield takeLatest(UPDATE_PAYMENT, updatePayment);
  yield takeLatest(DELETE_PAYMENT, deletePayment);
}
