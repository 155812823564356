import { SET_DEFAULT_VALUES, GET_DEFAULT_VALUES } from './../types/DefaultValues';

export interface DefaultValuesState {
    loading: boolean;
    values: any ;
}

const defaultState: DefaultValuesState = {
    loading: false,
    values: {
        bill: undefined,
    },
};

export default  (state: DefaultValuesState = defaultState, action: any) => {
    switch (action.type) {
        case GET_DEFAULT_VALUES:
            const retrievedObject: any = localStorage.getItem('default_values') || '{}';
            return { ...state, values: JSON.parse(retrievedObject) };
        case SET_DEFAULT_VALUES:
            localStorage.setItem( 'default_values', JSON.stringify(action.data) );
            return { ...state, values: action.data };
        default:
            return state;
    }
};
