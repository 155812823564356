export const getValueByKey = (key: string, fallback: any) => {
  try {
    const value = sessionStorage.getItem(key);
    if (typeof value !== "string") {
      return fallback;
    }
    return JSON.parse(value);
  } catch (_error) {
    return fallback;
  }
};

export const setValueByKey = (key: string, value: any) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (_error) {
    console.log(_error);
  }
};
