import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  clearData,
} from './TransferOrderActions';
import { AppState } from '../../reducers';
import TransferOrderCreateContent from './components/TransferOrderCreateContent/TransferOrderCreateContent';
import { useEffect } from 'react';

function TransferOrderCreate({ clearData }: any) {

  useEffect(() => {
    clearData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <TransferOrderCreateContent />;
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderCreate);
