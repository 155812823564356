import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik } from 'formik';
import validationFields from '../../../../lib/validate';
import { AppState } from '../../../../reducers';
import SalesOrderFormContent from './SalesOrderFormContent';
import { setShowModal, clearOrder } from '../../SalesOrderActions';
import { getDefaultValues } from '../../../DefaultValue/DefaultValueActions';
import moment from 'moment';

class SalesOrderForm extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.props.getDefaultValues();
    //
  }

  public render() {
    const {loading, order, counterparties, storages, types, isReadingMode, dictionaries, default_types} = this.props;
    order.currency_rate = order.currency_rate ? order.currency_rate : '1.00';

    if (!order.id && default_types.client) {
      const index: number = counterparties.map((e: any) => e.id ).indexOf(default_types.client);
      if (index > -1) {
        order.discount = counterparties[index].discount;
      }
    }

    order.discount = order.discount ? order.discount : '0.00';
    order.currency_name = order.currency_name ?
      order.currency_name : (default_types.currency && default_types.currency.toString());

    order.s_o_type = order.s_o_type ?
      order.s_o_type : (default_types.sales_order && default_types.sales_order.toString());

    order.storage_id = order.storage_id ?
      order.storage_id : (default_types.storage && default_types.storage);

    order.date = order.date ? order.date : moment().format('YYYY-MM-DD');
    order.counterparty_id = order.counterparty_id ? order.counterparty_id : default_types.client;
    order.note = order.note ? order.note : '';

    return (
      <Formik
          initialValues={order}
          enableReinitialize={true}
          validate={(values: any) => {
            const fields: any = [
              {name: 'date', rules: ['required']},
              {name: 'storage_id', rules: ['required']},
              {name: 'counterparty_id', rules: ['required']},
              {name: 's_o_type', rules: ['required']},
              {name: 'discount', rules: [{name: 'gte', value : 0}, {name: 'lte', value : 100}, 'required', 'numeric']},
              {name: 'currency_name', rules: ['required']},
              {name: 'currency_rate', rules: [{name: 'gte', value : 0}, 'required', 'numeric']},
            ];
            return validationFields(fields, values);
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (this.props.onChange) {
              this.props.onChange(values);
            }
            setSubmitting(loading);
          }}
          // render={props => ()}
        >
          {(props) => <SalesOrderFormContent {...props}
            loading={loading}
            isReadingMode = {isReadingMode}
            setShowModal = {this.props.setShowModal}
            clearOrder = {this.props.clearOrder}
            counterparties={counterparties.map((item: any) => ({
              title: item.name, value: item.id, discount: item.discount,
            }))}
            storages={storages.map((item: any) => ({title: item.name, value: item.id}))}
            types={types.filter((item: any) => item.d_type === 'sales_order')
              .map((item: any) => ({title: item.title, value: item.id}))}
            currency = {dictionaries.filter((item: any) => item.d_type === 'currency')
              .map((item: any) => ({title: item.title, value: item.id}))
            }
          />}
        </Formik>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isReadingMode: state.sales_order.isReadingMode,
  loading: state.sales_order.loading,
  storages: state.started_data.storages,
  types: state.started_data.dictionaries,
  counterparties: state.started_data.counterparties.filter((item: any) => item.slug.indexOf('client') > -1 ),
  dictionaries: state.started_data.dictionaries,
  default_types: state.default_values.values,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowModal,
      getDefaultValues,
      clearOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderForm);
