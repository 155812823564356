import { SET_CATEGORIES_FILTER, FILTERED_CATEGORIES_RECEIVED } from './../types/CategoryTypes';
import {
  GET_CATEGORIES,
  CATEGORIES_RECEIVED,
  CATEGORY_RECEIVED,
  CREATE_CATEGORY_RECEIVED,
  DELETE_CATEGORY_RECEIVED,
  UPDATE_CATEGORY_RECEIVED,
  SET_CATEGORY_SHOW_MODAL,
  SET_SELECTED_CATEGORY_ID } from '../types/CategoryTypes';

import cloneDeep from 'lodash.clonedeep';

export interface CategoriesState {
  loading: boolean;
  list_data: any[];
  filtered_list_data: any[];
  category: any;
  show_modal: boolean;
  selectedCategoryId: number;
  filter: any [];
}

const defaultState: CategoriesState = {
  loading: false,
  list_data: [],
  filtered_list_data: [],
  category: {},
  show_modal: false,
  selectedCategoryId: 0,
  filter: [
    {
      attribute: 'parent_id',
      value: 0,
    },
  ],
};

export default (state: CategoriesState = defaultState, action: any) => {
  let tmpListData: any = cloneDeep(state.list_data);
  let tmpFilteredData: any = cloneDeep(state.filtered_list_data);
  switch (action.type) {
    case SET_CATEGORY_SHOW_MODAL:
      return {...state, show_modal: action.status};
    case SET_SELECTED_CATEGORY_ID:
      return {...state,
        selectedCategoryId: action.id,
        filtered_list_data: state.list_data.filter((item: any) => item.parent_id == action.id),
      };
    case SET_CATEGORIES_FILTER:
      return {...state, filter: action.filter};
    case GET_CATEGORIES:
      return {...state, loading: true};
    case CATEGORIES_RECEIVED:
      return {...state, loading: false, list_data: action.data};
    case FILTERED_CATEGORIES_RECEIVED:
      return {...state, loading: false, filtered_list_data: action.data};
    case CATEGORY_RECEIVED:
      return {...state, loading: false, category: action.data};
    case CREATE_CATEGORY_RECEIVED:
      return {...state,
        list_data: [...state.list_data, action.item],
        filtered_list_data: [...state.filtered_list_data, action.item],
      };
    case UPDATE_CATEGORY_RECEIVED:
      return {...state,
        list_data: state.list_data.map((item: any) => item.id === action.id ? action.item : item),
        filtered_list_data: state.filtered_list_data.map((item: any) => item.id === action.id ? action.item : item)};
    case DELETE_CATEGORY_RECEIVED:
      tmpListData = tmpListData.filter((item: any) => item.id !== action.id);
      tmpFilteredData = tmpFilteredData.filter((item: any) => item.id !== action.id);
      return {...state, list_data: tmpListData, filtered_list_data: tmpFilteredData};
    default:
        return state;
  }
};
