import cloneDeep from 'lodash.clonedeep';

import {
  STARTED_DATA_RECEIVED,
  GET_STARTED_DATA,
  CREATE_STARTED_DATA_ITEM,
  UPDATE_STARTED_DATA_ITEM,
  DELETE_STARTED_DATA_ITEM,
  UPDATE_STARTED_DATA_COUNTERPARTY,
  CREATE_STARTED_DATA_COUNTERPARTY,
  UPDATE_STARTED_DATA_PRODUCT,
} from './../types/StartedDataTypes';

export interface StartedDataState {
  loading: boolean;
  dictionaries: any;
  accounts: any;
  counterparties: any;
  categories: any;
  products: any;
  storages: any;
}

const defaultState: StartedDataState = {
  loading: false,
  dictionaries: [],
  accounts: [],
  counterparties: [],
  categories: [],
  products: [],
  storages: [],
};

export default (state: StartedDataState = defaultState, action: any) => {
  const name: any = action.name;
  const tmp: any = cloneDeep(state);

  switch (action.type) {
    case GET_STARTED_DATA:
      return {...state, loading: true};
    case STARTED_DATA_RECEIVED:
      return {...state,
        loading: false,
        dictionaries: action.dictionaries,
        accounts:  action.accounts,
        counterparties:  action.counterparties,
        categories:  action.categories,
        products:  action.products,
        storages:  action.storages,
      };

    case UPDATE_STARTED_DATA_ITEM:
      return {...state, [name]: tmp[name].map((item: any) => item.id === action.id ? action.item : item) };

    case CREATE_STARTED_DATA_ITEM:
      return {...state, [name]: [action.item, ...tmp[name]]};

    case UPDATE_STARTED_DATA_PRODUCT:
      const index = tmp.products.map((e: any) => e.id).indexOf(action.id);
      if (index > -1) {
        if ( action.item.published) {
          return {...state, products: tmp.products.map((item: any) => item.id === action.id ? action.item : item) };
        } else {
          return {...state, products: tmp.products.filter((item: any) => item.id !== action.id) };
        }
      } else {
        return {...state, products: [action.item, ...tmp.products]};
      }

    case UPDATE_STARTED_DATA_COUNTERPARTY:
      const res = {...action.item, slug: []};
      tmp.dictionaries.map((dictr: any) => {
        res.c_type.map((slug: number) => {
          if (dictr.id == slug) {
            res.slug.push( dictr.slug );
          }
        });
      });

      return {...state,
        counterparties: tmp.counterparties.map((item: any) => item.id === res.id ? res : item),
      };

    case CREATE_STARTED_DATA_COUNTERPARTY:
      const createItem = {...action.item, slug: []};
      tmp.dictionaries.map((dictr: any) => {
        createItem.c_type.map((slug: number) => {
          if (dictr.id == slug) {
            createItem.slug.push( dictr.slug );
          }
        });
      });
      return {...state, counterparties: [createItem, ...tmp.counterparties]};
    case DELETE_STARTED_DATA_ITEM:
        tmp[name] = tmp[name].filter((item: any) => item.id !== action.id);
        return {...state, [name]: tmp[name]};

      default:
       return state;
  }
};
