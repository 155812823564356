import * as React from 'react';
import { Form, Select, Tooltip } from 'antd';

function SelectField({
  errors,
  touched,
  handleBlur,
  handleChange,
  inputProps,
  title,
  label,
  placeholder,
  name,
  values,
  mode,
  options,
  withErrorText,
}: any) {

  const validateStatus = errors[name] && touched[name] ? 'error' : '';
  const help = (withErrorText && validateStatus === 'error') ? errors[name] : '';

  return (
    <Form.Item
      label={label}
      validateStatus={validateStatus}
      help={help}
    >
      <Tooltip
        title={errors[name] === 'Required' ? '' : errors[name]}
      >
      <Select
          mode = {mode ? mode : 'default'}
          style={{ width: '100%' }}
          {...inputProps}
          showSearch
          placeholder={placeholder || title}
          optionFilterProp="children"
          allowClear={true}
          value={values[name]}
          onBlur={(value: any) => {
            handleBlur({target: {name, value}});
          }}
          onChange={(value: any) => {
            handleChange({
              target: {
                name,
                value,
              },
            });
          }}
          filterOption={(input: any, option: any) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {(options || []).map((option: any, index: number) =>
            <Select.Option key = {name + '-' + index } value={option.value}>
              {option.title}
            </Select.Option>)}
        </Select>
      </Tooltip>
    </Form.Item>
  );
}

export default SelectField;
