import * as React from 'react';
import { Button, Select, Divider, InputNumber, Row, Col, Form } from 'antd';
import { I18n } from 'react-redux-i18n';

class DefaultValueFormContent extends React.Component<any, any> {
  isSubmit: boolean;
  constructor(props: any) {
    super(props);
    this.isSubmit = false;
  }

  render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      loading,
      dictionaries,
      storages,
      categories,
      counterparties,
      accounts,
      is_mobile,
    }: any = this.props;

    if (isSubmitting) {
      this.isSubmit = true;
    }
    
    return (
      <Form style={{ overflow: 'auto '}} className='defaultValues-form' layout="vertical">
        <Divider> {I18n.t('default_values.Types')} </Divider>
        <div className='flex-column'>
          <Form.Item label = {I18n.t('default_values.Account')}
              { ...errors.account && (touched.account ||  this.isSubmit) &&
                  { help : errors.account, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Account')}
                  value = {values.account}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'account', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'account',
                          value,
                        },
                      });
                  }}
              >
              { accounts.map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id} >{option.name}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Bill')}
              { ...errors.bill && (touched.bill ||  this.isSubmit) &&
                  { help : errors.bill, validateStatus: 'error'}
              }
          >
            <Select
                  placeholder = {I18n.t('default_values.Bill')}
                  value = {values.bill}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'bill', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'bill',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'bill').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Payment')}
              { ...errors.payment && (touched.payment ||  this.isSubmit) &&
                  { help : errors.payment, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Payment')}
                  value = {values.payment}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'payment', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'payment',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'payment').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Сlient')}
            { ...errors.client && (touched.client ||  this.isSubmit) &&
              { help : errors.client, validateStatus: 'error'}
            }
          >
              <Select
                  placeholder = {I18n.t('default_values.Сlient')}
                  value = {values.client}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'client', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'client',
                          value,
                        },
                      });
                  }}
              >
              { counterparties.filter((item: any) => item.slug.indexOf('client') > -1 ).map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.name}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Supplier')}
            { ...errors.supplier && (touched.supplier ||  this.isSubmit) &&
              { help : errors.supplier, validateStatus: 'error'}
            }
          >
              <Select
                  placeholder = {I18n.t('default_values.Supplier')}
                  value = {values.supplier}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'supplier', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'supplier',
                          value,
                        },
                      });
                  }}
              >
              { counterparties.filter((item: any) => item.slug.indexOf('supplier') > -1).map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.name}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Currency')}
              { ...errors.currency && (touched.currency ||  this.isSubmit) &&
                  { help : errors.currency, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Currency')}
                  value = {values.currency}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'currency', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'currency',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'currency').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Sales order')}
              { ...errors.sales_order && (touched.sales_order ||  this.isSubmit) &&
                  { help : errors.sales_order, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Sales order')}
                  value = {values.sales_order}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'sales_order', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'sales_order',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'sales_order').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Purchase order')}
              { ...errors.purchase_order && (touched.purchase_order ||  this.isSubmit) &&
                  { help : errors.purchase_order, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Purchase order')}
                  value = {values.purchase_order}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'purchase_order', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'purchase_order',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'purchase_order').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

          <Form.Item label = {I18n.t('default_values.Transfer order')}
              { ...errors.transfer_order && (touched.transfer_order ||  this.isSubmit) &&
                  { help : errors.transfer_order, validateStatus: 'error'}
              }
          >
              <Select
                  placeholder = {I18n.t('default_values.Transfer order')}
                  value = {values.transfer_order}
                  allowClear={true}
                  onBlur={(value) => {handleBlur({target: {name: 'transfer_order', value}}); }}
                  onChange = {(value: any) => {
                      handleChange({
                        target: {
                          name: 'transfer_order',
                          value,
                        },
                      });
                  }}
              >
              { dictionaries.filter((item: any) => item.d_type === 'transfer_order').map((option: any, index: any) =>
                  <Select.Option key = {index} value={option.id}>{option.title}</Select.Option>)
              }
              </Select>
          </Form.Item>

        <Form.Item label = {I18n.t('default_values.Storage')}
            { ...errors.storage && (touched.storage ||  this.isSubmit) &&
                { help : errors.storage, validateStatus: 'error'}
            }
        >
            <Select
                placeholder = {I18n.t('default_values.Storage')}
                value = {values.storage}
                allowClear={true}
                onBlur={(value) => {handleBlur({target: {name: 'storage', value}}); }}
                onChange = {(value: any) => {
                    handleChange({
                      target: {
                        name: 'storage',
                        value,
                      },
                    });
                }}
            >
             { storages.map((option: any, index: any) =>
                <Select.Option key = {index} value={option.id} >{option.name}</Select.Option>)
            }
            </Select>
        </Form.Item>

        <Form.Item label = {I18n.t('default_values.Category by default')}
          { ...errors.category && (touched.category ||  this.isSubmit) &&
            { help : errors.category, validateStatus: 'error'}
          }
        >
          <Select
            placeholder = {I18n.t('default_values.Category by default')}
            value = {values.category}
            allowClear={true}
            onBlur={(value) => {handleBlur({target: {name: 'category', value}}); }}
            onChange = {(value: any) => {
              handleChange({
                target: {
                  name: 'category',
                  value,
                },
              });
            }}
          >
            {categories.map((option: any, index: any) =>
              <Select.Option key = {index} value={option.id} >{option.name}</Select.Option>
            )} 
          </Select>
        </Form.Item>
        </div>
        <Divider> {I18n.t('default_values.Pagination')} </Divider>
        <div className='flex-column'>
          <Row justify="space-around">

            <Col span = {is_mobile ? 8 : 4}>
              <Form.Item label = {I18n.t('default_values.Dictionary limit')}>
                <InputNumber min={1} max={1000}
                  value={values.dictionary_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'dictionary_limit', value }})}/>
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Account limit')}>
                <InputNumber min={1} max={1000}
                  value={values.account_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'account_limit', value }})}/>
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Product limit')}>
                <InputNumber min={1} max={1000}
                  value={values.product_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'product_limit', value }})}/>
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Storage limit')}>
                <InputNumber min={1} max={1000}
                  value={values.storage_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'storage_limit', value }})}/>
              </Form.Item>
            </Col>

            </Row>
            <Row justify="space-around">

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Counterparty limit')}>
                <InputNumber min={1} max={1000}
                  value={values.counterparty_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'counterparty_limit', value }})}/>
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Bill limit')}>
                <InputNumber min={1} max={1000}
                  value={values.bill_limit}
                  onChange = {(value: any) => handleChange({ target: { name: 'bill_limit', value }})}/>
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Payment limit')}>
                <InputNumber min={1} max={1000}
                  value={values.payment_limit}
                  onChange = {(value: any) => handleChange({ target: {  name: 'payment_limit', value }}) }
                />
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Transfer limit')}>
                <InputNumber min={1} max={1000}
                  value={values.transfer_limit}
                  onChange = {(value: any) => handleChange({ target: {  name: 'transfer_limit', value }}) }
                  />
              </Form.Item>
            </Col>

            </Row>
            <Row justify="space-around">

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Sales order limit')}>
                <InputNumber min={1} max={1000}
                  value={values.s_o_limit}
                  onChange = {(value: any) => handleChange({ target: {  name: 's_o_limit', value }}) }
                  />
              </Form.Item>
            </Col>

            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Purchase order limit')}>
                <InputNumber min={1} max={1000}
                  value={values.p_o_limit}
                  onChange = {(value: any) => handleChange({ target: {  name: 'p_o_limit', value }}) }
                  />
              </Form.Item>
            </Col>
            <Col span = {4}>
              <Form.Item label = {I18n.t('default_values.Transfer order limit')}>
                <InputNumber min={1} max={1000}
                  value={values.t_o_limit}
                  onChange = {(value: any) => handleChange({ target: {  name: 't_o_limit', value }}) }
                  />
              </Form.Item>
            </Col>
            <Col span={4} />
          </Row>
          <Form.Item>
            <Button onClick={handleSubmit} type="primary" loading={loading}> {I18n.t('default_values.Submit')} </Button>
          </Form.Item>
        </div>
      </Form>
    );
  }
}

export default (DefaultValueFormContent);
