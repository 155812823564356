import { Input, Button, InputNumber, TreeSelect, Checkbox, Tooltip, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { I18n } from 'react-redux-i18n';
import SelectField from '../../../../components/Fields/SelectField';
import { createNewTreeData } from '../../../../../lib/helper';
import { useCallback } from 'react';

function ProductFormContent({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  categories,
  product_types,
}: any) {
  const fieldError = useCallback(
    (field: string) => {
      return errors[field] && touched[field] && { help: errors[field], validateStatus: 'error' };
    },
    [errors, touched]
  );

  if (!['number', 'string'].includes(typeof values.category_id)) {
    delete values.category_id;
  }

  const treeData: any = [
    { value: '0', title: I18n.t('product.All Categories'), key: '0', children: createNewTreeData(categories) },
  ];

  return (
    <Form onFinish={handleSubmit} className="createProduct-form" layout="vertical">
      <Form.Item label={I18n.t('product.Product Title')} {...fieldError('title')}>
        <Tooltip title={errors.title === 'Required' ? '' : errors.title}>
          <Input
            placeholder={I18n.t('product.Product Title')}
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
          />
        </Tooltip>
      </Form.Item>
      <div className="productType-container">
        <SelectField
          label={I18n.t('product.Product type')}
          placeholder={I18n.t('product.Product type')}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values || {}}
          name="product_type"
          options={
            (Array.isArray(product_types) &&
              product_types.length &&
              product_types.map(item => ({
                value: item.id.toString(),
                title: item.title,
              }))) ||
            []
          }
          errors={errors}
          withErrorText
          touched={touched}
        />
      </div>

      <Form.Item label={I18n.t('product.Description')} {...fieldError('description')}>
        <TextArea
          placeholder={I18n.t('product.Description')}
          name="description"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          rows={5}
        />
      </Form.Item>

      <Form.Item label={I18n.t('product.Price')} {...fieldError('price')}>
        <InputNumber
          style={{ width: '100%' }}
          placeholder={I18n.t('product.Price')}
          name="price"
          // size = "large"
          step={1}
          min={0}
          precision={2}
          value={values.price}
          onChange={e => {
            handleChange({ target: { name: 'price', value: e } });
          }}
          onBlur={e => {
            handleBlur({ target: { name: 'price', value: e } });
          }}
        />
      </Form.Item>

      <Form.Item label={I18n.t('product.Category')} {...fieldError('category_id')}>
        <TreeSelect
          treeDefaultExpandAll
          placeholder={I18n.t('product.Category')}
          value={values.category_id}
          onChange={(value: any) => {
            handleChange({
              target: {
                name: 'category_id',
                value,
              },
            });
          }}
          treeData={treeData}
        />
      </Form.Item>

      <Form.Item>
        {I18n.t('product.Published')} :
        <Checkbox
          name="published"
          checked={values.published}
          onChange={(value: any) => {
            handleChange({
              target: {
                name: 'published',
                value: value.target.checked,
              },
            });
          }}
        />
      </Form.Item>

      <Form.Item label={I18n.t('product.Barcode')}>
        <Input
          placeholder={I18n.t('product.Barcode')}
          name="barcode"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.barcode}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {I18n.t('product.Save')}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ProductFormContent;
