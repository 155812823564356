import { CREATE_STARTED_DATA_ITEM, UPDATE_STARTED_DATA_ITEM, DELETE_STARTED_DATA_ITEM } from './../types/StartedDataTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import {
  STORAGES_RECEIVED,
  GET_STORAGES,
  CREATE_STORAGE,
  UPDATE_STORAGE,
  DELETE_STORAGE,
  DELETE_STORAGE_RECEIVED,
  UPDATE_STORAGE_RECEIVED,
  CREATE_STORAGE_RECEIVED,
} from './../types/StorageTypes';
import generateFilter from '../lib/genFilter';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_STORAGES_FILTERS } from '../types/Constants';

function* getStorages({filter}: any) {
  setValueByKey(STORAGE_STORAGES_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('storages', '', generateFilter(filter));
    yield put({ type: STORAGES_RECEIVED, data, count, filter });
  } catch (error) {
    yield put({ type: STORAGES_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not storage']});
  }
}

function* createStorage({data}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.post('storages', data);
    yield put({ type: CREATE_STORAGE_RECEIVED, item: result });
    yield put({ type: CREATE_STARTED_DATA_ITEM, name: 'storages', item: result });

  } catch (error) {
    yield put({ type: CREATE_STORAGE_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not storage create']});
  }
}

function* updateStorage({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('storages', id, data);
    yield put({ type: UPDATE_STORAGE_RECEIVED, item: result });
    yield put({ type: UPDATE_STARTED_DATA_ITEM, name: 'storages', item: result, id });

  } catch (error) {
    yield put({ type: UPDATE_STORAGE_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not storage update']});
  }
}
function* deleteStorage({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('storages', id);
    yield put({ type: DELETE_STORAGE_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'storages', id });

  } catch (error) {
    yield put({ type: ERROR, messages: ['Not storage delete']});
  }
}

export default function* actionWatcherStorage() {
  yield takeLatest(GET_STORAGES, getStorages);
  yield takeLatest(CREATE_STORAGE, createStorage);
  yield takeLatest(UPDATE_STORAGE, updateStorage);
  yield takeLatest(DELETE_STORAGE, deleteStorage);
}