export default {
  Title: 'Назва',
  Price: 'Ціна',
  Date: 'Дата',
  Processed: 'Проведено',
  Published: 'Опубліковано',
  Type: 'Тип',
  Description: 'Опис',
  Barcode: 'Штрих код',
  Yes: 'Так',
  No: 'Ні',
  Choose: 'Вибрати',
  Operations: 'Дії',
  'All Categories': 'Всі категорії',
};
