import {
  GET_DICTIONARIES,
  DICTIONARIES_RECEIVED,
  CREATE_DICTIONARY_RECEIVED,
  UPDATE_DICTIONARY_RECEIVED,
  DELETE_DICTIONARY_RECEIVED,
  DICTIONARY_TYPES_RECEIVED,
  SET_DICTIONARIES_FILTER,
  ADD_DICTIONARY_TYPES,
} from '../types/DictionaryTypes';
import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_DICTIONARIES_FILTERS } from '../types/Constants';

export interface DictionaryState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  dictionary_types: any[];
  total_count: number;
}

const defaultState: DictionaryState = {
  loading: false,
  list_data: [],
  dictionary_types: [],
  total_count: 0,
  filter: getValueByKey(STORAGE_DICTIONARIES_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 1000,
    },
    {
      attribute: 'sort',
      value: '-createdAt',
    },
  ]),
};

export default (state: DictionaryState = defaultState, action: any) => {
  let tmpList: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_DICTIONARIES:
      return {...state, loading: true};
    case DICTIONARY_TYPES_RECEIVED:
      return {...state, loading: false, dictionary_types: action.data};
    case ADD_DICTIONARY_TYPES:
      if (state.dictionary_types.indexOf(action.data) === -1) {
        return {...state, loading: false, dictionary_types: [action.data, ...state.dictionary_types ]};
      }
      return {...state, loading: false};
    case DICTIONARIES_RECEIVED:
      const total_count = action.count > -1 ? action.count : state.total_count;
      return {...state,
        loading: false,
        list_data: action.data,
        filter: action.filter || state.filter,
        total_count,
      };
    case CREATE_DICTIONARY_RECEIVED:
      return {...state, list_data: [action.item, ...state.list_data ], total_count: state.total_count + 1 };
    case UPDATE_DICTIONARY_RECEIVED:
      return {...state, list_data: state.list_data.map((item: any) => item.id === action.item.id ? action.item : item)};
    case DELETE_DICTIONARY_RECEIVED:
        tmpList = tmpList.filter((item: any) => item.id !== action.id);
        return {...state, list_data: tmpList, total_count: state.total_count - 1 };
    case SET_DICTIONARIES_FILTER:
      return {...state, filter: action.filter};
    default:
       return state;
  }
};
