import started_data, { StartedDataState } from './started_data';
import default_values, { DefaultValuesState } from './default_values';
import transfer, { TransferState } from './transfer';
import dialog_product, { DialogProductsState } from './dialog_product';
import category, { CategoriesState } from './category';
import { combineReducers } from 'redux';
import auth, { AuthState } from './auth';
import error, { ErrorState } from './error';
import product, { ProductsState } from './product';
import purchase_order, { PurchaseOrdersState } from './purchase_order';
import sales_order, { SalesOrdersState } from './sales_order';
import counterparty, { CounterpartyState } from './counterparty';
import storage, { StoragetyState } from './storage';
import dictionary, { DictionaryState } from './dictionary';
import bill, { BillState } from './bill';
import account, { AccountState } from './account';
import payment, { PaymentState } from './payment';
import report, { ReportState } from './report';
import { i18nReducer } from 'react-redux-i18n';
import transfer_order, { TransferOrdersState } from './transfer_order';

export interface AppState {
  auth: AuthState;
  product: ProductsState;
  dialog_product: DialogProductsState;
  purchase_order: PurchaseOrdersState;
  transfer_order: TransferOrdersState;
  sales_order: SalesOrdersState;
  counterparty: CounterpartyState;
  storage: StoragetyState;
  account: AccountState;
  dictionary: DictionaryState;
  bill: BillState;
  error: ErrorState;
  category: CategoriesState;
  payment: PaymentState;
  transfer: TransferState;
  report: ReportState;
  default_values: DefaultValuesState;
  started_data: StartedDataState;
  i18n: any;
}

export const rootReducer = combineReducers<AppState>({
  auth,
  product,
  dialog_product,
  purchase_order,
  counterparty,
  storage,
  sales_order,
  transfer_order,
  dictionary,
  bill,
  payment,
  error,
  category,
  account,
  transfer,
  report,
  default_values,
  started_data,
  i18n: i18nReducer,
});

export default rootReducer;
