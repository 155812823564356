import { Tree } from 'antd';

function ProductCategoryTree({ categories, onSelect, selectedKeys }: any) {

  return (
    <Tree
      defaultExpandAll = {true}
      onSelect = {onSelect}
      selectedKeys = {(selectedKeys).toString().split()} // because need array of strings
      treeData={categories}
    />
  );

}

export default (ProductCategoryTree);
