import FilterForm from './components/FilterForm/FilterForm';
import './style.scss';

function Filter(props: any) {
  return (
    <FilterForm {...props}/>
  );
}

export default Filter;
