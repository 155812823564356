import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Modal, Row } from 'antd';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import findIndex from 'lodash/findIndex';
import { AppState } from '../../../reducers';
import ReportAccountDetailsData from './ReportAccountDetailsData';
import { getReportAccountDetails } from '../ReportActions';
import Filter from '../../components/Filter/Filter';

const renderNumber = (value: number) => (value || 0).formatNumber()

function ReportAccountData({ data, filter, accounts, is_mobile, loading, getReportAccountDetails }: any) {
  const [open, setOpen] = useState(false)
  const dateFrom = useRef(moment().toJSON());
  const dateTo = useRef(moment().toJSON());
  const accountId = useRef(null);

  const disabledStartDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!startValue || !endValue) {
      return false;
    }
    return !startValue || startValue.isAfter(endValue);
  }

  const disabledEndDate = (startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!endValue || !startValue) {
      return false;
    }
    return !endValue || endValue.isBefore(startValue);
  }

  let date_from;
  let date_to;

  filter.forEach((item: any) => {
    if (item.attribute === 'date_from') {
      date_from = item.value;
    }
    if (item.attribute === 'date_to') {
      date_to = item.value;
    }
  });

  const columns = [
    {
      title: I18n.t('report.Accounts'),
      dataIndex: 'account_id',
      key: 'account_id',
      render: (accountId: number) => {
        const index = findIndex(accounts, ['id', accountId]);
        return index > -1 ? accounts[index].name : accountId;
      },
    },
    {
      title: I18n.t('report.Remainder to', {date: moment(date_from).format('L')}),
      dataIndex: 'remainder',
      key: 'remainder',
      align: 'right' as any,
      onCell: (record: any) => {
        return {
          style: { color: record.remainder < 0 ? 'red' : 'unset' },
        };
      },      
      render: renderNumber,
    },
    {
      title: I18n.t('report.Turnover'),
      children: [
        {
          title: I18n.t('report.Incoming'),
          dataIndex: 'incoming',
          key: 'incoming',
          align: 'right' as any,
          render: renderNumber,
        },
        {
          title: I18n.t('report.Outcoming'),
          dataIndex: 'outcoming',
          key: 'outcoming',
          align: 'right' as any,
          render: renderNumber,
        },
      ],
    },
    {
      title: I18n.t('report.Remainder to', {date: moment(date_to).format('L')}),
      align: 'right' as any,
      onCell: (item: any) => {
        const balance = (item.remainder || 0) + (item.incoming || 0) - (item.outcoming || 0);
        return {
          style: { color: balance < 0 ? 'red' : 'unset' },
        };
      },
      render: (item: any) => {
        return ((item.remainder || 0) + (item.incoming || 0) - (item.outcoming || 0)).formatNumber();
      },
    },
  ];

  return (
    <>
      <Modal
        width={is_mobile ? '100%' : '90%'}
        title={I18n.t('report.Account details')}
        open={open}
        onCancel={() => setOpen(false)}
        footer={false}
        // onCancel={this.handleCancel}
      >
        <Row style={{ marginBottom: 10 }}>
          <Filter
            onChange={(values: any) => {
              dateFrom.current = (values.find((item: any) => item.attribute === 'date_from') || { value: '' }).value;
              dateTo.current = (values.find((item: any) => item.attribute === 'date_to') || { value: '' }).value;
              getReportAccountDetails([...values,
                { attribute: 'account_id', value: accountId.current },
              ]);
            }}
            rules={[
              {name: 'date_from', rules: ['required']},
              {name: 'date_to', rules: ['required']},
            ]}
            initialValues={filter}
            attributes={[
              {
                name: 'date_from',
                title: I18n.t('report.Date from'),
                label: I18n.t('report.Date from'),
                hyper_type: 'date',
                inputProps: {
                  allowClear: false,
                  disabledDate: (startValue: any) => {
                    return disabledStartDate(startValue, dateTo.current);
                  },
                },
              },
              {
                name: 'date_to',
                title: I18n.t('report.Date to'),
                label: I18n.t('report.Date to'),
                hyper_type: 'date',
                inputProps: {
                  allowClear: false,
                  disabledDate: (endValue: any) => {
                    return disabledEndDate(dateFrom.current, endValue);
                  },
                },
              },
            ]}
            showClear={false}
          />
        </Row>
        <ReportAccountDetailsData/>
      </Modal>
      <div style = {{overflow: 'auto', width: '100%'}} className = "small-table no-actions">
        <Table
          columns={columns}
          dataSource={data}
          bordered
          loading={loading}
          pagination={false}
          rowKey={(record: any) => record.account_id}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event: any) => {
                getReportAccountDetails([...filter,
                  { attribute: 'account_id', value: record.account_id  },
                ]);
                accountId.current = record.account_id
                setOpen(true)
              },
            };
          }}
        />
      </div>
    </>
  );
}
            

const mapStateToProps = (state: AppState) => ({
  data: state.report.report,
  filter: state.report.filter,
  loading: state.report.loading,
  accounts: state.started_data.accounts,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportAccountDetails,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ReportAccountData);
