import { Component } from 'react';
import { Checkbox, Button } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import './style.scss';
import isEqual from 'lodash.isequal';

export default class TableColSettings extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      values: [],
    };
  }

  checkValues(props: any) {
    const res: any[] = [];
    let tmpRes = [];
    if (props.data) {
      tmpRes = (props.data || '').split(',');
      tmpRes = tmpRes.concat(props.options.map((item: any) => (item.disabled ? item.value : '')).filter((i: any) => i));
      for (const str of tmpRes) {
        if (!res.includes(str)) {
          res.push(str);
        }
      }
    } else {
      props.options.forEach((item: any) => {
        res.push(item.value);
      });
    }
    this.setState({ values: res });
  }

  componentDidMount() {
    this.checkValues(this.props);
  }

  componentWillReceiveProps(newProps: any) {
    if (!isEqual(newProps, this.props)) {
      this.checkValues(newProps);
    }
  }

  render() {
    const { open, values } = this.state;
    const { options, title } = this.props;

    return (
      <div className="table-col-setting">
        <SettingOutlined onClick={() => this.setState({ open: !this.state.open })} />

        {open ? (
          <div className="setting-card" title={title}>
            <Checkbox.Group
              options={options}
              value={values}
              onChange={(value: any) => this.setState({ values: value })}
            />
            <Button
              type="primary"
              onClick={() => {
                this.setState({ open: false });
                this.props.onSave(values);
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
