import {
  GET_DIALOG_PRODUCTS,
  DIALOG_PRODUCTS_RECEIVED,
  SET_DIALOG_PRODUCTS_FILTER,
  SET_SELECTED_DIALOG_CATEGORY_ID,
} from '../types/ProductDialogTypes';

export interface DialogProductsState {
  selected_category_id: number;
  loading: boolean;
  list_data: any[];
  total_count: number;
  filter: any[];
}

const defaultState: DialogProductsState = {
  selected_category_id: 0,
  loading: false,
  list_data: [],
  total_count: 0,
  filter: [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 5,
    },
    {
      attribute: 'populate',
      value: 'category',
    },
    {
      attribute: 'sort',
      value: '-createdAt',
    },
  ],
};

export default (state: DialogProductsState = defaultState, action: any) => {
  switch (action.type) {
    case GET_DIALOG_PRODUCTS:
      return {...state, loading: true};
    case DIALOG_PRODUCTS_RECEIVED:
      return {...state, loading: false,
        list_data: action.data, total_count: action.count};
    case SET_DIALOG_PRODUCTS_FILTER:
      return {...state, filter: action.filter};
    case SET_SELECTED_DIALOG_CATEGORY_ID:
      return {...state, selected_category_id: action.id };
    default:
      return state;
  }
};
