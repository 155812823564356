import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import moment from 'moment';
import { getPayments, createPayment, updatePayment, deletePayment } from '../../PaymentActions';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import OrderItemsEditable from '../../../components/OrderItemsEditable/OrderItemsEditable';
import DateField from '../../../components/Fields/DateField';
import SelectField from '../../../components/Fields/SelectField';
import SwitchField from '../../../components/Fields/SwitchField';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';
import { useCallback, useEffect } from 'react';
import { formatValue } from '../../../../lib/helper';

function Footer({ totals, currency }: any) {
  /*
    d_type: "currency"
    id: 8
    short_title: "Є"
    slug: "EUR"
    style: null
    title: "Євро"
  */
  const perCurrencyData = Array.isArray(totals.perCurrency) ?
    totals.perCurrency.map((item: any) => {
      const currencyTitle = currency.find((_currency: any) => _currency.id.toString() === item.currency_name)?.title;
      return {
        currencyTitle,
        total: item.total
      }
    }) : [];

  return (
    <div>
      {!!perCurrencyData.length && <div style={{ display: 'flex', gap: 10, marginBottom: 10 }}>
          {perCurrencyData.map((item: any, index: number) => {
            return <div key={index}>{item.currencyTitle}: <strong>{(item.total || 0).formatNumber()}</strong></div>
          })}
      </div>}
      <p>{I18n.t('payment.Summary Total')}: <strong>{(totals.total || 0).formatNumber()}</strong></p>
    </div>
  );
}

function PaymentTable({
  data,
  filter,
  counterparties,
  accounts,
  dictionaries,
  currency,
  default_types,
  totals,
  total_count,

  getPayments,
  createPayment,
  updatePayment,
  deletePayment,
}: any) {

  useEffect(() => {
    if (!localStorage.getItem('payment-table-col-setting')) {
      localStorage.setItem('payment-table-col-setting',
        'counterparty_id,account_id,p_type,currency_name,currency_rate,base_total,total,date,processed',
      );
    }
  }, []);

  const renderInput = useCallback((props: any) => {
    switch (props.inputType) {
      case 'date':
        return <div style = {{width: '115px'}}><DateField {...props}/></div>;
      case 'switch':
        return <SwitchField {...props} inputProps={{disabled: true}}/>;
      case 'select':
        let options: any = [];
        if (props.name === 'counterparty_id') {
          options = counterparties.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'account_id') {
          options = accounts.map((item: any) => ({title: item.name, value: item.id}));
        }
        if (props.name === 'p_type') {
          options = dictionaries
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        if (props.name === 'currency_name') {
          options = currency
            .map((item: any) => ({title: item.short_title, value: item.id.toString()}));
        }
        return <SelectField
          {...props}
          options={options}
          inputProps={{
            style: {
              minWidth: '100px',
              width: '100%',
            },
          }}
        />;
      default:
        return null;
    }
  }, [accounts, counterparties, currency, dictionaries]);

  const onTableCell = useCallback((col: any, record: any) => {
    switch (col.dataIndex) {
      case 'date':
        return {
          inputType: 'date',
          renderInput: renderInput,
        };
      case 'counterparty_id':
        return {
          inputType: 'select',
          renderInput: renderInput,
        };
      case 'account_id':
        return {
          inputType: 'select',
          renderInput: renderInput,
        };
      case 'p_type':
        return {
          inputType: 'select',
          renderInput: renderInput,
        };
      case 'currency_name':
        return {
          inputType: 'select',
          renderInput: renderInput,
        };
      case 'currency_rate':
        return {
          inputType: 'input',
          // style: {minWidth: '90px'},
        };
      case 'base_total':
          return {
            inputType: 'input',
            // style: {minWidth: '100px'},
          };
      case 'processed':
        return {
          inputType: 'switch',
          renderInput: renderInput,
        };
      default:
        return {
          inputType: 'text',
          // render: () => {
          //   return <div></div>;
          // },
        };
    }
  }, [renderInput]);

  const createItem = (data: any) => {
    createPayment(data, filter);
  }
  const updateItem = (data: any) => {
    updatePayment(data.id, data);
  }
  const deleteItem = (id: any) => {
    deletePayment(id);
  }

  const onChange = (pagination: any, filters: any, sorter: any) => {

    const _filter: any = cloneDeep(filter);

    const index = findIndex(filter, ['attribute', 'sort']);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(_filter[indexLimit].value) - _filter[indexLimit].value;
      (indexSkip > -1) ? _filter[indexSkip].value = skip : _filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      getPayments(_filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      _filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      _filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    getPayments(_filter);
  }

  const tableData: any [] = data.map((item: any) => {
    if (item.processed) {
      let index = -1;
      delete item.row_color;
      if ( findIndex(dictionaries, ['id', parseInt(item.p_type, 10)]) > -1) {
        index = findIndex(dictionaries, ['id', parseInt(item.p_type, 10)]);
        if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
          item.row_color = dictionaries[index].style.color;
        }
      }
      if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
        index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
        if (currency[index] && currency[index].style && currency[index].style.color) {
          item.row_color = currency[index].style.color;
        }
      }
    } else {
      item.row_color = undefined;;
    }
    return item;
  });

  let sortedInfo: any = {};
  let limit = 0;

  filter.forEach((item: any) => {
    if (item.attribute === 'sort') {
      sortedInfo = {
        order: item.value[0] === '-' ? 'descend' : 'ascend',
        columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
      };
    }
    if (item.attribute === 'limit') {
      limit = item.value;
    }
  });

  const pagination = (total_count <= limit)
    ? false
    : {
        total : total_count + Math.ceil(total_count / limit),
        pageSize: limit + 1,
      };

  const validateRules = [
    {name: 'counterparty_id', rules: ['required']},
    {name: 'base_total', rules: ['required', 'numeric']},
    {name: 'account_id', rules: ['required']},
    {name: 'p_type', rules: ['required']},
    {name: 'date', rules: ['required']},
    {name: 'currency_name', rules: ['required']},
    {name: 'currency_rate', rules: ['required', 'numeric']},
  ];

  const cols: any = (localStorage.getItem('payment-table-col-setting') || '').split(',');
  const hiddenCols: any = [ {title: 'ID', dataIndex: 'id'}];

  const columns: any[] = [
    {
      title: I18n.t('payment.Counterparty'),
      dataIndex: 'counterparty_id',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'counterparty_id' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(counterparties, ['id', value]);
        return {
          props: { style: { background: record.row_color, width: 250, minWidth: 250 } },
          children: <div>{(index > -1) && value && counterparties[index].name}</div>,
        };
      },
    },
  ];

  if (cols.indexOf('account_id') > -1 || !default_types.account) {
    columns.push({
      title: I18n.t('payment.Account'),
      dataIndex: 'account_id',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'account_id' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(accounts, ['id', value]);
        return {
          props: { style: { background: record.row_color, width: 150, minWidth: 150} },
          children: <div>{(index > -1) && value && accounts[index].name}</div>,
        };
      },
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Account'), dataIndex: 'account_id',
      render: (value: any) => {
        const index = findIndex(accounts, ['id', value]);
        return (index > -1) && value && accounts[index].name;
      }},
    );
  }

  if (cols.indexOf('p_type') > -1 || !default_types.bill) {
    columns.push({
      title: I18n.t('payment.Type'),
      dataIndex: 'p_type',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'p_type' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
        return {
          props: { style: { background: record.row_color, width: 150, minWidth: 150 } },
          children: <div>{(index > -1) && value && dictionaries[index].short_title}</div>,
        };
      },
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Type'), dataIndex: 'p_type',
      render: (value: any) => {
        const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
        return (index > -1) && value && dictionaries[index].short_title;
      }},
    );
  }

  if (cols.indexOf('currency_name') > -1 || !default_types.currency) {
    columns.push({
      title: I18n.t('payment.Currency name'),
      dataIndex: 'currency_name',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'currency_name' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(currency, ['id', parseInt(value, 10)]);
        return {
          props: { style: { background: record.row_color, width: 80, minWidth: 80 } },
          children: <div>{(index > -1) && value && currency[index].short_title}</div>,
        };
      },
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Currency name'), dataIndex: 'currency_name',
      render: (value: any) => {
        const index = findIndex(currency, ['id', parseInt(value, 10)]);
        return (index > -1) && value && currency[index].short_title;
      }},
    );
  }

  if (cols.indexOf('currency_rate') > -1) {
    columns.push({
      title: I18n.t('payment.Currency rate'),
      dataIndex: 'currency_rate',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'currency_rate' && sortedInfo.order,
      editable: true,
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color, width: 80, minWidth: 80 } },
        children: <div>{value}</div>,
      }),
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Currency rate'), dataIndex: 'currency_rate' });
  }

  columns.push({
    title: I18n.t('payment.Base total'),
    dataIndex: 'base_total',
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'base_total' && sortedInfo.order,
    editable: true,
    align: 'right',
    render: (value: any, record: any) => ({
      props: { style: { background: record.row_color, width: 120, minWidth: 120} },
      children: <div>{formatValue(value, 'currency.4')}</div>,
    }),
  });

  if (cols.indexOf('total') > -1) {
    columns.push({
      title: I18n.t('payment.Total'),
      dataIndex: 'total',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
      editable: false,
      align: 'right',
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color, width: 120, minWidth: 120} },
        children: <div>{formatValue(value, 'currency.2')}</div>,
      }),
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Total'), dataIndex: 'total', render: (value: any) => (value || '0') });
  }

  if (cols.indexOf('date') > -1) {
    columns.push({
      title: I18n.t('payment.Date'),
      dataIndex: 'date',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color, width: 120, minWidth: 120} },
        children: <div>{formatValue(value, 'date')}</div>,
      }),
    });
  } else {
    hiddenCols.push({title: I18n.t('payment.Date'), dataIndex: 'date',
    render: (value: any) => formatValue(value, 'date') });
  }

  if (cols.indexOf('note') > -1) {
    columns.push({
      title: I18n.t('general.Field_Note'),
      dataIndex: 'note',
      editable: true,
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'note' && sortedInfo.order,
      render: (value: any, record: any) => ({
        props: { style: { background: record.row_color, width: 200, minWidth: 200 } },
        children: <div>{value}</div>,
      }),
    });
  } else {
    hiddenCols.push({
      title: I18n.t('general.Field_Note'),
      dataIndex: 'note',
      render: (value: any) => value,
    });
  }

  return (
    <div style = {{overflow: 'auto', width: '100%'}} className = "small-table PaymentTable">
      <TableColSettings
        options = {[
          { label: I18n.t('payment.Counterparty'), value: 'counterparty_id', disabled: true },
          { label: I18n.t('payment.Account'), value: 'account_id', disabled: !default_types.account },
          { label: I18n.t('payment.Type'), value: 'p_type', disabled: !default_types.bill },
          { label: I18n.t('payment.Currency name'), value: 'currency_name', disabled: !default_types.currency },
          { label: I18n.t('payment.Currency rate'), value: 'currency_rate' },
          { label: I18n.t('payment.Base total'), value: 'base_total', disabled: true},
          { label: I18n.t('payment.Total'), value: 'total' },
          { label: I18n.t('payment.Date'), value: 'date' },
          { label: I18n.t('payment.Processed'), value: 'processed', disabled: true },
          { label: I18n.t('general.Field_Note'), value: 'note', disabled: false },
        ]}
        data = {localStorage.getItem('payment-table-col-setting')}
        onSave = {(values: any) => {
          // this.setState({values});
          localStorage.setItem('payment-table-col-setting', values);
        }}
      />
      <OrderItemsEditable
        onChange={onChange}
        insertNewRow="first"
        btnCreateDisabled={false}
        items={tableData}
        create={createItem}
        update={updateItem}
        delete={deleteItem}
        changeStatus = {(id: number, processed: boolean) => updatePayment(id, {processed})}

        validateRules={validateRules}
        columns={columns}
        onTableCell={onTableCell}

        initionalNewRowValues = {{
          currency_rate: '1.00',
          p_type: default_types.payment && default_types.payment.toString(),
          account_id: default_types.account,
          currency_name: default_types.currency && default_types.currency.toString(),
          date: moment().format('YYYY-MM-DD'),
        }}

        btnCreateShow={false}
        showNewLine={true}
        enableReinitialize={true}
        actionUpdateShow={(item: any) => !item.processed}
        actionDeleteShow={(item: any) => !item.processed}
        pagination={pagination}
        footer={() => <Footer totals={totals} currency={currency} />}
        operationsOnLeft={true}
        expandedRowRender = { (record: any) =>
          hiddenCols.map((item: any) => <div key={item.dataIndex}>
            {item.title} : {item.render ? item.render(record[item.dataIndex]) : record[item.dataIndex]}
          </div>)
        }
        propsTable={{
          tableLayout: 'fixed', //- | auto | fixed
          scroll: { x: true },
        }}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.payment.list_data,
  filter: state.payment.filter,
  total_count: state.payment.total_count,
  totals: state.payment.totals,
  counterparties: state.started_data.counterparties,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'payment'),
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  accounts: state.started_data.accounts,
  default_types: state.default_values.values,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getPayments,
      createPayment,
      updatePayment,
      deletePayment,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTable);
