import { CREATE_STORAGE_RECEIVED, UPDATE_STORAGE_RECEIVED, DELETE_STORAGE_RECEIVED } from './../types/StorageTypes';
import {
  GET_STORAGES,
  STORAGES_RECEIVED } from '../types/StorageTypes';
import { cloneDeep } from 'lodash';
import { getValueByKey } from '../lib/storage';
import { STORAGE_STORAGES_FILTERS } from '../types/Constants';

export interface StoragetyState {
  loading: boolean;
  list_data: any[];
  total_count: number;
  filter: any[];
}

const defaultState: StoragetyState = {
  loading: false,
  list_data: [],
  total_count: 0,
  filter: getValueByKey(STORAGE_STORAGES_FILTERS,[
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 1000,
    },
    {
      attribute: 'sort',
      value: '-createdAt',
    },
  ]),
};

export default (state: StoragetyState = defaultState, action: any) => {
  let tmpList: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_STORAGES:
      return {...state, loading: true};
    case STORAGES_RECEIVED:
      return {...state, loading: false, list_data: action.data, total_count: action.count, filter: action.filter};
    case CREATE_STORAGE_RECEIVED:
      return {...state, list_data: [action.item, ...state.list_data ], total_count: state.total_count + 1  };
    case UPDATE_STORAGE_RECEIVED:
      return {...state, list_data: state.list_data.map((item: any) => item.id === action.item.id ? action.item : item)};
    case DELETE_STORAGE_RECEIVED:
        tmpList = tmpList.filter((item: any) => item.id !== action.id);
        return {...state, list_data: tmpList, total_count: state.total_count - 1 };
    default:
       return state;
  }
};
