import { GET_BIILS, UPDATE_BILL, CREATE_BILL, DELETE_BILL } from '../../types/BillTypes';

export const getBills = (filter: any = []) => ({
  type: GET_BIILS,
  filter,
});

export const updateBill = (id: number, data: any) => ({
  type: UPDATE_BILL,
  data,
  id,
});

export const createBill = (data: any, filter: any) => ({
  type: CREATE_BILL,
  data,
  filter,
});

export const deleteBill = (id: any) => ({
  type: DELETE_BILL,
  id,
});
