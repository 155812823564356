import { connect } from 'react-redux';
import { notification } from 'antd';
import { AppState } from '../../../reducers';

notification.config({
  placement: 'bottomRight',
  bottom: 50,
  duration: 3,
});

function Error({ messages, isError }: any) {
  if (isError) {
    messages.forEach((message: any) => {
      notification.error({
        message: 'Error',
        description: message,
      });
    });
  }
  return null;
}

const mapStateToProps = (state: AppState) => ({
  isError: state.error.isError,
  messages: state.error.messages,
});

export default connect(mapStateToProps)(Error);
