import { Layout } from 'antd';
import { Route, Switch, Redirect } from 'react-router';
import Error from '../../components/Error/Error';
import Login from '../../Login/Login';

const {
  Content,
} = Layout;

function Light() {

  return (
    <div>
      <Error/>
      <Layout style={{minHeight: '100vh'}}>
        <Content>
          <Switch>
            <Route exact path="/login" component={Login}/>
            <Route render={() => {
              return <Redirect to="/login"/>;
            }}/>
          </Switch>
        </Content>
      </Layout>
    </div>
  );
}

export default Light;
