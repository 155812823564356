import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import { showModal } from '../../CategoryActions';
import { Modal } from 'antd';
import CategoryForm from '../CategoryForm/CategoryForm';
import { I18n } from 'react-redux-i18n';

interface CategoryModalProps {
  showModal: any;
  show_modal: any;
}

function CategoryModal({ showModal, show_modal}: CategoryModalProps) {

  return (
    <Modal
      footer = {null}
      title = {I18n.t('category.Categories')}
      onCancel = {() => showModal(false)}
      open={show_modal}
      destroyOnClose = {true}
    >
      <CategoryForm/>
    </Modal>
  );
}

const mapStateToProps = (state: AppState) => ({
  show_modal: state.category.show_modal,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
