import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import DictionaryList from './DictionaryList';

function Dictionary({ loading }: any) {
  return (
    <Spin spinning={ loading }>
      <Switch>
        <Route exact path="/dictionary/list" component={DictionaryList}/>
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.dictionary.loading,
});

export default connect(mapStateToProps)(Dictionary);
