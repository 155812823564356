/* eslint-disable import/no-anonymous-default-export */
export default {
  'Account': 'Каса',
  'Bill': ' Тип прихідного ордера',
  'Category': 'Тип категорії',
  'Dictionary': 'Тип словника',
  'Payment': 'Тип розхідного ордера',
  'Сlient': 'Клієнт по замовчуванню',
  'Supplier': 'Постачальник по замовчуванню',
  'Currency': 'Тип валюти',
  'Sales order': 'Тип прихідної накладної',
  'Purchase order': 'Тип розхідної накладної',
  'Transfer order': 'Тип накладної переміщення',
  'Types': 'Типи',
  'Pagination': 'Пагінація',
  'Storage': 'Cклад',
  'Category by default': 'Категорія',
  'Submit': 'Зберегти',

  'Dictionary limit': 'Словники',
  'Account limit': 'Каси',
  'Product limit': 'Каталог продуктів',
  'Storage limit': 'Склади',
  'Counterparty limit': 'Контрагенти',
  'Bill limit': 'Прихідні ордера',
  'Payment limit': 'Розхідні ордера',
  'Transfer limit': 'Переміщеня',
  'Sales order limit': 'Розхідні накладні',
  'Purchase order limit': 'Прихідні накладні',
  'Transfer order limit': 'Накладні переміщення',
};
