import { ERROR, CLEAR_ERROR } from '../types/ErrorTypes';

export interface ErrorState {
  isError: boolean;
  messages: any[];
}

const defaultState: ErrorState = {
  isError: false,
  messages: [],
};

export default (state: ErrorState = defaultState, action: any) => {
 switch (action.type) {
    case ERROR:
      return { ...state, isError: true, messages: action.messages };
    case CLEAR_ERROR:
      return { ...state, isError: false, messages: [] };
    default:
       return state;
  }
};
