import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import AccountList from './AccountList';

function Account({ loading }: any) {
  return (
    <Spin spinning={loading}>
      <Switch>
        <Route exact path="/account/list" component={AccountList} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.account.loading,
});

export default connect(mapStateToProps)(Account);
