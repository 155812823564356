import { Formik } from 'formik';
import validationFields from '../../../lib/validate';

function EditableFormRow({
  EditableContext,
  editingData,
  newData,
  save,
  validateRules,
  ...restProps
}: any) {
  return <Formik
    initialValues={restProps['data-row-key'] === 'new' ? (newData || {})  :  (editingData || {})}
    enableReinitialize={true}
    validate={(values: any) => {
      const fields: any =  [
        {name: 'product_title', rules: ['required']},
        {name: 'product_price', rules: ['required', 'numeric']},
        {name: 'quantity', rules: ['required', 'numeric']},
      ];
      return validationFields(validateRules, values);
    }}
    onSubmit={(values, { setSubmitting }) => {
      save(values);
    }}
    // render={props => ()}
  >
    {(props) => <EditableContext.Provider value={props}>
      <tr {...restProps} style={restProps.style} />
    </EditableContext.Provider>}
  </Formik>;
}

export default EditableFormRow;
