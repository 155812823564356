import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import PaymentList from './PaymentList';

function Payment({ loading, loading_counterparty, loading_storage }: any) {
  return (
    <Spin spinning={loading || loading_storage || loading_counterparty}>
      <Switch>
        <Route exact path="/payment/list" component={PaymentList}/>
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.purchase_order.loading,
  loading_storage: state.storage.loading,
  loading_counterparty: state.counterparty.loading,
});

export default connect(mapStateToProps)(Payment);
