import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_DIALOG_PRODUCTS,
  DIALOG_PRODUCTS_RECEIVED,
  SET_DIALOG_PRODUCTS_FILTER,
  } from '../types/ProductDialogTypes';
import { GenericEntitiesResponse } from '../types/baseTypes';

function* getDialogProducts({filter}: any) {
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('products' , '', generateFilter(filter));
    yield put({ type: DIALOG_PRODUCTS_RECEIVED, data, count});
    yield put({ type: SET_DIALOG_PRODUCTS_FILTER, filter });
  } catch (error) {
    yield put({ type: DIALOG_PRODUCTS_RECEIVED, data: [], count: 0 });
    yield put({ type: ERROR, messages: ['Not products']});
  }
}

export default function* actionWatcherDialogProduct() {
  yield takeLatest(GET_DIALOG_PRODUCTS, getDialogProducts);
}
