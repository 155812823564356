import { Input, Button, Form } from 'antd';
import CategoryTreeSelect from '../CategoryTreeSelect/CategoryTreeSelect';
import { createNewTreeData } from '../../../../lib/helper';
import { I18n } from 'react-redux-i18n';
import { useRef } from 'react';

function CategoryFormContent({
  values,
  errors,
  touched,
  handleChange,
  handleSubmit,
  isSubmitting,
  loading,
  categories,
}: any) {
  const isSubmit = useRef(false);

  if (isSubmitting) {
    isSubmit.current = true;
  }

  const treeData: any =  [
    { value : 0, title: I18n.t('product.All Categories'), key: '0', children : createNewTreeData(categories) }
  ];

  return (
    <Form layout="vertical">
      <Form.Item label={I18n.t('category.Parent Category')} >
        <CategoryTreeSelect
          treeData={treeData}
          value = { values.parent_id  }
          onSelect = {(value: any) => {
            handleChange({
              target: {
                name: 'parent_id',
                value,
            }});
          }}
        />
      </Form.Item>
      <Form.Item label = {I18n.t('category.Name')}
        { ...errors.name && (touched.name || isSubmit.current) && { help : errors.name, validateStatus: 'error'}}
        >
        <Input
          placeholder = {I18n.t('category.Name')}
          onChange = {handleChange}
          name = "name"
          value = {values.name}
        />
      </Form.Item>
      <Form.Item>
        <Button onClick={handleSubmit} type="primary" loading={loading}> {I18n.t('category.Submit')} </Button>
      </Form.Item>
    </Form>
  );
}

export default CategoryFormContent;
