export default {
  'Date': 'Дата',
  'Processed': 'Проведено',
  'Total': 'Сума у гривнях',
  'Counterparty': 'Контрагенти',
  'Type': 'Тип',
  'Account': 'Рахунок',
  'Base total': 'Базова сума',
  'Currency name': 'Валюта',
  'Currency rate': 'Курс',
  'Summary Total': 'Сума',
};
