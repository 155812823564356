export const GET_REPORT_ACCOUNT = 'GET_REPORT_ACCOUNT';
export const GET_REPORT_RECEIVED = 'GET_REPORT_RECEIVED';
export const CLEAR_REPORT = 'CLEAR_REPORT';
export const GET_REPORT_COUNTERPARTY = 'GET_REPORT_COUNTERPARTY';
export const GET_REPORT_COUNTERPARTY_DETAILS = 'GET_REPORT_COUNTERPARTY_DETAILS';
export const GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED = 'GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED';

export const GET_REPORT_STORAGE = 'GET_REPORT_STORAGE';
export const GET_REPORT_STORAGE_DETAILS = 'GET_REPORT_STORAGE_DETAILS';
export const GET_REPORT_STORAGE_DETAILS_RECEIVED = 'GET_REPORT_STORAGE_DETAILS_RECEIVED';
// export const GET_REPORT_STORAGE_DATA = 'GET_REPORT_STORAGE_DATA';

export const GET_REPORT_ACCOUNT_DETAILS = 'GET_REPORT_ACCOUNT_DETAILS';
export const GET_REPORT_ACCOUNT_DETAILS_RECEIVED = 'GET_REPORT_ACCOUNT_DETAILS_RECEIVED';
