import {
  GET_SALES_ORDERS,
  GET_SALES_ORDER,
  CREATE_SALES_ORDER,
  UPDATE_SALES_ORDER,
  UPDATE_SALES_ORDER_ITEM,
  CREATE_SALES_ORDER_ITEM,
  DELETE_SALES_ORDER_ITEM,
  SALES_ORDER_CLEAR_DATA,
  SALES_ORDER_PROCESSED,
  SET_SHOWMODAL_SALES_ORDER,
  SALES_ORDER_RECEIVED,
  SALES_ORDER_ITEMS_RECEIVED,
  SET_SALES_ORDER_READING_MODE,
  DELETE_SALES_ORDER,
} from '../../types/SalesOrderTypes';

export const getOrders = (filter: any = []) => ({
  type: GET_SALES_ORDERS,
  filter,
});

export const getOrder = (id: number, filter: any) => ({
  type: GET_SALES_ORDER,
  id,
  filter,
});

export const createOrder = (data: any) => ({
  type: CREATE_SALES_ORDER,
  data,
});

export const updateOrder = (id: number, data: any) => ({
  type: UPDATE_SALES_ORDER,
  data,
  id,
});

export const updateOrderItem = (id: number, data: any) => ({
  type: UPDATE_SALES_ORDER_ITEM,
  data,
  id,
});

export const createOrderItem = (data: any) => ({
  type: CREATE_SALES_ORDER_ITEM,
  data,
});

export const deleteOrderItem = (id: any, order_id: any) => ({
  type: DELETE_SALES_ORDER_ITEM,
  id,
  order_id,
});

export const clearData = () => ({
  type: SALES_ORDER_CLEAR_DATA,
});

export const setOrderProcessed = (data: boolean, filter: any) => ({
  type: SALES_ORDER_PROCESSED,
  data,
  filter,
});

export const deleteOrder = (id: number, filter: any) => ({
  type: DELETE_SALES_ORDER,
  id,
  filter,
});

export const setShowModal = (status: boolean) => ({
  type: SET_SHOWMODAL_SALES_ORDER,
  status,
});

export const clearOrder = () => ({
  type: SALES_ORDER_RECEIVED,
  order: {},
});

export const clearOrderItems = () => ({
  type: SALES_ORDER_ITEMS_RECEIVED,
  items: [],
});

export const setReadingMode = (status: boolean) => ({
  type: SET_SALES_ORDER_READING_MODE,
  status,
});
