import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { clearData } from './SalesOrderActions';
import { AppState } from '../../reducers';
import SalesOrderCreateContent from './components/SalesOrderCreateContent/SalesOrderCreateContent';
import { useEffect } from 'react';

function SalesOrderCreate({ clearData }: any) {
  useEffect(() => {
    clearData();
  }, [clearData]);

  return <SalesOrderCreateContent />;
}

const mapStateToProps = (state: AppState) => ({
  order: state.sales_order.order,
  items: state.sales_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderCreate);
