import { Form, Select } from 'antd';

function FieldSelect({ attribute, values, is_mobile, handleChange, handleSubmit }: any) {

  const handleOnChange = (value: any) => {
    const valueAtt: any = {
      attribute: attribute.name,
      value,
    };

    if (attribute.comparison) {
      valueAtt.comparison = attribute.comparison;
    }
    handleChange({
      target: {
        name: attribute.name,
        value: value ? valueAtt : '',
      },
    });
    setTimeout(() => {
      handleSubmit();
    }, 200);
  }

  const value = values?.value;

  return (
    <Form.Item>
      <Select
        showSearch
        style={{ width: is_mobile ? '100%' : 168 }}
        placeholder={attribute.title}
        optionFilterProp="children"
        allowClear={true}
        value={value}
        onChange={handleOnChange}
        filterOption={(input: any, option: any) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {attribute.options.map((option: any) => <Select.Option
          key = { option.title + '-' + option.value} value={option.value}>{option.title}</Select.Option>)}
      </Select>
    </Form.Item>
  );
}

export default FieldSelect;
