// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Create': 'Створити',
  'Product Title': 'Назва товару',
  'Price': 'Ціна',
  'Quantity': 'Кількість',
  'Storage': 'Склад',
  'Date': 'Дата',
  'Purchase Order Title': 'Прихідна накладна',
  'Processed': 'Проведено',
  'Total Price': 'Загальна вартість',
  'Sub total': 'Сума зі знижкою',
  'Base total': 'Базова сума',
  'Operation': 'Дія',
  'Type': 'Тип',
  'Note': 'Примітка',
  'Save': 'Зберегти',
  'Cancel': 'Закрити',
  'Discount': 'Знижка',
  'Sure to delete?': 'Ви впевнені?',
  'Close': 'Закрити',
  'Ok': 'Видалити',
  'IS PROCESSED' : 'ПРОВЕДЕНО',
  'Currency name': 'Валюта',
  'Currency rate': 'Курс',
  'Summary Total': 'Сума',
  'Process': 'Провести',
  'Update': 'Редагувати',
  'Delete': 'Видалити',
  'View': 'Переглянути',
  'Un process': 'Відмінити',
  'Operations': 'Дії',
};
