// import RestClient from './mockup_rest/rest-client';
import RestClient from 'flex-rest-client';
import { IRestClient } from '../types/baseTypes';

const rs: IRestClient = new RestClient();

// export default rs;

function checkHttpStatus(response: any) {
  if (response.status >= 200 && response.status < 300) {
      return response;
  } else {
      return Promise.reject(response);
  }
}

function makeRequest({url, method, headers, bodyJSObject}: any) {
  const body: any = bodyJSObject ? JSON.stringify(bodyJSObject) : undefined;
  return fetch(url,
      {
          method: method || 'GET',
          headers,
          body,
      }).then(checkHttpStatus).then((response: any) => {
      return response.json();
  });
}

const options: any = {
  baseUrl: process.env.REACT_APP_API_URL || 'http://192.168.1.102:3010/admin',
  headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
  },
  requestMethod: makeRequest,
};

if (localStorage.getItem('token')) {
  options.headers.Authorization = 'Bearer ' + localStorage.getItem('token');
}

rs.config(options);

export default rs;
