import {
  GET_TRANSFERS,
  CREATE_TRANSFER,
  UPDATE_TRANSFER,
  DELETE_TRANSFER,
} from '../../types/TransferTypes';

export const getTransfers = (filter: any = []) => ({
  type: GET_TRANSFERS,
  filter,
});

export const createTransfer = (data: any, filter: any) => ({
  type: CREATE_TRANSFER,
  data,
  filter,
});

export const updateTransfer = (id: number, data: any) => ({
  type: UPDATE_TRANSFER,
  data,
  id,
});

export const deleteTransfer = (id: any) => ({
  type: DELETE_TRANSFER,
  id,
});
