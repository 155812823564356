import { Table } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import { getProducts, showModal, setProduct } from '../../ProductActions';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { useCallback } from 'react';
import { find } from 'lodash';

function ProductTable({
  getProducts,
  showModal,
  setProduct,
  filter,
  data,
  total_count,
  categoryNotSelected,
  categories,
  product_types,
}: any) {
  const onChange = useCallback((pagination: any, _filters: any, sorter: any) => {
    const filter: any = cloneDeep(_filters);
    const index = findIndex(filter, ['attribute', 'sort']);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      indexSkip > -1 ? (filter[indexSkip].value = skip) : filter.push({ attribute: 'skip', value: skip });
    }

    if (!Object.keys(sorter).length) {
      filter[index].value = '-createdAt';
      getProducts(filter);
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({ attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field });
    }

    getProducts(filter);
  }, [getProducts]);

  const customExpandIcon = useCallback(({ expanded, record, onExpand }) => {
    return (
      <a
        onClick={(e: any) => {
          onExpand(record, e);
        }}
      >
        {expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
      </a>
    );
  }, []);

  let sortedInfo: any = {};
  let limit = 0;

  filter.forEach((item: any) => {
    if (item.attribute === 'sort') {
      sortedInfo = {
        order: item.value[0] === '-' ? 'descend' : 'ascend',
        columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
      };
    }
    if (item.attribute === 'limit') {
      limit = item.value;
    }
  });

  let pagination = {};
  if (total_count <= limit) {
    pagination = false;
  } else {
    pagination = {
      total: total_count,
      pageSize: limit,
    };
  }

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '5px',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
    },
    {
      title: I18n.t('product.Product Title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
    },
    {
      title: I18n.t('product.Product type'),
      dataIndex: 'product_type',
      key: 'product_type',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'product_type' && sortedInfo.order,
      render: (value: any, record: any) => {
        const data = (find(product_types, ['id', parseInt(value, 10)]) || { short_title: '' }).short_title;
        return {
          props: { style: { background: record.row_color } },
          children: <div>{data}</div>,
        };
      },
    },
    {
      title: I18n.t('product.Price'),
      dataIndex: 'price',
      key: 'price',
      width: '6%',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
    },
  ];

  if (categoryNotSelected) {
    columns.push({
      title: I18n.t('product.Category'),
      dataIndex: 'category_id',
      key: 'category_id',
      width: '15%',
      render: (cat: number) => categories.map((item: any) => (item.id === cat ? item.name : '')),
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'category_id' && sortedInfo.order,
    });
  }

  return (
    <div style={{ paddingLeft: 5 }} className="small-table no-status">
      <Table
        bordered
        rowKey={(record: any) => record.id}
        columns={columns}
        dataSource={data}
        onChange={onChange}
        pagination={pagination}
        expandIcon={customExpandIcon}
        expandedRowRender={(record: any) => (
          <div style={{ margin: 0 }}>
            <p>
              <a
                onClick={() => {
                  setProduct(record);
                  showModal(true);
                }}
              >
                {I18n.t('product.Update')}
              </a>
            </p>
            <p>
              {' '}
              {I18n.t('product.Date')}: {moment(record.createdAt).format('DD-MM-YYYY HH:mm')}{' '}
            </p>
            <p>
              {' '}
              {I18n.t('product.Description')}: {record.description}{' '}
            </p>
            <p>
              {' '}
              {I18n.t('product.Barcode')}: {record.barcode}{' '}
            </p>
            <p>
              {' '}
              {I18n.t('product.Published')}: {record.published ? I18n.t('product.Yes') : I18n.t('product.No')}{' '}
            </p>
          </div>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.product.filter,
  total_count: state.product.total_count,
  product_types: state.started_data.dictionaries.filter((item: any) => item.d_type === 'product_type'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProducts,
      showModal,
      setProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProductTable);
