export const STARTED_DATA_RECEIVED = 'STARTED_DATA_RECEIVED';
export const GET_STARTED_DATA = 'GET_STARTED_DATA';

export const UPDATE_STARTED_DATA_ITEM = 'UPDATE_STARTED_DATA_ITEM';
export const CREATE_STARTED_DATA_ITEM = 'CREATE_STARTED_DATA_ITEM';
export const DELETE_STARTED_DATA_ITEM = 'DELETE_STARTED_DATA_ITEM';

export const UPDATE_STARTED_DATA_COUNTERPARTY = 'UPDATE_STARTED_DATA_COUNTERPARTY';
export const CREATE_STARTED_DATA_COUNTERPARTY = 'CREATE_STARTED_DATA_COUNTERPARTY';
export const DELETE_STARTED_DATA_COUNTERPARTY = 'DELETE_STARTED_DATA_COUNTERPARTY';

export const UPDATE_STARTED_DATA_PRODUCT = 'UPDATE_STARTED_DATA_PRODUCT';

