import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ProductTable from './components/ProductTable/ProductTable';
import { Button, Row, Col, Switch } from 'antd';
import { AppState } from '../../reducers';
import { createNewTreeData } from '../../lib/helper';
import ProductCategoryTree from './components/ProductCategoryTree/ProductCategoryTree';
import ProductModal from './components/ProductModal/ProductModal';
import Filter from '../components/Filter/Filter';
import findIndex from 'lodash.findindex';
import {
  getProducts,
  setSelectedCategoryId,
  showModal,
  setProduct,
} from './ProductActions';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';
import ProductCategorySelect from './components/ProductCategorySelect/ProductCategorySelect';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

class ProductList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      checked: true,
      previousCategory: 0,
    };
  }

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const product_limit: number = JSON.parse(retrievedObject).product_limit;
    const filter: any = cloneDeep(this.props.filter);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      filter[indexLimit].value = product_limit || 5;
    } else {
      filter.push({ attribute: 'limit', value: 5 });
    }

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }

    this.setState({
      selected_type: (filter.find((item: any) => item.attribute === 'product_type') || { value: '' }).value,
    })

    this.props.getProducts(filter);

    this.props.setSelectedCategoryId(0);
  }

  onChangeFilter = (filter: any) => {
    this.props.getProducts(filter);
  }

  onChange = (checked: boolean) => {
    this.setState({
      checked,
    });
    if (!checked) {
      this.props.setSelectedCategoryId({});
      this.props.getProducts(this.props.filter.filter((item: any) => item.attribute !== 'category_id' ));
    } else {
      this.props.setSelectedCategoryId(this.state.previousCategory);
      if (this.state.previousCategory) {
        this.props.filter.push({attribute: 'category_id', value: this.state.previousCategory});
        this.props.getProducts(this.props.filter);
      }
    }
  }

  onSelect = (id: any) => {
    if (id[0]) {
      if (id[0] !== '0') {
        this.props.filter.push({attribute: 'category_id', value: id});
        this.props.getProducts(this.props.filter);
      } else {
        this.props.getProducts( this.props.filter.filter((item: any) => item.attribute !== 'category_id' ));
      }
      this.setState({previousCategory: parseInt(id, 10 )});
      this.props.setSelectedCategoryId( parseInt(id, 10 ));
    }
  }

  public render() {
    const { categories, data, filter, selected_category_id, is_mobile, product_types } = this.props;
    const treeData: any =  [
      { value : '0', title: I18n.t('product.All Categories'), key: '0', children : createNewTreeData(categories) }
    ];

    const isSelectedCategory = filter.find((item: any) => item.attribute === 'category_id');
    return (
      <div>
        <ProductModal product_types={product_types} />
        <Row>
          <Col span = {12}>
            <Row>
              <Filter
                onChange={this.onChangeFilter}
                initialValues={filter}
                attributes={[
                  {
                    name: 'title',
                    title: I18n.t('product.Product Title'),
                    hyper_type: 'input',
                    comparison: 'contains',
                  },
                  {
                    name: 'published',
                    title: I18n.t('product.Product status'),
                    hyper_type: 'select',
                    options: [{ title: 'Опубліковано', value: 'true'}, {title: 'Неопубліковано', value: 'false'}],
                  },
                  {
                    name: 'product_type',
                    title:I18n.t('product.Product type'),
                    hyper_type: 'select',
                    options: product_types.map((item: any) => {
                      return {
                        title: item.title,
                        value: item.id,
                      };
                    }),
                  },
                ]}
              />
            </Row>
            <Row>
              <Switch
                style = {{margin : '10px'}}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
                onChange = {this.onChange}
              />
            </Row>
          </Col>
          <Col span = {12} style = {{display: 'flex', justifyContent: 'flex-end'}}>
            <Button type="primary"
              onClick={ () => {
                this.props.setProduct( selected_category_id !== 0 ? {category_id : selected_category_id} : {});
                this.props.showModal(true);
              }}
              style = {{marginTop : '4px'}} > {I18n.t('product.Create')} </Button>
        </Col> </Row>
        <Row>
          <Col md = {5} xs = {24} sm = {24}
            style = {{overflow: 'auto'}}
          >
            {this.state.checked && (
              is_mobile ?
              <ProductCategorySelect
                selectedKeys = {selected_category_id}
                treeData={treeData}
                onSelect = {this.onSelect}
              /> :
              <ProductCategoryTree
                selectedKeys = {selected_category_id}
                categories = {treeData}
                onSelect = {this.onSelect}
              />
            )}
          </Col>
          <Col md = {this.state.checked ? 19 :  24}  xs = {24} sm = {24} style = {{overflow: 'auto'}} >
            <ProductTable
              data = {data}
              categories = {categories}
              categoryNotSelected = {!isSelectedCategory}
            />
        </Col> </Row>
      </div>
    );
}
}

const mapStateToProps = (state: AppState) => ({
  selected_category_id: state.product.selected_category_id,
  filter: state.product.filter,
  categories: state.started_data.categories,
  data: state.product.list_data,
  is_mobile: state.auth.is_mobile,
  product_types: state.started_data.dictionaries.filter((dictionary: any) => dictionary.d_type === 'product_type') || []
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProducts,
      setSelectedCategoryId,
      showModal,
      setProduct,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
