import { getValueByKey } from '../lib/storage';
import { GET_BIILS,
  BILLS_RECEIVED,
  CREATE_BILL_RECEIVED,
  UPDATE_BILL_RECEIVED,
  DELETE_BILL_RECEIVED,
} from '../types/BillTypes';
import { STORAGE_BILL_FILTERS } from '../types/Constants';

export interface BillState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  total_count: number;
  totals: any;
}

const defaultState: BillState = {
  loading: false,
  list_data: [],
  total_count: 0,
  totals: {
    total: 0,
    sub_total: 0,
  },
  filter: getValueByKey(STORAGE_BILL_FILTERS ,[
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 5,
    },
    {
      attribute: 'sort',
      value: '-id',
    },
  ]),
};

export default (state: BillState = defaultState, action: any) => {
 switch (action.type) {
    case GET_BIILS:
      return {...state, loading: true};
    case BILLS_RECEIVED:
      return {...state,
        loading: false,
        list_data: action.data,
        filter: action.filter,
        total_count: action.count || 0,
        totals: action.totals || 0,
      };
    case CREATE_BILL_RECEIVED:
      return {...state, list_data: [action.data, ...state.list_data], total_count: state.total_count + 1};
    case UPDATE_BILL_RECEIVED:
      return {...state,
        list_data: state.list_data.map((item: any) => item.id === action.id ? action.data : item),
        totals: action.totals || 0,
      };
    case DELETE_BILL_RECEIVED:
      return {...state,
        list_data: state.list_data.filter((item: any) => item.id !== action.id),
        total_count: state.total_count - 1,
      };
    default:
       return state;
  }
};
