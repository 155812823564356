import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrder, setReadingMode } from './TransferOrderActions';
import { AppState } from '../../reducers';
import TransferOrderCreateContent from './components/TransferOrderCreateContent/TransferOrderCreateContent';

function TransferOrderView({ match, getOrder, setReadingMode }: any) {

  useEffect(() => {
    if (match?.params?.id) {
      getOrder(match.params.id, []);
    }
    setReadingMode(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TransferOrderCreateContent />
  );
}

const mapStateToProps = (state: AppState) => ({
  items: state.transfer_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setReadingMode,
      getOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderView);
