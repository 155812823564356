import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik } from 'formik';
import validationFields from '../../../../lib/validate';
import { AppState } from '../../../../reducers';
import PurchaseOrderFormContent from './PurchaseOrderFormContent';
import { setShowModal } from '../../PurchaseOrderActions';
import { getDefaultValues } from '../../../DefaultValue/DefaultValueActions';
import moment from 'moment';
import './PurchaseOrderForm.scss'
import { useEffect } from 'react';

const purchaseOrderFormRules = [
  { name: 'date', rules: ['required'] },
  { name: 'storage_id', rules: ['required'] },
  { name: 'counterparty_id', rules: ['required'] },
  { name: 'p_o_type', rules: ['required'] },
  { name: 'discount', rules: ['required', 'numeric', { name: 'gte', value : 0 }, { name: 'lte', value : 100 }] },
  { name: 'currency_name', rules: ['required'] },
  { name: 'currency_rate', rules: ['required', 'numeric', { name: 'gte', value : 0 }] },
];


function PurchaseOrderForm({
  getDefaultValues,
  onChange,
  setShowModal,
  loading,
  order,
  counterparties,
  storages,
  isReadingMode,
  currency,
  types,
  default_types,
  is_mobile,
}: any) {

  useEffect(() => {
    getDefaultValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  order.currency_rate = order.currency_rate ? order.currency_rate : '1.00';

  if (!order.id && default_types.supplier) {
    const index: number = counterparties.map((e: any) => e.id ).indexOf(default_types.supplier);
    if (index > -1) {
      order.discount = counterparties[index].discount;
    }
  }

  order.discount = order.discount ? order.discount : '0.00';
  order.currency_name = order.currency_name ?
    order.currency_name : (default_types.currency && default_types.currency.toString());

  order.storage_id = order.storage_id ?
    order.storage_id : default_types.storage;

  order.p_o_type = order.p_o_type ?
    order.p_o_type : (default_types.purchase_order && default_types.purchase_order.toString());

  order.date = order.date ? order.date : moment().format('YYYY-MM-DD');
  order.counterparty_id = order.counterparty_id ? order.counterparty_id : default_types.supplier;

  order.note = order.note ? order.note : '';

  return (
    <Formik
      initialValues={order}
      enableReinitialize={true}
      validate={(values: any) => {
        return validationFields(purchaseOrderFormRules, values);
      }}
      onSubmit={(values, { setSubmitting }) => {
        if (onChange) {
          onChange(values);
        }
        setSubmitting(loading);
      }}
    >
      {(props) => <PurchaseOrderFormContent {...props}
        loading={loading}
        is_mobile = {is_mobile}
        isReadingMode = {isReadingMode}
        setShowModal = {setShowModal}
        counterparties={counterparties.map((item: any) => ({
          title: item.name, value: item.id, discount: item.discount,
        }))}
        storages={storages.map((item: any) => ({title: item.name, value: item.id}))}
        types={types.map((item: any) => ({title: item.short_title, value: item.id}))}
        currency = {currency.map((item: any) => ({title: item.short_title, value: item.id })) }
      />}
    </Formik>
  );
}

const mapStateToProps = (state: AppState) => ({
  isReadingMode: state.purchase_order.isReadingMode,
  loading: state.purchase_order.loading,
  storages: state.started_data.storages,
  counterparties: state.started_data.counterparties,
  types: state.started_data.dictionaries.filter((item: any) => item.d_type === 'purchase_order'),
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency' ),
  default_types: state.default_values.values,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setShowModal,
      getDefaultValues,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderForm);
