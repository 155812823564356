import {
  GET_REPORT_COUNTERPARTY,
  GET_REPORT_COUNTERPARTY_DETAILS,
  GET_REPORT_ACCOUNT,
  GET_REPORT_RECEIVED,
  CLEAR_REPORT,
  GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED,
  GET_REPORT_ACCOUNT_DETAILS_RECEIVED,
  GET_REPORT_STORAGE_DETAILS_RECEIVED,
} from '../types/ReportTypes';

export interface ReportState {
  loading: boolean;
  report: any[];
  filter: any[];
  total_count: number;
  report_details: any[];
}

const defaultState: ReportState = {
  loading: false,
  report: [],
  filter: [],
  total_count: 0,
  report_details: [],
};

const reportReducer = (state: ReportState = defaultState, action: any) => {
  switch (action.type) {
    case GET_REPORT_COUNTERPARTY_DETAILS:
      return {...state, loading: true};
    case GET_REPORT_COUNTERPARTY:
      return {...state, loading: true};
    case GET_REPORT_ACCOUNT:
      return {...state, loading: true};
    case GET_REPORT_RECEIVED:
      return {...state, loading: false, report: action.data, filter: action.filter, total_count: action.count};
    case GET_REPORT_COUNTERPARTY_DETAILS_RECEIVED:
      return {...state, loading: false, report_details: action.data};
    case GET_REPORT_ACCOUNT_DETAILS_RECEIVED:
      return {...state, loading: false, report_details: action.data};
    case GET_REPORT_STORAGE_DETAILS_RECEIVED:
      return {...state, loading: false, report_details: action.data};
    case CLEAR_REPORT:
      return defaultState;
    default:
       return state;
  }
};

export default reportReducer;
