export const GET_DICTIONARIES = 'GET_DICTIONARIES';

export const DICTIONARY_TYPES_RECEIVED = 'TYPES_RECEIVED';
export const ADD_DICTIONARY_TYPES = 'ADD_DICTIONARY_TYPES';

export const DICTIONARIES_RECEIVED = 'DICTIONARIES_RECEIVED';

export const CREATE_DICTIONARY = 'CREATE_DICTIONARY';
export const UPDATE_DICTIONARY = 'UPDATE_DICTIONARY';
export const DELETE_DICTIONARY = 'DELETE_DICTIONARY';
export const CREATE_DICTIONARY_RECEIVED = 'CREATE_DICTIONARY_RECEIVED';
export const UPDATE_DICTIONARY_RECEIVED = 'UPDATE_DICTIONARY_RECEIVED';
export const DELETE_DICTIONARY_RECEIVED = 'DELETE_DICTIONARY_RECEIVED';

export const SET_DICTIONARIES_FILTER = 'SET_DICTIONARIES_FILTER';
