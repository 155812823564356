export default {
  'Title': 'Назва',
  'From account': 'З каси',
  'To account': 'В касу',
  'Total': 'Сума',
  'Note': 'Примітка',
  'Date': 'Дата',
  'Account to': 'З рахунку',
  'Account from': 'На рахунок',
  'Currency name': 'Валюта',
  'Currency rate': 'Курс',
};
