import * as React from 'react';
import { Input, Button, Select, DatePicker, Row, Col, Form } from 'antd';
import moment from 'moment';
import histoty from '../../../../lib/histoty';
import { I18n } from 'react-redux-i18n';
import isEqual from 'lodash.isequal';
import locale from 'antd/es/date-picker/locale/uk_UA';

class SalesOrderFormContent extends React.Component<any, any> {
  isSubmit: boolean;
  constructor(props: any) {
    super(props);
    this.isSubmit = false;
  }

  componentDidMount() {
    //
  }

  public render() {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      counterparties,
      storages,
      isSubmitting,
      loading,
      types,
      isReadingMode,
      currency,
      initialValues,
    }: any = this.props;

    if (isSubmitting) {
      this.isSubmit = true;
    }

    let disableButton: boolean = false;
    if ( initialValues.id && isEqual(initialValues, values)) {
      disableButton = true;
    }

    return (
      <Form
        onFinish={handleSubmit}
        className="sales-order-form"
        layout="vertical"
      >
        <h3> {isReadingMode ? I18n.t('sales_order.IS PROCESSED')  : ''} </h3>
        <Row gutter={16}>
          <Col span={12} xs = {24}>
            <Form.Item
              { ...errors.date && (touched.date ||  this.isSubmit) && { help : errors.date, validateStatus: 'error'}}
              label = {I18n.t('sales_order.Date')}
            >
              <DatePicker
                locale={locale}
                disabled = {isReadingMode ? true : false}
                style={{width: '100%'}}
                defaultValue={values.date && moment(values.date)}
                value={values.date && moment(values.date)}
                onOpenChange={(status: any) => {
                  if (!status) {
                    handleBlur({target: {name: 'date'}});
                  }
                }}
                onChange={(date) => {
                  this.props.handleChange({
                    target: {
                      name: 'date',
                      value: date,
                    },
                  });
                }}
                placeholder={I18n.t('sales_order.Date')}
              />
            </Form.Item>
          </Col>

          <Col span={12} xs = {24}>
            <Form.Item
              { ...errors.counterparty_id
                && (touched.counterparty_id ||  this.isSubmit)
                && { help : errors.counterparty_id, validateStatus: 'error'}
              }
              label = {I18n.t('sales_order.Counterparties')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{ width: '100%' }}
                placeholder={I18n.t('sales_order.Counterparties')}
                optionFilterProp="children"
                allowClear={true}
                value={values.counterparty_id}
                onBlur={(value) => {handleBlur({target: {name: 'counterparty_id', value}}); }}
                onChange={(value: any) => {
                  const index: number = counterparties.map((e: any) => e.value ).indexOf(value);
                  if (index > -1) {
                    this.props.handleChange({ target: {name: 'discount', value: counterparties[index].discount || 0}});
                  }
                  this.props.handleChange({
                    target: {
                      name: 'counterparty_id',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {counterparties.map((option: any) =>
                  <Select.Option key={option.value} value={option.value}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}  xs = {12}>
          <Form.Item
            { ...errors.storage_id
              && (touched.storage_id ||  this.isSubmit)
              && { help : errors.storage_id, validateStatus: 'error'}}
            label = {I18n.t('sales_order.Storage')}
          >
            <Select
              disabled = {isReadingMode ? true : false}
              showSearch
              style={{width: '100%'}}
              placeholder={I18n.t('sales_order.Storage')}
              optionFilterProp="children"
              allowClear={true}
              value={values.storage_id}
              onBlur={(value) => {handleBlur({target: {name: 'storage_id', value}}); }}
              onChange={(value: any) => {
                this.props.handleChange({
                  target: {
                    name: 'storage_id',
                    value,
                  },
                });
              }}
              filterOption={(input: any, option: any) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {storages.map((option: any) => <Select.Option value={option.value}>{option.title}</Select.Option>)}
            </Select>
          </Form.Item>
          </Col>
          <Col span={8} xs = {12}>
            <Form.Item
              { ...errors.s_o_type
                && (touched.s_o_type ||  this.isSubmit)
                && { help : errors.s_o_type, validateStatus: 'error'}}
              label = {I18n.t('sales_order.Type')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{width: '100%'}}
                placeholder={I18n.t('sales_order.Type')}
                optionFilterProp="children"
                allowClear={true}
                value = { values.s_o_type}
                onBlur={(value) => {handleBlur({target: {name: 's_o_type', value}}); }}
                onChange={(value: string) => {
                  this.props.handleChange({
                    target: {
                      name: 's_o_type',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {types.map((option: any) =>
                  <Select.Option key={option.value} value={option.value.toString()}>{option.title}</Select.Option>)}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}  xs = {24}>
            <Form.Item
              // validateStatus={'error'}
              // help={'Error'}
              label = {I18n.t('sales_order.Note')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('sales_order.Note')}
                name={'note'}
                value={values.note}
                onChange={(e) => {
                  this.props.handleChange({
                    target: {
                      name: 'note',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              { ...errors.currency_name
                && (touched.currency_name ||  this.isSubmit)
                && { help : errors.currency_name, validateStatus: 'error'}}
                label = {I18n.t('sales_order.Currency name')}
            >
              <Select
                disabled = {isReadingMode ? true : false}
                showSearch
                style={{ width: '100%' }}
                placeholder={I18n.t('sales_order.Currency name')}
                optionFilterProp="children"
                allowClear={true}
                value={values.currency_name}
                onBlur={(value) => {handleBlur({target: {name: 'currency_name', value}}); }}
                onChange={(value: any) => {
                  this.props.handleChange({
                    target: {
                      name: 'currency_name',
                      value,
                    },
                  });
                }}
                filterOption={(input: any, option: any) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {currency.map((option: any) =>
                  <Select.Option key = {option} value={option.value.toString()}>{option.title}</Select.Option>)
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              { ...errors.currency_rate
                && (touched.currency_rate ||  this.isSubmit)
                && { help : errors.currency_rate, validateStatus: 'error'}}
              label = {I18n.t('sales_order.Currency rate')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('sales_order.Currency rate')}
                name={'currency_rate'}
                value={values.currency_rate}
                onChange={(e) => {
                this.props.handleChange({
                  target: {
                    name: 'currency_rate',
                    value: e.target.value,
                  },
                });
              }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              { ...errors.discount
                && (touched.discount ||  this.isSubmit)
                && { help : errors.discount, validateStatus: 'error'}}
              label = {I18n.t('sales_order.Discount')}
            >
              <Input
                disabled = {isReadingMode ? true : false}
                placeholder={I18n.t('sales_order.Discount')}
                name={'discount'}
                value={values.discount}
                onBlur={(value) => {handleBlur({target: {name: 'discount', value}}); }}
                onChange={(e) => {
                  this.props.handleChange({
                    target: {
                      name: 'discount',
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        { !isReadingMode ?
        <Form.Item >
          <Button
            disabled = { disableButton }
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            {I18n.t('sales_order.Save')}
          </Button>
          <Button
            htmlType="button"
            style={{marginLeft: '10px'}}
            type="default"
            onClick={() => {
              if (this.props.setShowModal) {
                this.props.setShowModal(false);
              }
              histoty.push('/sales_order/list');
            }}
          >
            {I18n.t('sales_order.Cancel')}
          </Button>
        </Form.Item> : ''}
      </Form>
    );
  }
}

export default SalesOrderFormContent;
