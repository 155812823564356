import { GET_DEFAULT_VALUES, SET_DEFAULT_VALUES } from '../../types/DefaultValues';
import { GET_DICTIONARIES } from '../../types/DictionaryTypes';

export const getDefaultValues = () => ({
  type: GET_DEFAULT_VALUES,
});

export const setDefaultValues = (data: any) => ({
  type: SET_DEFAULT_VALUES,
  data,
});

export const getDictionaries = (filter: any = []) => ({
  type: GET_DICTIONARIES,
  filter,
});
