import FieldDate from './Fields/FieldDate';
import FieldInput from './Fields/FieldInput';
import FieldSelect from './Fields/FieldSelect';

function FormField(props: any) {
  const { attribute } = props;
  if (attribute.render) {
    return attribute.render({...props});
  }
  switch (attribute.hyper_type) {
  case 'date':
    return <FieldDate {...props}/>;
  case 'input':
    return <FieldInput {...props}/>;
  case 'select':
    return <FieldSelect {...props}/>;
  default:
    return null;
  }
}

export default FormField;
