export interface AuthState {
  loading: boolean;
  logined: boolean;
  is_mobile: boolean;
  profile: any;
}

const defaultState: AuthState = {
  loading: true,
  logined: false,
  is_mobile: false,
  profile: {},
};

export default  (state: AuthState = defaultState, action: any) => {
 switch (action.type) {
    case 'LOGIN':
      return { ...state, loading: true, errors: [] };
    case 'AUTH':
      return { ...state, loading: false, logined: action.logined, profile: action.profile };
    case 'SET_MOBILE':
      return { ...state, is_mobile: action.value };
    default:
       return state;
  }
};
