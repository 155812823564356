import * as React from 'react';
import { SketchPicker } from 'react-color';
import './style.scss';

class ColorPicker extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: '#FFFFFF',
        };
    }

    componentDidMount() {
        if (this.props.values && this.props.values.style && this.props.values.style.color) {
            this.setState({color: this.props.values.style.color});
        }

        if (this.props.color) {
            this.setState({color: this.props.color});
        }
    }

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    }

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    }

    handleChange = (color: any) => {
        let value: string = '';
        if (color.hex !== '#ffffff') {
            value = color.hex;
        }
        this.props.handleChange({
            target: {
              name: this.props.name,
              value,
            },
          });
        this.setState({ color: color.hex });
    }

  public render() {
    const { disablePicker } = this.props;

    return (
        <div className = {`color-picker ${ disablePicker ? 'disabled' : ''}`}>
            <div className="swatch" onClick={ this.handleClick }>
                <div style={{
                    background: this.state.color,
                }} />
            </div>

            { this.state.displayColorPicker && !disablePicker ? <div className="popover" >
                <div className="cover" onClick={ this.handleClose }/>
                <SketchPicker color={ this.state.color } onChange={ this.handleChange } disableAlpha  = {true}/>
            </div> : null }
      </div>
    );
  }
}

export default ColorPicker;
