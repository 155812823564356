export default {
    from: 'з',
    to: 'по',
    Period: 'Період',

    'Today': 'Сьогодні',
    'Yesterday': 'Вчора',
    'This week': 'Цей тиждень',
    'Previous week': 'Попередній тиждень',
    'Last 7 days': 'Останні 7 днів',
    'This month': 'Цей місяць',
    'Previous month': 'Попередній місяць',
    'Last 30 days': 'Останні 30 днів',
};
