import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  getOrder,
  clearData,
} from './PurchaseOrderActions';
import { AppState } from '../../reducers';
import PurchaseOrderCreateContent from './components/PurchaseOrderCreateContent/PurchaseOrderCreateContent';

class PurchaseOrderCreate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  public render() {
    return <PurchaseOrderCreateContent/>;
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder,
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderCreate);
