import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import SalesOrderTable from './components/SalesOrderTable/SalesOrderTable';
import { AppState } from '../../reducers';
import Filter from '../components/Filter/Filter';
import { Button } from 'antd';
import Modal from './components/Modal/Modal';
import {
  getOrders,
  setShowModal,
  clearOrder,
  clearOrderItems,
} from './SalesOrderActions';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';
import SalesOrderMobileTable from './components/SalesOrderMobileTable/SalesOrderMobileTable';

class SalesOrderList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const s_o_limit: number = JSON.parse(retrievedObject).s_o_limit;
    const filter: any = cloneDeep(this.props.filter);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);

    if (indexLimit > -1) {
      filter[indexLimit].value = s_o_limit || 10;
    } else {
      filter.push({ attribute: 'limit', value: 10 });
    }

    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      filter[indexSkip].value = 0;
    }

    this.props.getOrders(filter);
  }

  onChangeFilter = (filter: any) => {
    this.props.getOrders(filter);
  }

  public render() {
    const {filter, counterparts, storages, types, is_mobile} = this.props;
    return (
      <div>
        <Modal
          title={I18n.t('sales_order.Sales Order Title')}
        />
        <Filter
          onChange={this.onChangeFilter}
          initialValues={filter}
          attributes={[
            {
              name: 'date',
              title: I18n.t('sales_order.Date'),
              hyper_type: 'date',
              comparison: 'date_is_within'},
            // {name: 'order_name', title: 'Name ddf', hyper_type: 'input', comparison: 'contains'},
            {
              name: 'counterparty_id',
              title: I18n.t('sales_order.Counterparty'),
              hyper_type: 'select',
              options: counterparts.map((item: any) => {
                return {
                  title: item.name,
                  value: item.id,
                };
              }),
            },
            {
              name: 'storage_id',
              title: I18n.t('sales_order.Storage'),
              hyper_type: 'select',
              options: storages.map((item: any) => {
                return {
                  title: item.name,
                  value: item.id,
                };
              }),
            },
            {
              name: 's_o_type',
              title: I18n.t('sales_order.Type'),
              hyper_type: 'select',
              options: types.map((item: any) => {
                  return {
                    title: item.short_title,
                    value: item.id,
                  };
              }),
            },
          ]}
        />
        <Button
          style={{
            margin: '16px 0',
          }} 
          onClick={() => {
            this.props.clearOrder();
            this.props.clearOrderItems();
            this.props.setShowModal(true);
          }}
        >
          {I18n.t('sales_order.Create')}
        </Button>

        <SalesOrderTable/>
        
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.sales_order.filter,
  is_mobile: state.auth.is_mobile,
  storages: state.started_data.storages,
  counterparts: state.started_data.counterparties,
  types: state.started_data.dictionaries.filter((item: any) => item.d_type === 'sales_order'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrders,
      setShowModal,
      clearOrder,
      clearOrderItems,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderList);
