import { Button, Form, Row } from 'antd';
import FormField from './FormField';
import { CloseOutlined } from '@ant-design/icons';

function FromContent({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  loading,
  is_mobile,
  attributes,
  showClear,
  handleReset,
}: any) {
  return (
    <div>
      <Form onReset={handleReset} layout="inline" onFinish={handleSubmit} className="filter-form">
        <Row>
          {attributes.map((attribute: any, index: number) => (
            <FormField
              key={'filter-' + index}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              handleBlur={handleBlur}
              values={values[attribute.name]}
              is_mobile={is_mobile}
              attribute={attribute}
            />
          ))}
          <Form.Item className="reset-container">
            {/* <Button htmlType="submit" type="primary" shape="circle" icon="search" /> */}
            {showClear ? <Button htmlType="reset" type="default" shape="circle" icon={<CloseOutlined />} /> : null}
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default FromContent;
