import { Form, Switch } from 'antd';

function SwitchField({
  errors,
  touched,
  handleBlur,
  handleChange,
  inputProps,
  title,
  placeholder,
  isSubmit,
  name,
  values,
}: any) {
  return (
    <Form.Item
      { ...errors[name]
        && (touched[name] || isSubmit)
        && { help : errors[name], validateStatus: 'error'}}
    >
      <Switch
        {...inputProps}
        checked={values[name]}
        onChange={(value) => {
          handleChange({target: {
            name,
            value,
          }});
        }}
      />
    </Form.Item>
  );
}

export default SwitchField;
