import { Row } from 'antd';
import LoginForm from './components/LoginForm/LoginForm';

function Login() {
  return (
    <Row justify="space-around" align="middle" style={{ height: '100%', width: '100%' }}>
      <LoginForm/>
    </Row>
  );
}

export default Login;
