import { connect } from 'react-redux';
import { Modal } from 'antd';
import ProductDialogModalContent from './ProductDialogModalContent/ProductDialogModalContent';

function ProductDialogModal(props: any) {
  const { open, onCancel, is_mobile } = props;
  return (
    <div>
      <Modal
        mask={true}
        maskClosable={false}
        open={open}
        onCancel={onCancel}
        footer={null}
        width={is_mobile ? 'auto' : '60%'}
      >
        <ProductDialogModalContent {...props} />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  is_mobile: state.auth.is_mobile,
});

export default connect(mapStateToProps)(ProductDialogModal);
