import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Popconfirm, Button } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { AppState } from '../../../../reducers';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import '../../PurchaseOrder.scss';
import {
  getOrders,
  getOrder,
  setOrderProcessed,
  setShowModal,
  setReadingMode,
  deleteOrder,
  duplicateOrder,
} from '../../PurchaseOrderActions';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';
import ActionsColumn from '../../../components/ActionsColumn';
import { formatValue } from '../../../../lib/helper';

const defaultColumns =
  'id,date,counterparty,p_o_type,storage,currency_name,currency_rate,base_total,discount,sub_total,total,processed,note';

function PurchaseOrderTable({
  filter,
  created_order_id,
  data,
  total_count,
  totals,
  dictionaries,
  currency,
  getOrders,
  setOrderProcessed,
  setReadingMode,
  getOrder,
  setShowModal,
  deleteOrder,
  duplicateOrder,
}: any) {
  const [cols, setCols] = useState(() => {
    let columnsLS = localStorage.getItem('p-o-table-col-setting');
    if (!columnsLS) {
      columnsLS = defaultColumns;
      localStorage.setItem('p-o-table-col-setting', columnsLS);
    }
    return columnsLS.split(',');
  });

  const customExpandIcon = (props: any) => {
    return (
      <Button
        type="link"
        icon={props.expanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
        onClick={(e: any) => props.onExpand(props.record, e)}
      />
    );
  };

  const onChange = (pagination: any, filters: any, sorter: any) => {
    const _filter: any = cloneDeep(filter);
    const index = findIndex(filter, ['attribute', 'sort']);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(_filter[indexLimit].value) - _filter[indexLimit].value;
      if (indexSkip > -1) {
        _filter[indexSkip].value = skip;
      } else {
        _filter.push({ attribute: 'skip', value: skip });
      }
    }

    if (!Object.keys(sorter).length) {
      getOrders(_filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      _filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      _filter.push({ attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field });
    }
    getOrders(_filter);
  };

  const rowClassName = (record: any, index: any) => {
    return record.id === created_order_id ? 'create-row' : '';
  };

  const tableData: any[] = data.map((item: any) => {
    if (item.processed) {
      let index = -1;
      delete item.row_color;

      if (findIndex(dictionaries, ['id', parseInt(item.p_o_type, 10)]) > -1) {
        index = findIndex(dictionaries, ['id', parseInt(item.p_o_type, 10)]);
        if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
          item.row_color = dictionaries[index].style.color;
        }
      }
      if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
        index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
        if (currency[index] && currency[index].style && currency[index].style.color) {
          item.row_color = currency[index].style.color;
        }
      }
    } else {
      item.row_color = undefined;
    }
    return item;
  });

  let sortedInfo: any = {};

  let limit = 0;

  filter.forEach((item: any) => {
    if (item.attribute === 'sort') {
      sortedInfo = {
        order: item.value[0] === '-' ? 'descend' : 'ascend',
        columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
      };
    }
    if (item.attribute === 'limit') {
      limit = item.value;
    }
  });

  const view = (record: any) => (e: any) => {
    e.preventDefault();
    setReadingMode(true);
    getOrder(record.id);
    setShowModal(true);
  };

  const edit = (record: any) => (e: any) => {
    e.preventDefault();
    setReadingMode(false);
    getOrder(record.id);
    setShowModal(true);
    // histoty.push('/purchase_order/update/' + record.id);
  };

  const duplicate = (record: any) => () => {
    setReadingMode(false);
    duplicateOrder(record.id);
    setShowModal(true);
  };

  const _delete = (record: any) => () => {
    deleteOrder(record.id, filter);
  };

  const changeStatus = (record: any, status: boolean) => (e: any) => {
    setOrderProcessed({ id: record.id, status }, filter);
  };

  const pagination =
    total_count <= limit
      ? false
      : {
          total: total_count,
          pageSize: limit,
        };

  const allColumns: any[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      render: (item: any, _record: any) => <div>{item}</div>,
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 40, minWidth: 40 } }),
    },
    {
      title: I18n.t('purchase_order.Date'),
      dataIndex: 'date',
      key: 'date',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
      render: (value: any, _record: any) => <div>{formatValue(value, 'date')}</div>,
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 110, minWidth: 110 } }),
      hiddenRender: (value: any) => formatValue(value, 'date'),
    },
    {
      title: I18n.t('general.Counterparty'),
      dataIndex: 'counterparty',
      key: 'counterparty',
      render: (counterparty: any, _record: any) => <div>{counterparty ? counterparty.name : ''}</div>,
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 250, minWidth: 250 } }),
      hiddenRender: (item: any) => (item ? item.name : ''),
    },
    {
      title: I18n.t('purchase_order.Type'),
      dataIndex: 'p_o_type',
      key: 'p_o_type',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'p_o_type' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
        return <div>{index > -1 && value && dictionaries[index].short_title}</div>;
      },
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 150, minWidth: 150 } }),
      hiddenRender: (value: any) => {
        const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
        return index > -1 && value && dictionaries[index].short_title;
      },
    },
    {
      title: I18n.t('purchase_order.Storage'),
      dataIndex: 'storage',
      key: 'storage',
      // ellipsis: true,
      render: (storage: any, _record: any) => <>{storage ? storage.name : ''}</>,
      onCell: (record: any, _index: number) => ({
        style: { background: record.row_color, width: 150, minWidth: 150, whiteSpace: 'nowrap' },
      }),
      hiddenRender: (item: any) => (item ? item.name : ''),
    },
    {
      title: I18n.t('purchase_order.Currency name'),
      dataIndex: 'currency_name',
      sorter: true,
      sortOrder: sortedInfo.columnKey === 'currency_name' && sortedInfo.order,
      render: (value: any, record: any) => {
        const index = findIndex(currency, ['id', parseInt(value, 10)]);
        return <div>{index > -1 && value && currency[index].short_title}</div>;
      },
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 80, minWidth: 80 } }),
      hiddenRender: (value: any) => {
        const index = findIndex(currency, ['id', parseInt(value, 10)]);
        return index > -1 && value && currency[index].short_title;
      },
    },
    {
      title: I18n.t('purchase_order.Currency rate'),
      dataIndex: 'currency_rate',
      key: 'currency_rate',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'currency_rate' && sortedInfo.order,
      render: (text: any, _record: any) => <div>{text.formatNumber(undefined, 4, 4)}</div>,
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 80, minWidth: 80 } }),
    },
    {
      title: I18n.t('purchase_order.Base total'),
      dataIndex: 'base_total',
      key: 'base_total',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'base_total' && sortedInfo.order,
      render: (baseTotal: any, record: any) => (
        <div>{formatValue(baseTotal, 'currency.4')}</div>
      ),
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 120, minWidth: 120 } }),
      hiddenRender: (value: any) => formatValue(value, 'currency.4'),
    },
    {
      title: I18n.t('purchase_order.Discount'),
      dataIndex: 'discount',
      key: 'discount',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'discount' && sortedInfo.order,
      render: (discount: any, record: any) => (
        <div>{!isNaN(parseInt(discount, 10)) ? parseFloat(discount).toFixed(2) + ' %' : discount + ' %'}</div>
      ),
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 80, minWidth: 80 } }),
      hiddenRender: (value: any) => (!isNaN(parseInt(value, 10)) ? parseFloat(value).toFixed(2) + ' %' : value + ' %'),
    },
    {
      title: I18n.t('purchase_order.Sub total'),
      dataIndex: 'sub_total',
      key: 'sub_total',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'sub_total' && sortedInfo.order,
      render: (sub_total: any, record: any) => (
        <div>
          {!isNaN(parseFloat(sub_total))
            ? (sub_total - (sub_total / 100) * record.discount).formatNumber()
            : (sub_total && 'error') || ''}
        </div>
      ),
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 120, minWidth: 120 } }),
      hiddenRender: (sub_total: any, record: any) =>
        !isNaN(parseFloat(sub_total))
          ? (sub_total - (sub_total / 100) * record.discount).formatNumber()
          : (sub_total && 'error') || '',
    },
    {
      title: I18n.t('purchase_order.Total Price'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      align: 'right',
      sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
      render: (total: any, record: any) => (
        <div>
          {!isNaN(parseFloat(total))
            ? (total - (total / 100) * record.discount).formatNumber()
            : (total && 'error') || ''}
        </div>
      ),
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 120, minWidth: 120 } }),
      hiddenRender: (total: any, record: any) =>
        !isNaN(parseFloat(total)) ? (total - (total / 100) * record.discount).formatNumber() : (total && 'error') || '',
    },
    {
      title: I18n.t('purchase_order.Note'),
      dataIndex: 'note',
      key: 'note',
      render: (note: any, record: any) => note,
      onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 200, minWidth: 200 } }),
      hiddenRender: (note: any, record: any) => note,
    },
  ];

  const columns = allColumns.filter(column => cols.includes(column.dataIndex));
  columns.unshift({
    title: I18n.t('order_item_editable.Operation'),
    dataIndex: 'operation',
    render: (text: any, record: any) => (
      <ActionsColumn
        record={record}
        changeStatus={changeStatus}
        edit={edit}
        view={view}
        deleteRecord={_delete}
        duplicate={duplicate}
      />
    ),
    onCell: (record: any, _index: number) => ({ style: { background: record.row_color, width: 120, minWidth: 120 } }),
  });
  const hiddenCols = allColumns
    .filter(column => !cols.includes(column.dataIndex))
    .map(({ title, dataIndex, hiddenRender }) => ({ title, dataIndex, render: hiddenRender }));

  return (
    <div style={{ overflow: 'auto', width: '100%' }} className="small-table no-editable PurchaseOrderTable">
      <TableColSettings
        options={[
          { label: I18n.t('purchase_order.Id'), value: 'id' },
          { label: I18n.t('purchase_order.Date'), value: 'date' },
          { label: I18n.t('general.Counterparty'), value: 'counterparty' },
          { label: I18n.t('purchase_order.Type'), value: 'p_o_type' },
          { label: I18n.t('purchase_order.Storage'), value: 'storage' },
          { label: I18n.t('purchase_order.Currency name'), value: 'currency_name' },
          { label: I18n.t('purchase_order.Currency rate'), value: 'currency_rate' },
          { label: I18n.t('purchase_order.Base total'), value: 'base_total' },
          { label: I18n.t('purchase_order.Discount'), value: 'discount' },
          { label: I18n.t('purchase_order.Sub total'), value: 'sub_total' },
          { label: I18n.t('purchase_order.Total Price'), value: 'total' },
          { label: I18n.t('purchase_order.Note'), value: 'note' },
        ]}
        data={cols.join(',')}
        onSave={(values: any) => {
          setCols(values);
          localStorage.setItem('p-o-table-col-setting', values.join(','));
        }}
      />
      <Table
        columns={columns}
        rowKey={(record: any) => record.id}
        onChange={onChange}
        dataSource={tableData}
        bordered={true}
        rowClassName={rowClassName}
        tableLayout="fixed" //- | auto | fixed
        scroll={{ x: true }}
        footer={() => (
          <div>
            {/* <p>Subtotal: {totals.total || '0.00'}</p> */}
            <p>
              {I18n.t('purchase_order.Summary Total')}: <strong>{(totals.total || 0).formatNumber()}</strong>
            </p>
          </div>
        )}
        pagination={pagination}
        expandIcon={(props: any) => customExpandIcon(props)}
        expandedRowRender={(record: any) => (
          <>
            {false && <div className="hidden-actions">
              {record.processed ? (
                <>
                  <Button type="link" onClick={changeStatus(record, false)}>
                    {I18n.t('purchase_order.Un process')}
                  </Button>
                  <Button type="link" href={'/purchase_order/view/' + record.id} onClick={view(record)}>
                    {I18n.t('purchase_order.View')}
                  </Button>
                </>
              ) : (
                <>
                  <Button type="link" onClick={changeStatus(record, true)}>
                    {I18n.t('purchase_order.Process')}
                  </Button>
                  <Button type="link" href={'/purchase_order/update/' + record.id} onClick={edit(record)}>
                    {I18n.t('purchase_order.Update')}
                  </Button>
                  <Popconfirm
                    title={I18n.t('purchase_order.Sure to delete?')}
                    onConfirm={_delete(record)}
                    cancelText={I18n.t('purchase_order.Cancel')}
                    okText={I18n.t('purchase_order.Ok')}
                  >
                    <Button type="link">{I18n.t('purchase_order.Delete')}</Button>
                  </Popconfirm>
                </>
              )}
              <Button type="link" onClick={duplicate(record)}>
                {I18n.t('general.Duplicate')}
              </Button>
            </div>}
            {hiddenCols.map((item: any) => (
              <div key={item.dataIndex}>
                {item.title} : {item.render ? item.render(record[item.dataIndex], record) : record[item.dataIndex]}
              </div>
            ))}
          </>
        )}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.purchase_order.list_data,
  filter: state.purchase_order.filter,
  totals: state.purchase_order.totals,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'purchase_order'),
  total_count: state.purchase_order.total_count,
  created_order_id: state.purchase_order.created_order_id,
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrders,
      getOrder,
      setOrderProcessed,
      setShowModal,
      setReadingMode,
      deleteOrder,
      duplicateOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderTable);
