import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { clearData } from './PurchaseOrderActions';
import { AppState } from '../../reducers';
import PurchaseOrderCreateContent from './components/PurchaseOrderCreateContent/PurchaseOrderCreateContent';
import { useEffect } from 'react';

function PurchaseOrderCreate({ clearData }: any) {
  useEffect(() => {
    clearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PurchaseOrderCreateContent />;
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      clearData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderCreate);
