import {
  GET_DICTIONARIES,
  CREATE_DICTIONARY,
  UPDATE_DICTIONARY,
  DELETE_DICTIONARY,
} from '../../types/DictionaryTypes';

export const getDictionaries = (filter: any = []) => ({
  type: GET_DICTIONARIES,
  filter,
});

export const createDictionary = (data: any) => ({
  type: CREATE_DICTIONARY,
  data,
});

export const updateDictionary = (id: number, data: any) => ({
  type: UPDATE_DICTIONARY,
  data,
  id,
});

export const deleteDictionary = (id: any) => ({
  type: DELETE_DICTIONARY,
  id,
});
