import * as React from 'react';
import { Layout, Menu } from 'antd';
import { LaptopOutlined, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import { AppState } from '../../../../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { logout } from '../../../LogoutActions';

const { Sider } = Layout;

class SideBar extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      defaultOpen: '',
    };
  }

  componentDidMount() {
    //
  }
  selectMenuItem = () => {
    const res = window.location.pathname.split('/');
    if (res[1]) {
      if (res[1] !== 'report') {
        return res[1];
      } else {
        return res[1] + 's_' + res[2];
      }
    }
    return '';
  }

  componentWillMount() {
    const res = window.location.pathname.split('/');
    switch (res[1]) {
      case '':
        this.setState({defaultOpen: ''});
        return;
      case 'dictionary':
      case 'account':
      case 'counterparty':
      case 'category':
      case 'product':
      case 'storage':
      case 'default_value':
        this.setState({defaultOpen: 'directories'});
        return;

      case 'bill':
      case 'payment':
      case 'transfer':
        this.setState({defaultOpen: 'money'});
        return;

      case 'purchase_order':
      case 'sales_order':
        this.setState({defaultOpen: 'orders'});
        return;

      case 'report':
        this.setState({defaultOpen: 'reports'});
        return;
      default: return '';
    }
  }

  public render() {
    const {isMobile, collapsed/*, hideMenu */} = this.props;
    const selectedKey: string = this.selectMenuItem();

    return (
      <Sider
        style={{
          background: '#fff',
          minHeight: '100vh',
          overflow: 'auto',
          left: 0,
          // position: 'absolute',
        }}
        trigger={null}
        collapsible
        collapsedWidth = {isMobile ? 0 : 80}
        collapsed={collapsed}
      >
        <Menu
          mode="inline"
          defaultOpenKeys = {[this.state.defaultOpen]}
          selectedKeys = {[selectedKey]}
          style={{ height: '100%', borderRight: 0 , overflowX: 'hidden' }}
          items={[
            {
              label: <span>{I18n.t('layout.Directories')}</span>,
              key: 'directories',
              icon: <LaptopOutlined />,
              children: [
                {
                  label: <Link to="/dictionary/list">{I18n.t('layout.Dictionaries')}</Link>,
                  key: 'dictionary',
                },
                {
                  label: <Link to="/account/list">{I18n.t('layout.Accounts')}</Link>,
                  key: 'account',
                },
                {
                  label: <Link to="/counterparty/list">{I18n.t('layout.Counterparties')}</Link>,
                  key: 'counterparty',
                },
                {
                  label: <Link to="/category/list">{I18n.t('layout.Categories')}</Link>,
                  key: 'category',
                },
                {
                  label: <Link to="/product/list">{I18n.t('layout.Catalog')}</Link>,
                  key: 'product',
                },
                {
                  label: <Link to="/storage/list">{I18n.t('layout.Storages')}</Link>,
                  key: 'storage',
                },
                {
                  label: <Link to="/default_value/list">{I18n.t('layout.Default value')}</Link>,
                  key: 'default_value',
                },
              ],
            },
            {
              label: <span>{I18n.t('layout.Money')}</span>,
              key: 'money',
              icon: <LaptopOutlined />,
              children: [
                {
                  label: <Link to="/bill/list">{I18n.t('layout.Bill')}</Link>,
                  key: 'bill',
                },
                {
                  label: <Link to="/payment/list">{I18n.t('layout.Payment')}</Link>,
                  key: 'payment',
                },
                {
                  label: <Link to="/transfer/list">{I18n.t('layout.Transfer')}</Link>,
                  key: 'transfer',
                },
              ],
            },
            {
              label: <span>{I18n.t('layout.Orders')}</span>,
              key: 'orders',
              icon: <LaptopOutlined />,
              children: [
                {
                  label: <Link to="/purchase_order/list">{I18n.t('layout.Purchase Order')}</Link>,
                  key: 'purchase_order',
                },
                {
                  label: <Link to="/sales_order/list">{I18n.t('layout.Sales Order')}</Link>,
                  key: 'sales_order',
                },
                {
                  label: <Link to="/transfer_order/list">{I18n.t('layout.Transfer Order')}</Link>,
                  key: 'tratransfer_ordernsfer',
                },
              ],
            },
            {
              label: <span>{I18n.t('layout.Reports')}</span>,
              key: 'reports',
              icon: <LaptopOutlined />,
              children: [
                {
                  label: <Link to="/report/account">{I18n.t('layout.Reports account')}</Link>,
                  key: 'reports_account',
                },
                {
                  label: <Link to="/report/counterparty">{I18n.t('layout.Reports counterparty')}</Link>,
                  key: 'reports_counterparty',
                },
                {
                  label: <Link to="/report/storage">{I18n.t('layout.Reports storage')}</Link>,
                  key: 'reports_storage',
                },
              ],
            },
            {
              label: I18n.t('layout.Logout'),
              key: 'logout',
              icon: <LogoutOutlined />,
              onClick: () => {
                this.props.logout();
              },
            }
          ]}
        >
        </Menu>
      </Sider>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  logined: state.auth.logined,
  loading: state.auth.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps) (SideBar);
