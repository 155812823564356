import { CREATE_STARTED_DATA_ITEM, UPDATE_STARTED_DATA_ITEM, DELETE_STARTED_DATA_ITEM } from './../types/StartedDataTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import {
  CATEGORIES_RECEIVED,
  DELETE_CATEGORY_RECEIVED,
  UPDATE_CATEGORY_RECEIVED,
  GET_CATEGORIES,
  DELETE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  CREATE_CATEGORY_RECEIVED,
  FILTERED_CATEGORIES_RECEIVED,
} from '../types/CategoryTypes';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';

function* getCategories() {
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('categories', '',  {limit: 10000, include_count: true});
    yield put({ type: CATEGORIES_RECEIVED, data, count });
    // tslint:disable-next-line: triple-equals
    yield put({ type: FILTERED_CATEGORIES_RECEIVED, data: data.filter((item: any) => item.parent_id === 0), count });
  } catch (error) {
    yield put({ type: CATEGORIES_RECEIVED, data: [] , count : 0});
    yield put({ type: ERROR, messages: ['Not categories']});
  }
}

function* createCategory({ data }: any) {
  try {
    const result: GenericEntityResponse = yield restClient.post('categories', data);
    yield put({ type: CREATE_CATEGORY_RECEIVED, item: result });
    yield put({ type: CREATE_STARTED_DATA_ITEM, name: 'categories', item: result });

  } catch (error) {
    yield put({ type: ERROR, messages: ['Not category create']});
  }
}

function* deleteCategory({id}: any) {
  try {
    yield restClient.delete('categories', id);
    yield put({ type: DELETE_CATEGORY_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'categories', id });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not delete category']});
  }
}

function* updateCategory({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('categories', id, data);
    yield put({ type: UPDATE_CATEGORY_RECEIVED, item: result, id});
    yield put({ type: UPDATE_STARTED_DATA_ITEM, name: 'categories', item: result, id });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order update']});
  }
}

export default function* actionWatcherCategories() {
  yield takeLatest(GET_CATEGORIES, getCategories);
  yield takeLatest(CREATE_CATEGORY, createCategory);
  yield takeLatest(DELETE_CATEGORY, deleteCategory);
  yield takeLatest(UPDATE_CATEGORY, updateCategory);
}
