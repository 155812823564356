import { UPDATE_STARTED_DATA_ITEM, DELETE_STARTED_DATA_ITEM } from './../types/StartedDataTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_DICTIONARIES,
  DICTIONARY_TYPES_RECEIVED,
  ADD_DICTIONARY_TYPES,
  DICTIONARIES_RECEIVED,
  CREATE_DICTIONARY_RECEIVED,
  UPDATE_DICTIONARY_RECEIVED,
  DELETE_DICTIONARY_RECEIVED,
  CREATE_DICTIONARY,
  UPDATE_DICTIONARY,
  DELETE_DICTIONARY,
} from '../types/DictionaryTypes';
import { CREATE_STARTED_DATA_ITEM } from '../types/StartedDataTypes';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_DICTIONARIES_FILTERS } from '../types/Constants';

function* getDictionaries({filter}: any) {
  setValueByKey(STORAGE_DICTIONARIES_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('dictionaries', '', generateFilter(filter));
    const types: GenericEntityResponse = yield restClient.get('dictionaries/types', '', '');
    yield put({ type: DICTIONARIES_RECEIVED, data, count, filter });
    yield put({ type: DICTIONARY_TYPES_RECEIVED, data: types });
  } catch (error) {
    yield put({ type: DICTIONARIES_RECEIVED, data: [], count: 0, filter: [] });
    yield put({ type: ERROR, messages: ['Not dictionaries']});
  }
}

function* createDictionary({data}: any) {
  data.short_title = data.short_title ? data.short_title : data.title;
  try {
    const result: GenericEntityResponse = yield restClient.post('dictionaries', data);
    yield put({ type: CREATE_DICTIONARY_RECEIVED, item: result });
    yield put({ type: CREATE_STARTED_DATA_ITEM, name: 'dictionaries', item: result });
    yield put({ type: ADD_DICTIONARY_TYPES, data: result.d_type });
  } catch (error) {
    yield put({ type: CREATE_DICTIONARY_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not dictionary create']});
  }
}

function* updateDictionary({data, id}: any) {
  data.short_title = data.short_title ? data.short_title : data.title;
  try {
    const result: GenericEntityResponse = yield restClient.update('dictionaries', id, data);
    yield put({ type: UPDATE_DICTIONARY_RECEIVED, item: result });
    const {slug, d_type, title, short_title, style} = result;
    yield put({ type: UPDATE_STARTED_DATA_ITEM, name: 'dictionaries',
      item: {id: result.id, slug, d_type, title, short_title, style}, id });
    yield put({ type: ADD_DICTIONARY_TYPES, data: result.d_type });
  } catch (error) {
    yield put({ type: UPDATE_DICTIONARY_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not dictionary update']});
  }
}
function* deleteDictionary({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('dictionaries', id);
    yield put({ type: DELETE_DICTIONARY_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'dictionaries', id });
    yield put({ type: ADD_DICTIONARY_TYPES, data: result.d_type });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not dictionary delete']});
  }
}

export default function* actionWatcherDictionary() {
  yield takeLatest(GET_DICTIONARIES, getDictionaries);
  yield takeLatest(CREATE_DICTIONARY, createDictionary);
  yield takeLatest(UPDATE_DICTIONARY, updateDictionary);
  yield takeLatest(DELETE_DICTIONARY, deleteDictionary);
}
