import { GET_STARTED_DATA } from './../../types/StartedDataTypes';
import { isMobileDevice } from '../../lib/helper';

export const getStartedData = () => ({
    type: GET_STARTED_DATA,
});

export const isMobile = () => ({
    type: 'SET_MOBILE',
    value: isMobileDevice(),
});
