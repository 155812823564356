import * as React from 'react';
import { Button, Table } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../../reducers';
import { getDialogProducts } from '../../ProductDialogActions';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';

class ProductDialogTable extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount = () => {
    this.props.getDialogProducts([...this.props.filter, { attribute: 'published', value: 'true'} ] );
  }

  onChange = (pagination: any, filters: any, sorter: any) => {
    const filter: any = cloneDeep(this.props.filter);
    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      (indexSkip > -1) ? filter[indexSkip].value = skip : filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      filter[index].value = '-createdAt';
      this.props.getDialogProducts(filter);
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getDialogProducts(filter);
  }

  public render() {
    const {products, filter, total_count, onDoubleClick, categoryNotSelected, categories } = this.props;

    let sortedInfo: any = {};
    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'sort') {
        sortedInfo = {
          order: item.value[0] === '-' ? 'descend' : 'ascend',
          columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
        };
      }
      if (item.attribute === 'limit') { limit = item.value; }
    });

    let pagination = {};
    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count,
        pageSize: limit,
      };
    }

    const columns: any = [
      {
        title: I18n.t('product_dialog.Operations'),
        dataIndex: 'operations',
        key: 'operations',
        width : '10%',
        render: (_temp: any, record: any) => <Button onClick={() => onDoubleClick(record)}>{I18n.t('product_dialog.Choose')}</Button>
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width : '5%',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
      },
      {
        title: I18n.t('product_dialog.Title'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      },
      {
        title: I18n.t('product_dialog.Price'),
        dataIndex: 'price',
        key: 'price',
        width: '5%',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
      },
    ];

    if (categoryNotSelected) {
      columns.push({
        title: I18n.t('product.Category'),
        dataIndex: 'category_id',
        key: 'category_id',
        width: '15%',
        render: (cat: number) => categories.map((item: any) => item.id === cat ? item.name : ''),
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'category_id' && sortedInfo.order,
      });
    }

    return (
      <div style = {{paddingLeft: 5}}>
        <Table
          bordered
          rowKey = { (record: any) => record.id}
          columns = { columns }
          dataSource = { products }
          onChange={ this.onChange }
          // onRow = { (record, rowIndex) => {
          //   return {
          //     onDoubleClick: (e: any) => onDoubleClick(record),
          //   };
          // } }
          pagination = {pagination}
          expandedRowRender = { (record: any) =>
            <div style={{ margin: 0 }} >
              <p> {I18n.t('product_dialog.Date')} : { moment( record.createdAt ).format('DD-MM-YYYY HH:mm') } </p>
              <p> {I18n.t('product_dialog.Description')} : { record.description} </p>
              <p> {I18n.t('product_dialog.Barcode')} : { record.barcode} </p>
              <p> {I18n.t('product_dialog.Published')} : {
                  record.published ? I18n.t('product_dialog.Yes') : I18n.t('product_dialog.No')
                }
              </p>
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  products : state.dialog_product.list_data,
  categories : state.started_data.categories,
  filter: state.dialog_product.filter,
  total_count: state.dialog_product.total_count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getDialogProducts,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialogTable);
