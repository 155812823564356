import { Switch, Route } from 'react-router-dom';
import { Router as BrowserRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import history from '../lib/histoty';
import DefaultRouter from './default';

function Router({ logined }: any) {
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/" component={DefaultRouter} />
      </Switch>
    </BrowserRouter>
  );
}

const mapStateToProps = (state: any) => ({
  logined: state.auth.logined,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Router);
