import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  getOrder,
  clearData,
} from './SalesOrderActions';
import { AppState } from '../../reducers';
import SelectField from '../components/Fields/SelectField';
import ProductDialog from '../components/ProductDialog/ProductDialog';
import SalesOrderForm from './components/SalesOrderForm/SalesOrderForm';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import { Row } from 'antd';
import { I18n } from 'react-redux-i18n';

class SalesOrderCreate extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
  }

  create = (data: any) => {
    if (this.props.order && this.props.order.id) {
      this.props.updateOrder(this.props.order.id, data);
    } else {
      this.props.createOrder(data);
    }
  }

  updateItem = (data: any) => {
    this.props.updateOrderItem(data.id, data);
  }

  deleteItem = (data: any) => {
    this.props.deleteOrderItem(data);
  }

  createItem = (data: any) => {
    const {order} = this.props;
    this.props.createOrderItem({...data, s_o_id: order.id});
  }

  renderInput = (props: any) => {
    switch (props.inputType) {
      case 'select':
        let options: any = [];
        if (props.name === 'product_id') {
          options = this.props.products.filter((item: any) => item.published)
            .map((item: any) => ({title: item.title, value: item.id, key: item.id}));
        }
        return ( <div style= {{ flexDirection : 'row', display : 'flex', alignItems : 'center' }}>
          <div style = {{flex : '1'}}>
            <SelectField
              {...props}
              options={options}
            />
          </div>
          <ProductDialog
            onDoubleClick={(id: any) => {
              props.handleChange({target: { name : 'product_id', value : id }}); }}
          />
        </div>);
      default:
        return null;
    }
  }

  onTableCell = (col: any, record: any) => {
    switch (col.dataIndex) {
      case 'product_id':
        return {
          inputType: 'select',
          renderInput: this.renderInput,
        };
      default:
        return {
          inputType: 'text',
        };
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  public render() {
    const {order, items} = this.props;

    const validateRules = [
      {name: 'product_id', rules: ['required']},
      {name: 'price', rules: ['required', 'numeric']},
      {name: 'quantity', rules: ['required', 'numeric']},
    ];

    const columns = [
      {
        title: I18n.t('sales_order.ProductTitle'),
        dataIndex: 'product_id',
        editable: true,
        render: (text: any, record: any) => {
          if (record.product) {
            return record.product.title;
          }
          return record.id;
        },
      },
      {
        title: I18n.t('sales_order.Price'),
        dataIndex: 'price',
        editable: true,
      },
      {
        title: I18n.t('sales_order.Quantity'),
        dataIndex: 'quantity',
        editable: true,
      },
    ];

    return (
      <Row>
        <SalesOrderForm onChange={this.create} order={order}/>
        <OrderItemsEditable
          btnCreateDisabled={!order.id}
          items={items}
          create={this.createItem}
          update={this.updateItem}
          delete={this.deleteItem}
          validateRules={validateRules}
          columns={columns}
          onTableCell={this.onTableCell}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  order: state.sales_order.order,
  items: state.sales_order.items,
  products: state.started_data.products,
  categories: state.started_data.categories,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder,
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderCreate);
