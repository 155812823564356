export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const ACCOUNTS_RECEIVED = 'ACCOUNTS_RECEIVED';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';

export const CREATE_ACCOUNT_RECEIVED = 'CREATE_ACCOUNT_RECEIVED';
export const UPDATE_ACCOUNT_RECEIVED = 'UPDATE_ACCOUNT_RECEIVED';
export const DELETE_ACCOUNT_RECEIVED = 'DELETE_ACCOUNT_RECEIVED';
