import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Table, Popconfirm } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { AppState } from '../../../../reducers';
import findIndex from 'lodash.findindex';
import cloneDeep from 'lodash.clonedeep';
import {
  getOrders,
  getOrder,
  setOrderProcessed,
  setShowModal,
  setReadingMode,
  deleteOrder,
} from '../../SalesOrderActions';
import { I18n } from 'react-redux-i18n';
import TableColSettings from '../../../components/TableColSettings';
import { formatValue } from '../../../../lib/helper';

class SalesOrderTable extends React.Component<any, any> {

  componentDidMount() {
    if (!localStorage.getItem('s-o-table-col-setting')) {
      localStorage.setItem('s-o-table-col-setting',
        `id,date,counterparty,s_o_type,storage,currency_name,
        currency_rate,base_total,discount,sub_total,total,processed`,
      );
    }
  }

  customExpandIcon(props: any) {
    if (props.expanded) {
        return <a onClick={(e: any) => {
            props.onExpand(props.record, e);
        }}><CaretUpOutlined type="caret-up" /></a>;
    } else {
        return <a  onClick={(e: any) => {
            props.onExpand(props.record, e);
        }}><CaretDownOutlined type="caret-down" /></a>;
    }
  }

  onChange = (pagination: any, filters: any, sorter: any) => {
    const filter: any = cloneDeep(this.props.filter);
    const index = findIndex(this.props.filter, ['attribute', 'sort']);
    const indexLimit = findIndex(this.props.filter, ['attribute', 'limit']);
    const indexSkip = findIndex(this.props.filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(filter[indexLimit].value) - filter[indexLimit].value;
      if (indexSkip > -1) {
        filter[indexSkip].value = skip;
      } else {
        filter.push({attribute: 'skip', value: skip});
      }
    }

    if (!Object.keys(sorter).length) {
      this.props.getOrders(filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    this.props.getOrders(filter);
  }

  rowClassName = (record: any, index: any) => {
    return record.id === this.props.created_order_id ? 'create-row' : '';
  }

  public render() {
    const {data, filter, total_count, totals, dictionaries, currency} = this.props;

    const tableData: any [] = data.map((item: any) => {
      if (item.processed) {
        let index = -1;
        delete item.row_color;

        if ( findIndex(dictionaries, ['id', parseInt(item.s_o_type, 10)]) > -1) {
          index = findIndex(dictionaries, ['id', parseInt(item.s_o_type, 10)]);
          if (dictionaries[index] && dictionaries[index].style && dictionaries[index].style.color) {
            item.row_color = dictionaries[index].style.color;
          }
        }
        if (!item.row_color && findIndex(currency, ['id', parseInt(item.currency_name, 10)]) > -1) {
          index = findIndex(currency, ['id', parseInt(item.currency_name, 10)]);
          if (currency[index] && currency[index].style && currency[index].style.color) {
            item.row_color = currency[index].style.color;
          }
        }
      } else {
        item.row_color = undefined;
      }
      return item;
    });

    let sortedInfo: any = {};

    let limit = 0;

    filter.forEach((item: any) => {
      if (item.attribute === 'sort') {
        sortedInfo = {
          order: item.value[0] === '-' ? 'descend' : 'ascend',
          columnKey: item.value[0] === '-' ? item.value.slice(1) : item.value,
        };
      }
      if (item.attribute === 'limit') {
        limit = item.value;
      }
    });

    let pagination = {};
    if ( total_count <= limit ) {
      pagination = false;
    } else {
      pagination = {
        total : total_count,
        pageSize: limit,
      };
    }

    const cols: any = (localStorage.getItem('s-o-table-col-setting') || '').split(',');
    const columns: any[] = [];
    const hiddenCols: any = [];

    if (cols.indexOf('id') > -1) {
      columns.push({
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'id' && sortedInfo.order,
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{item}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: 'ID', dataIndex: 'id'});
    }

    if (cols.indexOf('date') > -1) {
      columns.push({
        title: I18n.t('sales_order.Date'),
        dataIndex: 'date',
        key: 'date',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'date' && sortedInfo.order,
        render: (value: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{formatValue(value, 'date')}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Date'), dataIndex: 'date',
      render: (value: any) => formatValue(value, 'date') });
    }

    if (cols.indexOf('counterparty') > -1) {
      columns.push({
        title: I18n.t('sales_order.Counterparty'),
        dataIndex: 'counterparty',
        key: 'counterparty',
        render: (counterparty: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{counterparty ? counterparty.name : ''}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Counterparty'), dataIndex: 'counterparty',
      render: (item: any) => item ? item.name : ''});
    }

    if (cols.indexOf('s_o_type') > -1) {
      columns.push({
        title: I18n.t('sales_order.Type'),
        dataIndex: 's_o_type',
        key: 's_o_type',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 's_o_type' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(dictionaries, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div> {(index > -1) && value && dictionaries[index].short_title} </div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Type'), dataIndex: 's_o_type',
      render: (item: any) => {
        const index = findIndex(dictionaries, ['id', parseInt(item, 10)]);
        return (index > -1) && item && dictionaries[index].short_title;
      }});
    }

    if (cols.indexOf('storage') > -1) {
      columns.push({
        title: I18n.t('sales_order.Storage'),
        dataIndex: 'storage',
        key: 'storage',
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{item ? item.name : ''}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Storage'), dataIndex: 'storage',
      render: (item: any) => item ? item.name : '' });
    }

    if (cols.indexOf('currency_name') > -1) {
      columns.push({
        title: I18n.t('sales_order.Currency name'),
        dataIndex: 'currency_name',
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'currency_name' && sortedInfo.order,
        render: (value: any, record: any) => {
          const index = findIndex(currency, ['id', parseInt(value, 10)]);
          return {
            props: { style: { background: record.row_color} },
            children: <div>{(index > -1) && value && currency[index].short_title}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Currency name'), dataIndex: 'currency_name',
      render: (item: any) => {
        const index = findIndex(currency, ['id', parseInt(item, 10)]);
        return (index > -1) && item && currency[index].short_title;
      }});
    }

    if (cols.indexOf('currency_rate') > -1) {
      columns.push({
        title: I18n.t('sales_order.Currency rate'),
        dataIndex: 'currency_rate',
        key: 'currency_rate',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'currency_rate' && sortedInfo.order,
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{item ? item : ''}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Currency rate'), dataIndex: 'currency_rate' });
    }

    if (cols.indexOf('base_total') > -1) {
      columns.push({
        title: I18n.t('sales_order.Base total'),
        dataIndex: 'base_total',
        key: 'base_total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'base_total' && sortedInfo.order,
        render: (value: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{formatValue(value, 'currency.4')}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Base total'), dataIndex: 'base_total',
      render: (value: any) => formatValue(value, 'currency.4') });
    }

    if (cols.indexOf('discount') > -1) {
      columns.push({
        title: I18n.t('sales_order.Discount'),
        dataIndex: 'discount',
        key: 'discount',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'discount' && sortedInfo.order,
        render: (item: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(item)) ? (parseFloat(item).toFixed(2) + '%') : (item + '%')}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Discount'), dataIndex: 'discount',
      render: (item: any) => !isNaN(parseFloat(item)) ? (parseFloat(item).toFixed(2) + '%') : (item + '%')});
    }

    if (cols.indexOf('sub_total') > -1) {
      columns.push({
        title: I18n.t('sales_order.Sub total'),
        dataIndex: 'sub_total',
        key: 'sub_total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'sub_total' && sortedInfo.order,
        render: (total: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(total)) ?
              parseFloat((total - total / 100 * record.discount).toString()).formatNumber(undefined, 2, 2)
              : (total - total / 100 * record.discount)}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Sub total'), dataIndex: 'sub_total',
      render: (total: any, record: any) => !isNaN(parseFloat(total)) ?
        parseFloat((total - total / 100 * record.discount).toString()).formatNumber(undefined, 2, 2)
        : (total - total / 100 * record.discount)});
    }

    if (cols.indexOf('total') > -1) {
      columns.push({
        title: I18n.t('sales_order.Total Price'),
        dataIndex: 'total',
        key: 'total',
        sorter: true,
        align: 'right',
        sortOrder: sortedInfo.columnKey === 'total' && sortedInfo.order,
        render: (total: any, record: any) => {
          return {
            props: { style: { background: record.row_color} },
            children: <div>{!isNaN(parseFloat(total)) ?
              parseFloat((total - total / 100 * record.discount).toString()).formatNumber(undefined, 2, 2)
              : (total - total / 100 * record.discount)}</div>,
          };
        },
      });
    } else {
      hiddenCols.push({ title: I18n.t('sales_order.Total Price'), dataIndex: 'total',
      render: (total: any, record: any) => !isNaN(parseFloat(total)) ?
        parseFloat((total - total / 100 * record.discount).toString()).formatNumber(undefined, 2, 2)
        : (total - total / 100 * record.discount)});
    }

    return (
      <div style = {{overflow: 'auto', width: '100%'}} className = "small-table no-editable">
         <TableColSettings
          options = {[
            { label: I18n.t('sales_order.Id'), value: 'id' },
            { label: I18n.t('sales_order.Date'), value: 'date' },
            { label: I18n.t('sales_order.Counterparty'), value: 'counterparty' },
            { label: I18n.t('sales_order.Type'), value: 's_o_type' },
            { label: I18n.t('sales_order.Storage'), value: 'storage' },
            { label: I18n.t('sales_order.Currency name'), value: 'currency_name' },
            { label: I18n.t('sales_order.Currency rate'), value: 'currency_rate' },
            { label: I18n.t('sales_order.Base total'), value: 'base_total' },
            { label: I18n.t('sales_order.Discount'), value: 'discount' },
            { label: I18n.t('sales_order.Sub total'), value: 'sub_total' },
            { label: I18n.t('sales_order.Total Price'), value: 'total' },
          ]}
          data = {localStorage.getItem('s-o-table-col-setting')}
          onSave = {(values: any) => {
            this.setState({values});
            localStorage.setItem('s-o-table-col-setting', values);
          }}
        />
        <Table
          bordered
          columns={columns}
          rowKey={(record: any) => record.id}
          onChange={this.onChange}
          dataSource={tableData}
          rowClassName = {this.rowClassName}
          footer={() => {
            return <div>
              {/* <p>Subtotal: {totals.total || '0.00'}</p> */}
              <p>{I18n.t('sales_order.Summary Total')}: <strong>{totals.total || '0.00'}</strong></p>
            </div>;
          }}
          pagination={pagination}
          expandIcon={(props: any) => this.customExpandIcon(props)}
          expandedRowRender= {(record: any) => 
          <React.Fragment>
            <div className="hidden-actions">
              {
                record.processed ?
                <React.Fragment>
                  <a onClick = {() =>
                    this.props.setOrderProcessed({id: record.id, status: false}, this.props.filter)}
                  >{I18n.t('sales_order.Un process')}</a>
                  <a href={'/sales_order/view/' + record.id } onClick={(e) => {
                    e.preventDefault();
                    this.props.setReadingMode(true);
                    this.props.getOrder(record.id, []);
                    this.props.setShowModal(true);
                  }}>{I18n.t('sales_order.View')}</a>
                </React.Fragment>
              :
                <React.Fragment>
                  <a onClick = {() =>
                    this.props.setOrderProcessed({id: record.id, status: true}, this.props.filter)}
                  >{I18n.t('sales_order.Process')}</a>
                  <a href={'/sales_order/update/' + record.id} onClick={(e) => {
                  e.preventDefault();
                  this.props.setReadingMode(false);
                  this.props.getOrder(record.id, []);
                  this.props.setShowModal(true);
                }}>{I18n.t('sales_order.Update')}</a>
                <Popconfirm
                  title= {I18n.t('sales_order.Sure to delete?')}
                  cancelText = {I18n.t('sales_order.Cancel')}
                  okText = {I18n.t('sales_order.Ok')}
                  onConfirm={() => this.props.deleteOrder(record.id, this.props.filter)}
                >
                  <a>{I18n.t('sales_order.Delete')}</a>
                </Popconfirm>
              </React.Fragment>
              }
            </div>
            {hiddenCols.map((item: any) => <div key={item.dataIndex}>
              {item.title} : {item.render ? item.render(record[item.dataIndex], record) : record[item.dataIndex]}
            </div>)}
          </React.Fragment>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  data: state.sales_order.list_data,
  filter: state.sales_order.filter,
  dictionaries: state.started_data.dictionaries.filter((item: any) => item.d_type === 'sales_order'),
  total_count: state.sales_order.total_count,
  totals: state.sales_order.totals,
  currency: state.started_data.dictionaries.filter((item: any) => item.d_type === 'currency'),
  created_order_id: state.sales_order.created_order_id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrders,
      getOrder,
      setOrderProcessed,
      setShowModal,
      setReadingMode,
      deleteOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderTable);
