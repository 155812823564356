import {
  GET_COUNTERPARTIES,
  CREATE_COUNTERPARTY,
  UPDATE_COUNTERPARTY,
  DELETE_COUNTERPARTY,
} from '../../types/CounterpartyTypes';

export const getCounterparties = (filter: any = []) => ({
  type: GET_COUNTERPARTIES,
  filter,
});

export const createCounterparty = (data: any) => ({
  type: CREATE_COUNTERPARTY,
  data,
});

export const updateCounterparty = (id: number, data: any) => ({
  type: UPDATE_COUNTERPARTY,
  data,
  id,
});

export const deleteCounterparty = (id: any) => ({
  type: DELETE_COUNTERPARTY,
  id,
});

