import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrder } from './SalesOrderActions';
import { Row, Col, Table } from 'antd';
import { AppState } from '../../reducers';
import SalesOrderCreateContent from './components/SalesOrderCreateContent/SalesOrderCreateContent';
import { I18n } from 'react-redux-i18n';

class SalesOrderView extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
  }

  public render() {
    const {items} = this.props;
    const columns = [
      {
        title: I18n.t('sales_order.Product Title'),
        dataIndex: 'product_id',
        editable: true,
        render: (text: any, record: any) => {
          if (record.product) {
            return record.product.title;
          }
          return record.id;
        },
      },
      {
        title: I18n.t('sales_order.Price'),
        dataIndex: 'price',
        editable: true,
      },
      {
        title: I18n.t('sales_order.Quantity'),
        dataIndex: 'quantity',
        editable: true,
      },
    ];
    return <SalesOrderCreateContent />;
  }
}

const mapStateToProps = (state: AppState) => ({
  items: state.sales_order.items,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(SalesOrderView);
