import { Formik } from 'formik';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../../reducers';
import { createCategory, updateCategory, showModal } from '../../CategoryActions';
import CategoryFormContent from './CategoryFormContent';
import validationFields from '../../../../lib/validate';

interface CategoryModalProps {
  updateCategory: any;
  category: any;
  categories: any;
  parent_id: number;
  createCategory: any;
  showModal: any;
}

function CategoryModal({
  category,
  categories,
  parent_id,
  updateCategory,
  createCategory,
  showModal,
}: CategoryModalProps) {

  return (
    <Formik
      enableReinitialize={true}
      initialValues={ Object.assign({}, category, {parent_id}) }
      validate={(values: any) => {
        const fields: any = [
          {name: 'name', rules: ['required']},
        ];
        return validationFields(fields, values);
      }}
      onSubmit = {(values: any) => {
        if (values.id) {
          updateCategory(values.id, values);
        } else {
          createCategory(values);
        }
        showModal(false);
      }}
    >
      { (props) => <CategoryFormContent {...props}
        categories = {categories}
    />}
    </Formik>
  );
}

const mapStateToProps = (state: AppState) => ({
  category: state.category.category,
  categories: state.category.list_data,
  parent_id: state.category.selectedCategoryId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createCategory,
      updateCategory,
      showModal,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryModal);
