import {
    CATEGORY_RECEIVED,
    GET_CATEGORIES,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    SET_SELECTED_CATEGORY_ID,
    SET_CATEGORY_SHOW_MODAL,
    GET_FILTERED_CATEGORIES} from '../../types/CategoryTypes';

export const getCategories = () => ({
    type: GET_CATEGORIES,
});

export const getFilteredCategories = (filter: any = []) => ({
    type: GET_FILTERED_CATEGORIES,
    filter,
});

export const createCategory = (data: any) => ({
    type: CREATE_CATEGORY,
    data,
});

export const deleteCategory = (id: any) => ({
    type: DELETE_CATEGORY,
    id,
});

export const updateCategory = (id: any, data: any) => ({
    type: UPDATE_CATEGORY,
    data,
    id,
});

export const setCategory = (data: any) => ({
    type: CATEGORY_RECEIVED,
    data,
});

export const showModal = (status: any) => ({
    type: SET_CATEGORY_SHOW_MODAL,
    status,
});

export const setSelectedCategoryId = (id: any) => ({
    type: SET_SELECTED_CATEGORY_ID,
    id,
});
