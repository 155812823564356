export default {
  'Date': 'Дата',
  'Processed': 'Проведено',
  'Total': 'Сума',
  'Counterparty': 'Контрагенти',
  'Type': 'Тип',
  'Create': 'Створити',
  'All Categories': 'Всі категорії',
  'Please Select': 'Виберіть, будь ласка',
  'Name': 'Назва',
  'Parent Category': 'Батьківська категорія',
  'Operations': 'Дія',
  'Are You Sure?': 'Ви впевнені?',
  'Delete': 'Видалити',
  'Submit': 'Зберегти',
  'Categories': 'Категорії',
  'Edit': 'Редагувати',
};
