import './App.css';
import { Provider } from 'react-redux';
import Routes from './routes';
import rootReducer from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { syncTranslationWithStore } from 'react-redux-i18n';
import moment from 'moment';
import 'moment/locale/uk';
moment.locale('uk');

const w: any = window as any;
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const middleware = w.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(applyMiddleware(...middlewares), w.__REDUX_DEVTOOLS_EXTENSION__())
  : applyMiddleware(...middlewares);

const store = createStore(rootReducer, middleware);

sagaMiddleware.run(rootSaga);
syncTranslationWithStore(store);

function App() {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
