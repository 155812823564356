import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  getOrder,
  clearData,
} from './TransferOrderActions';
import { AppState } from '../../reducers';
import TransferOrderCreateContent from './components/TransferOrderCreateContent/TransferOrderCreateContent';

class TransferOrderCreate extends React.Component<any, any> {

  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.id) {
      this.props.getOrder(this.props.match.params.id, []);
    }
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  public render() {
    return <TransferOrderCreateContent />;
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getOrder,
      clearData,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferOrderCreate);
