import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button } from 'antd';
import { AppState } from '../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import CategoryTree from './components/CategoryTree/CategoryTree';
import CategoryTable from './components/CategoryTable/CategoryTable';
import CategoryModal from './components/CategoryModal/CategoryModal';
import {getCategories, setSelectedCategoryId, setCategory, getFilteredCategories, showModal} from './CategoryActions';
import { createNewTreeData } from '../../lib/helper';
import { I18n } from 'react-redux-i18n';
import CategoryTreeSelect from './components/CategoryTreeSelect/CategoryTreeSelect';

function CategoryList({ selectedKey, is_mobile, data, setSelectedCategoryId, getCategories, setCategory, showModal }: any) {

  useEffect(() => {
    setSelectedCategoryId(0);
    getCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const treeData: any =  [
    { value : 0, title: I18n.t('product.All Categories'), key: '0', children : createNewTreeData(data) }
  ];

  return (
    <div>
      <CategoryModal/>
      <Row>
        <Col md={5} sm= {23} xs = {24} style = {{overflow: 'auto'}}>
          {is_mobile ?
            <CategoryTreeSelect
              value={selectedKey}
              onSelect={(id: number) => setSelectedCategoryId(id)}
              treeData={treeData}
            />
            :
            <CategoryTree
              selectedKeys={selectedKey}
              treeData={treeData}
              onSelect={(id: string) => {
                if (id[0]) {
                  setSelectedCategoryId(parseInt(id, 10));
                }
              }}
            />
          }
        </Col>
        <Col md={19} sm= {23} xs = {24} >
          <Button
            type="primary"
            style={{ margin : '16px 0' }}
            onClick={ () => {
              setCategory({});
              showModal(true);
            }}
          > {I18n.t('category.Create')} </Button>
          <div style = {{overflow: 'auto'}}>
            <CategoryTable />
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  data: state.category.list_data,
  selectedKey: state.category.selectedCategoryId,
  is_mobile: state.auth.is_mobile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCategories,
      getFilteredCategories,
      setSelectedCategoryId,
      showModal,
      setCategory,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(CategoryList);
