import moment from 'moment';
import { put, takeLatest, select } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import findIndex from 'lodash.findindex';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_TRANSFER_ORDERS_FILTERS } from '../types/Constants';
import {
  CREATE_TRANSFER_ORDER,
  CREATE_TRANSFER_ORDER_ITEM,
  CREATE_TRANSFER_ORDER_ITEM_RECEIVED,
  CREATE_TRANSFER_ORDER_RECEIVED,
  DELETE_TRANSFER_ORDER,
  DELETE_TRANSFER_ORDER_ITEM,
  DELETE_TRANSFER_ORDER_ITEM_RECEIVED,
  GET_TRANSFER_ORDER,
  GET_TRANSFER_ORDERS,
  SET_TRANSFER_ORDERS_FILTER,
  TRANSFER_ORDERS_RECEIVED,
  TRANSFER_ORDER_ITEMS_RECEIVED,
  TRANSFER_ORDER_PROCESSED,
  TRANSFER_ORDER_PROCESSED_RECEIVED,
  TRANSFER_ORDER_RECEIVED,
  TRANSFER_ORDER_SET_TOTALS,
  TRANSFER_ORDER_UPDATE,
  UPDATE_TRANSFER_ORDER,
  UPDATE_TRANSFER_ORDER_ITEM,
  UPDATE_TRANSFER_ORDER_ITEM_RECEIVED,
  UPDATE_TRANSFER_ORDER_RECEIVED,
} from '../types/TransferOrderTypes';

function* getOrders({filter}: any) {
  setValueByKey(STORAGE_TRANSFER_ORDERS_FILTERS, filter);
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('transfer_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('transfer_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('transfer_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      const storages: GenericEntityResponse[] = yield select(state => state.started_data?.storages || [])
      data.forEach((item: any) => {
        const index = findIndex(totals, ['order_id', item.id]);
        item.storage_from = (storages.find(storage => storage.id == item.storage_from_id) || { name: '' }).name;
        item.storage_to = (storages.find(storage => storage.id == item.storage_to_id) || { name: '' }).name;
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: TRANSFER_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_TRANSFER_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: TRANSFER_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0} });
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}

function* deleteOrder({filter, id}: any) {

  try {

    yield restClient.delete('transfer_orders', id);
    const {data, count}: GenericEntitiesResponse = yield restClient.get('transfer_orders', '', generateFilter(filter));
    const ordersTotals: GenericEntityResponse = yield restClient.get('transfer_orders', 'sum_totals', generateFilter(filter, true));
    if (data.length) {
      const totals: GenericEntityResponse[] = yield restClient.get('transfer_orders', 'totals', {
        ids: data.map((item: any) => item.id).join(','),
      });
      data.forEach((item: any) => {
        const index = findIndex(totals, ['order_id', item.id]);
        if (index > -1) {
          item.base_total = totals[index].total;
          item.sub_total = totals[index].total;
          item.total = totals[index].total * item.currency_rate;
        } else {
          item.base_total = 0;
          item.total = 0;
          item.sub_total = 0;
        }
      });
    }

    yield put({ type: TRANSFER_ORDERS_RECEIVED, data, count, totals: ordersTotals });
    yield put({ type: SET_TRANSFER_ORDERS_FILTER, filter });
  } catch (error) {
    yield put({ type: TRANSFER_ORDERS_RECEIVED, data: [] , count: 0, totals: {total: 0} });
    yield put({ type: ERROR, messages: ['Not orders']});
  }
}

function* getOrder({ id }: any) {

  try {
    const data: GenericEntityResponse = yield restClient.findOne('transfer_orders', id, { populate: 'items' });
    const { data: transferOrderItems }: GenericEntitiesResponse = yield restClient.find(
      'transfer_order_items',
      {
        order_id: id,
        populate: 'product',
        // TODO: improve pagination order items pagination
        limit: 1000,
      },
    );
    yield put({ type: TRANSFER_ORDER_RECEIVED, order: data });
    yield put({ type: TRANSFER_ORDER_ITEMS_RECEIVED, items: transferOrderItems });
  } catch (error) {
    yield put({ type: TRANSFER_ORDER_RECEIVED, order: {}});
    yield put({ type: TRANSFER_ORDER_ITEMS_RECEIVED, items: [] });

    yield put({ type: ERROR, messages: ['Not order']});
  }
}

function* createOrder({data}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.post('transfer_orders', data);
    const result: GenericEntityResponse = yield restClient.findOne('transfer_orders', item.id, { });
    yield put({ type: CREATE_TRANSFER_ORDER_RECEIVED, order: result });
  } catch (error) {
    yield put({ type: CREATE_TRANSFER_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* updateOrder({data, id}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const item: GenericEntityResponse = yield restClient.update('transfer_orders', id, data);
    const result: GenericEntityResponse = yield restClient.findOne('transfer_orders', item.id, { });
    const totals: GenericEntityResponse = yield restClient.get('transfer_orders', 'totals', { ids: id });
    yield put({ type: UPDATE_TRANSFER_ORDER_RECEIVED, order: result, id });
    if (totals[0]) {
      yield put({ type: TRANSFER_ORDER_UPDATE, item: {
        base_total: totals[0].total || 0,
        sub_total: totals[0].total || 0,
        total: totals[0].total * item.currency_rate,
        id,
      }});
    }
  } catch (error) {
    yield put({ type: UPDATE_TRANSFER_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order update']});
  }
}

function* setOrderProcessed({data, filter}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('transfer_orders', 'processed', data);
    const ordersTotals: GenericEntityResponse = yield restClient.get('transfer_orders', 'sum_totals', generateFilter(filter, true));
    yield put({ type: TRANSFER_ORDER_PROCESSED_RECEIVED, order: result });
    yield put({ type: TRANSFER_ORDER_SET_TOTALS, totals: ordersTotals });

  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order create']});
  }
}

function* updateOrderItem({data, id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.update('transfer_order_items', id, data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('transfer_orders', 'totals', { ids: data.order_id });
    const order: GenericEntityResponse = yield restClient.get('transfer_orders', data.order_id, { populate: 'items' });
    yield put({ type: TRANSFER_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: data.order_id,
    }});
    yield put({ type: UPDATE_TRANSFER_ORDER_ITEM_RECEIVED, item: {...result, product}, id });
  } catch (error) {
    // yield put({ type: TRANSFER_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order item update']});
  }
}

function* createOrderItem({data}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.create('transfer_order_items', data);
    const product: GenericEntityResponse = yield restClient.findOne('products', data.product_id);
    const totals: GenericEntityResponse[] = yield restClient.get('transfer_orders', 'totals', {ids: data.order_id});
    const order: GenericEntityResponse = yield restClient.get('transfer_orders', data.order_id, { populate: 'items' });
    yield put({ type: TRANSFER_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: data.order_id,
    }});
    yield put({ type: CREATE_TRANSFER_ORDER_ITEM_RECEIVED, item: {...result, product} });
  } catch (error) {
    // yield put({ type: TRANSFER_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order item create']});
  }
}

function* deleteOrderItem({id, order_id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('transfer_order_items', id);
    const totals: GenericEntityResponse[] = yield restClient.get('transfer_orders', 'totals', { ids: order_id });
    const order: GenericEntityResponse = yield restClient.get('transfer_orders', order_id, { populate: 'items' });
    yield put({ type: TRANSFER_ORDER_UPDATE, item: {
      base_total: totals[0].total || 0,
      sub_total: totals[0].total || 0,
      total: totals[0].total * order.currency_rate,
      id: order_id,
    }});
    yield put({ type: DELETE_TRANSFER_ORDER_ITEM_RECEIVED, id });
  } catch (error) {
    // yield put({ type: TRANSFER_ORDER_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not delete order item']});
  }
}

export default function* actionWatcherTransferOrder() {
  yield takeLatest(GET_TRANSFER_ORDERS, getOrders);
  yield takeLatest(GET_TRANSFER_ORDER, getOrder);
  yield takeLatest(CREATE_TRANSFER_ORDER, createOrder);
  yield takeLatest(UPDATE_TRANSFER_ORDER, updateOrder);
  yield takeLatest(CREATE_TRANSFER_ORDER_ITEM, createOrderItem);
  yield takeLatest(UPDATE_TRANSFER_ORDER_ITEM, updateOrderItem);
  yield takeLatest(DELETE_TRANSFER_ORDER_ITEM, deleteOrderItem);
  yield takeLatest(TRANSFER_ORDER_PROCESSED, setOrderProcessed);
  yield takeLatest(DELETE_TRANSFER_ORDER, deleteOrder);
}
