import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import StorageList from './StorageList';

function Storage({ loading }: any) {
  return (
    <Spin spinning={loading}>
      <Switch>
        <Route exact path="/storage/list" component={StorageList} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.storage.loading,
});

export default connect(mapStateToProps)(Storage);
