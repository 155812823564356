import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import { BILLS_RECEIVED,
  GET_BIILS,
  UPDATE_BILL_RECEIVED,
  CREATE_BILL,
  UPDATE_BILL,
  DELETE_BILL,
  DELETE_BILL_RECEIVED,
  CREATE_BILL_RECEIVED,
} from '../types/BillTypes';
import generateFilter from '../lib/genFilter';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_BILL_FILTERS } from '../types/Constants';

function* getBills({filter}: any) {
  setValueByKey(STORAGE_BILL_FILTERS, filter);
  try {
    const {data, count} = yield restClient.get('bills', '', generateFilter(filter));
    const totals: GenericEntityResponse = yield restClient.get('bills', 'sum_totals', generateFilter(filter, true));
    yield put({ type: BILLS_RECEIVED, data, count, filter, totals });
  } catch (error) {
    yield put({ type: BILLS_RECEIVED, data: [], filter, count: 0 });
    yield put({ type: ERROR, messages: ['Not products']});
  }
}

function* updateBill({data, id}: any) {
  if (data.base_total && data.currency_rate) {
    data.total = data.base_total * data.currency_rate;
  }
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.update('bills', id, data);
    const totals: GenericEntityResponse = yield restClient.get('bills', 'sum_totals', {ids: id});
    yield put({ type: UPDATE_BILL_RECEIVED, data: result, id, totals });
  } catch (error) {
    // yield put({ type: BILL_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not order data update']});
  }
}

function* createBill({data, filter}: any) {
  data.total = data.base_total * data.currency_rate;

  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.create('bills', data);
    const res: GenericEntitiesResponse = yield restClient.get('bills', '', generateFilter(filter));
    const totals: GenericEntityResponse = yield restClient.get('bills', 'sum_totals', generateFilter(filter, true));
    yield put({ type: BILLS_RECEIVED, data: res.data, count: res.count, filter, totals });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not order data create']});
  }
}

function* deleteBill({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('bills', id);
    yield put({ type: DELETE_BILL_RECEIVED, id });
  } catch (error) {
    // yield put({ type: BILL_RECEIVED, order: {} });
    yield put({ type: ERROR, messages: ['Not delete order data']});
  }
}

export default function* actionWatcherBill() {
  yield takeLatest(GET_BIILS, getBills);
  yield takeLatest(CREATE_BILL, createBill);
  yield takeLatest(UPDATE_BILL, updateBill);
  yield takeLatest(DELETE_BILL, deleteBill);
}
