// eslint-disable-next-line import/no-anonymous-default-export
export default {
  'Create': 'Створити',
  'Product Title': 'Назва товару',
  'Product status': 'Статус',
  'Products Title': 'Товари',
  'Price': 'Ціна',
  'Quantity': 'Кількість',
  'Storage': 'Склад',
  'Date': 'Дата',
  'All Categories': 'Всі категорії',
  'Processed': 'Проведено',
  'Total Price': 'Загальна вартість',
  'Subtotal': 'Сума',
  'Operation': 'Дія',
  'Type': 'Тип',
  'Note': 'Примітка',
  'Description': 'Опис',
  'Published': 'Опубліковано',
  'Category': 'Категорія',
  'Save': 'Зберегти',
  'Cancel': 'Закрити',
  'Barcode': 'Штрих-код',
  'Yes': 'Так',
  'No': 'Ні',
  'Product type': 'Тип продукту',
  'Update': 'Редагувати',
};
