import moment from 'moment';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_TRANSFERS,
  TRANSFERS_RECEIVED,
  CREATE_TRANSFER_RECEIVED,
  UPDATE_TRANSFER_RECEIVED,
  DELETE_TRANSFER_RECEIVED,
  CREATE_TRANSFER,
  UPDATE_TRANSFER,
  DELETE_TRANSFER,
  SET_TRANSFERS_FILTER,
} from '../types/TransferTypes';
import { GenericEntitiesResponse, GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_TRANSFERS_FILTERS } from '../types/Constants';

function* getTransfers({filter}: any) {
  setValueByKey(STORAGE_TRANSFERS_FILTERS, filter)
  try {
    const {data, count}: GenericEntitiesResponse = yield restClient.get('transfers', '', generateFilter(filter));
    yield put({ type: TRANSFERS_RECEIVED, data, count, filter });
    yield put({ type: SET_TRANSFERS_FILTER, filter });
  } catch (error) {
    yield put({ type: TRANSFERS_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not transfers']});
  }
}

function* createTransfer({data, filter}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.post('transfers', data);
    // yield put({ type: CREATE_TRANSFER_RECEIVED, item: result });
    const res: GenericEntitiesResponse = yield restClient.get('transfers', '', generateFilter(filter));
    yield put({ type: TRANSFERS_RECEIVED, data: res.data, count: res.count, filter });
  } catch (error) {
    yield put({ type: CREATE_TRANSFER_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not transfer create']});
  }
}

function* updateTransfer({data, id}: any) {
  try {
    if (moment.isMoment(data.date)) {
      data.date = data.date.format('YYYY-MM-DD');
    }
    const result: GenericEntityResponse = yield restClient.update('transfers', id, data);
    yield put({ type: UPDATE_TRANSFER_RECEIVED, item: result });
  } catch (error) {
    yield put({ type: UPDATE_TRANSFER_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not transfer update']});
  }
}
function* deleteTransfer({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('transfers', id);
    yield put({ type: DELETE_TRANSFER_RECEIVED, id });
  } catch (error) {
    yield put({ type: ERROR, messages: ['Not transfer delete']});
  }
}

export default function* actionWatcherTransfer() {
  yield takeLatest(GET_TRANSFERS, getTransfers);
  yield takeLatest(CREATE_TRANSFER, createTransfer);
  yield takeLatest(UPDATE_TRANSFER, updateTransfer);
  yield takeLatest(DELETE_TRANSFER, deleteTransfer);
}
