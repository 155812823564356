import { cloneDeep } from 'lodash';
import { getValueByKey } from '../lib/storage';
import { STORAGE_PRODUCTS_FILTERS } from '../types/Constants';
import {
  GET_PRODUCTS,
  PRODUCT_RECEIVED,
  PRODUCTS_RECEIVED,
  CREATE_PRODUCT_RECEIVED,
  DELETE_PRODUCT_RECEIVED,
  UPDATE_PRODUCT_RECEIVED,
  SET_PRODUCTS_FILTER,
  SET_SELECTED_CATEGORY_ID,
  SET_PRODUCT_SHOW_MODAL} from '../types/ProductTypes';

export interface ProductsState {
  selected_category_id: number;
  loading: boolean;
  list_data: any[];
  product: any;
  total_count: number;
  filter: any[];
  show_modal: boolean;
}

const defaultState: ProductsState = {
  show_modal: false,
  selected_category_id: 0,
  loading: false,
  list_data: [],
  product: {},
  total_count: 0,
  filter: getValueByKey(STORAGE_PRODUCTS_FILTERS,[
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 5,
    },
    {
      attribute: 'populate',
      value: 'category',
    },
    {
      attribute: 'sort',
      value: '-createdAt',
    },
  ]),
};

export default (state: ProductsState = defaultState, action: any) => {
  let tmpListData: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_PRODUCTS:
      return {...state, loading: true};
    case PRODUCTS_RECEIVED:
      const total_count = action.count > -1 ? action.count : state.total_count;
      return {...state,
        loading: false,
        list_data: action.data,
        filter: action.filter || state.filter,
        total_count,
      };
    case PRODUCT_RECEIVED:
      return {...state, loading: false, product: action.data};
    case CREATE_PRODUCT_RECEIVED:
      return {...state,
        total_count: state.total_count + 1,
        list_data: [action.product, ...state.list_data ] };
    case UPDATE_PRODUCT_RECEIVED:
      return {...state,
        list_data: state.list_data.map((product: any) =>
        product.id === action.id ? action.product : product) };
    case DELETE_PRODUCT_RECEIVED:
      tmpListData = tmpListData.filter((product: any) => product.id !== product.id);
      return {...state, list_data: tmpListData, total_count: state.total_count - 1};
    case SET_PRODUCT_SHOW_MODAL:
      return {...state, show_modal: action.status};
    case SET_PRODUCTS_FILTER:
      return {...state, filter: action.filter};
    case SET_SELECTED_CATEGORY_ID:
      return {...state, selected_category_id: action.id };
    default:
      return state;
  }
};
