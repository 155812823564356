import {
  CREATE_TRANSFER_ORDER,
  CREATE_TRANSFER_ORDER_ITEM,
  DELETE_TRANSFER_ORDER,
  DELETE_TRANSFER_ORDER_ITEM,
  GET_TRANSFER_ORDER,
  GET_TRANSFER_ORDERS,
  SET_SHOWMODAL_TRANSFER_ORDER,
  SET_TRANSFER_ORDER_READING_MODE,
  TRANSFER_ORDER_CLEAR_DATA,
  TRANSFER_ORDER_ITEMS_RECEIVED,
  TRANSFER_ORDER_PROCESSED,
  TRANSFER_ORDER_RECEIVED,
  UPDATE_TRANSFER_ORDER,
  UPDATE_TRANSFER_ORDER_ITEM,
} from '../../types/TransferOrderTypes';
// import { GET_STORAGES } from '../../types/StorageTypes';
// import { GET_COUNTERPARTIES } from '../../types/CounterpartyTypes';
// import { GET_DICTIONARIES } from '../../types/DictionaryTypes';

export const getOrders = (filter: any = []) => ({
  type: GET_TRANSFER_ORDERS,
  filter,
});

export const getOrder = (id: number, filter: any) => ({
  type: GET_TRANSFER_ORDER,
  id,
  filter,
});

export const createOrder = (data: any) => ({
  type: CREATE_TRANSFER_ORDER,
  data,
});

export const updateOrder = (id: number, data: any) => ({
  type: UPDATE_TRANSFER_ORDER,
  data,
  id,
});

export const deleteOrder = (id: number, filter: any) => ({
  type: DELETE_TRANSFER_ORDER,
  id,
  filter,
});

export const updateOrderItem = (id: number, data: any) => ({
  type: UPDATE_TRANSFER_ORDER_ITEM,
  data,
  id,
});

export const createOrderItem = (data: any) => ({
  type: CREATE_TRANSFER_ORDER_ITEM,
  data,
});

export const deleteOrderItem = (id: any, order_id: any) => ({
  type: DELETE_TRANSFER_ORDER_ITEM,
  id,
  order_id,
});

export const clearData = () => ({
  type: TRANSFER_ORDER_CLEAR_DATA,
});

export const setOrderProcessed = (data: boolean, filter: any) => ({
  type: TRANSFER_ORDER_PROCESSED,
  data,
  filter,
});

export const setShowModal = (status: boolean) => ({
  type: SET_SHOWMODAL_TRANSFER_ORDER,
  status,
});

export const clearOrder = () => ({
  type: TRANSFER_ORDER_RECEIVED,
  order: {},
});

export const clearOrderItems = () => ({
  type: TRANSFER_ORDER_ITEMS_RECEIVED,
  items: [],
});

export const setReadingMode = (status: boolean) => ({
  type: SET_TRANSFER_ORDER_READING_MODE,
  status,
});
