import { Form, DatePicker, Select, Dropdown, Button, Menu } from 'antd';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { periods } from '../../../../../../config/period';
import locale from 'antd/es/date-picker/locale/uk_UA';
import { useCallback, useState } from 'react';

function FieldDate({ attribute, values, is_mobile, handleChange, handleSubmit }: any) {
  const [value, setValue] = useState({
    from: '',
    to: '',
  });

  const onChange = useCallback(
    (name = '') =>
      (date: any) => {
        let valueAtt: any = {
          attribute: attribute.name,
        };

        if (attribute.comparison) {
          valueAtt.comparison = attribute.comparison;
        }

        if (attribute.comparison === 'date_is_within') {
          if (values && values.value) {
            valueAtt.value = values.value;
          } else {
            valueAtt.value = {
              from: '',
              to: '',
            };
          }

          valueAtt.value[name] = date?._isValid ? date.format('YYYY-MM-DD') : '';

          if (!valueAtt.value.from && !valueAtt.value.to) {
            valueAtt = '';
          }

          if (valueAtt.hasOwnProperty('value')) {
            setValue(valueAtt.value);
          }
        } else {
          valueAtt.value = date?._isValid ? date.format('YYYY-MM-DD') : '';
        }

        handleChange({
          target: {
            value: valueAtt,
            name: attribute.name,
          },
        });

        setTimeout(() => {
          handleSubmit();
        }, 200);
      },
    [attribute.comparison, attribute.name, handleChange, handleSubmit, values]
  );

  const disabledStartDate = useCallback((startValue: any) => {
    const endValue = value.to;
    if (!startValue || !endValue) {
      return false;
    }
    return !startValue || startValue.isAfter(endValue);
  }, [value.to]);

  const disabledEndDate = useCallback((endValue: any) => {
    const startValue = value.from;
    if (!endValue || !startValue) {
      return false;
    }
    return !endValue || endValue.isBefore(startValue);
  }, [value.from]);

  const changePeriod = (period: any) => {
    if (period) {
      const res = period.split('&&');
      setValue({ from: res[0], to: res[1] });

      handleChange({
        target: {
          value: {
            attribute: attribute.name,
            comparison: 'date_is_within',
            value: {
              from: res[0],
              to: res[1],
            },
          },
          name: attribute.name,
        },
      });

      setTimeout(() => {
        handleSubmit();
      }, 200);
    } else {
      handleChange({
        target: {
          value: {
            attribute: attribute.name,
            comparison: 'date_is_within',
            value: {
              from: '',
              to: '',
            },
          },
          name: attribute.name,
        },
      });

      setTimeout(() => {
        handleSubmit();
      }, 200);
    }
  };

  const menu = (
    <Menu>
      {periods.map((item, index) => (
        <Menu.Item key={item.to + '_' + index} onClick={() => changePeriod(item.from + '&&' + item.to)}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Form.Item
      // validateStatus={'error'}
      // help={'Error'}
      label={attribute.label}
      className="custom-date-picker"
    >
      {attribute.comparison === 'date_is_within' ? (
        [
          <DatePicker
            key="date-from"
            locale={locale}
            value={
              values &&
              values.value &&
              values.value.from &&
              moment(values.value.from).isValid() &&
              moment(values.value.from)
            }
            name="from"
            onChange={onChange('from')}
            placeholder={attribute.title + ' ' + I18n.t('filter_form.from')}
            disabledDate={disabledStartDate}
            className="date-picker-from"
          />,
          <DatePicker
            key="date-to"
            locale={locale}
            name="to"
            value={
              values && values.value && values.value.to && moment(values.value.to).isValid() && moment(values.value.to)
            }
            style={{ marginLeft: is_mobile ? '' : 10 }}
            disabledDate={disabledEndDate}
            onChange={onChange('to')}
            placeholder={attribute.title + ' ' + I18n.t('filter_form.to')}
            className="date-picker-to"
          />,
          is_mobile ? (
            <Dropdown key="mobile_pick" overlay={menu} trigger={['click', 'hover']} className="date-picker-period">
              <Button>...</Button>
            </Dropdown>
          ) : (
            <Select
              key="desc_pick"
              style={{ width: is_mobile ? '100%' : 168, marginLeft: is_mobile ? '' : 10 }}
              allowClear={true}
              onChange={changePeriod}
              placeholder={I18n.t('filter_form.Period')}
            >
              {periods.map((item, index) => (
                <Select.Option key={item.to + '_' + index} value={item.from + '&&' + item.to}>
                  {I18n.t(`filter_form.${item.name}`)}
                </Select.Option>
              ))}
            </Select>
          ),
        ]
      ) : (
        <DatePicker
          key="date_pick"
          locale={locale}
          {...attribute.inputProps}
          value={values && values.value && moment(values.value).isValid() ? moment(values.value) : undefined}
          onChange={onChange('')}
          placeholder={attribute.title}
        />
      )}
    </Form.Item>
  );
}

export default FieldDate;
