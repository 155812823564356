import * as React from 'react';
import { AppState } from '../../../../../reducers';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { TreeSelect } from 'antd';
import { setSelectedDialogCategoryId, getDialogProducts } from '../../ProductDialogActions';
import { createNewTreeData } from '../../../../../lib/helper';

const TreeNode = TreeSelect.TreeNode;

class ProductDialogSelect extends React.Component<any, any> {

  onSelect = (id: any) => {
    if (id !== 0) {
        this.props.filter.push({attribute: 'category_id', value: id});
        this.props.getDialogProducts(this.props.filter.filter((item: any) => item.attribute !== 'skip'));
    } else {
        this.props.getDialogProducts( this.props.filter.filter((item: any) => item.attribute !== 'category_id' ));
    }
    this.props.setSelectedDialogCategoryId( parseInt(id, 10 ));
  }

  renderTreeNodes = (data: any) => data.map((item: any) => {
    if (item.children) {
      return (
        <TreeNode
          title={item.name}
          key={item.id}
          value={item.id}
        >
          {this.renderTreeNodes(item.children)}
        </TreeNode>
      );
    }
    return <TreeNode {...item} />;
  })

  public render() {

    const {categories, selectedKey} = this.props;
    const treeData: any =  [{ value : 0, name: 'All Categories', children : createNewTreeData(categories)}];

    return (
      <div style = {{overflow: 'auto'}}>
        <TreeSelect
          treeDefaultExpandAll
          onSelect = {this.onSelect}
          value={selectedKey}
          treeData={treeData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  filter: state.dialog_product.filter,
  categories: state.started_data.categories,
  selectedKey: state.dialog_product.selected_category_id,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators ({
    getDialogProducts,
    setSelectedDialogCategoryId,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductDialogSelect);
