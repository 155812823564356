export const STORAGE_SALES_ORDERS_FILTERS = 'StorageSalesOrdersFilters'

export const STORAGE_PURCHASE_ORDERS_FILTERS = 'StoragePurchaseOrdersFilters'

export const STORAGE_TRANSFER_ORDERS_FILTERS = 'StorageTransferOrdersFilters'

export const STORAGE_DICTIONARIES_FILTERS = 'StorageDictionariesFilters'

export const STORAGE_ACCOUNTS_FILTERS = 'StorageAccountsFilters'

export const STORAGE_COUNTERPARTY_FILTERS = 'StorageCounterpartyFilters'

export const STORAGE_PRODUCTS_FILTERS = 'StorageProductsFilters'

export const STORAGE_BILL_FILTERS = 'StorageBillFilters'

export const STORAGE_PAYMENTS_FILTERS = 'StoragePaymentsFilters'

export const STORAGE_TRANSFERS_FILTERS = 'StorageTransfersFilters'

export const STORAGE_STORAGES_FILTERS = 'StorageStoragesFilters'
