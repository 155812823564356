import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Row } from 'antd';
import { AppState } from '../../reducers';
import OrderItemsEditable from '../components/OrderItemsEditable/OrderItemsEditable';
import {
  createAccount,
  updateAccount,
  deleteAccount,
  getAccounts,
} from './AccountActions';
import { I18n } from 'react-redux-i18n';
import cloneDeep from 'lodash.clonedeep';
import findIndex from 'lodash.findindex';

function AccountList({ filter, accounts, total_count, getAccounts, updateAccount, deleteAccount, createAccount }: any) {

  useEffect(() => {
    const retrievedObject: any = localStorage.getItem('default_values') || '{}';
    const account_limit: number = JSON.parse(retrievedObject).account_limit;
    const _filter: any = cloneDeep(filter);

    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    if (indexLimit > -1) {
      _filter[indexLimit].value = account_limit || 1000;
    } else {
      _filter.push({ attribute: 'limit', value: 1000 });
    }

    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexSkip > -1) {
      _filter[indexSkip].value = 0;
    }

    getAccounts(_filter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateItem = useCallback((data: any) => {
    updateAccount(data.id, data);
  }, [updateAccount]);

  const deleteItem = useCallback((data: any) => {
    deleteAccount(data);
  }, [deleteAccount]);

  const createItem = useCallback((data: any) => {
    createAccount({...data});
  }, [createAccount]);

  const onChange = useCallback((pagination: any, filters: any, sorter: any) => {

    const _filter: any = cloneDeep(filter);

    const index = findIndex(filter, ['attribute', 'sort']);
    const indexLimit = findIndex(filter, ['attribute', 'limit']);
    const indexSkip = findIndex(filter, ['attribute', 'skip']);
    if (indexLimit > -1) {
      const skip = parseFloat(pagination.current) * parseFloat(_filter[indexLimit].value) - _filter[indexLimit].value;
      (indexSkip > -1) ? _filter[indexSkip].value = skip : _filter.push({attribute: 'skip', value: skip});
    }

    if (!Object.keys(sorter).length) {
      getAccounts(_filter.filter((item: any) => item.attribute !== 'sort'));
      return;
    }

    if (index > -1) {
      _filter[index].value = sorter.order === 'ascend' ? sorter.field : '-' + sorter.field;
    } else {
      _filter.push({attribute: 'sort', value: sorter.order === 'ascend' ? sorter.field : '-' + sorter.field});
    }
    getAccounts(_filter);
  }, [filter, getAccounts])

  let limit = 0;

  filter.forEach((item: any) => {
    if (item.attribute === 'limit') {
      limit = item.value;
    }
  });

  const pagination = (total_count <= limit)
    ? false
    : {
      total : total_count + total_count / limit,
      pageSize: limit + 1,
    };

  const validateRules = [
    {name: 'name', rules: ['required']},
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: I18n.t('account.Name'),
      dataIndex: 'name',
      editable: true,
    },
  ];

  return (
    <div style = {{overflow: 'auto'}} className = "small-table simple-actions no-status">
      <Row>
        <OrderItemsEditable
          onChange={onChange}
          insertNewRow="first"
          btnCreateDisabled={false}
          items={accounts}
          create={createItem}
          update={updateItem}
          delete={deleteItem}
          validateRules={validateRules}
          columns={columns}
          actionUpdateShow={(item: any) => !item.processed}
          actionDeleteShow={(item: any) => !item.processed}
          enableReinitialize={true}
          btnCreateShow={false}
          showNewLine={true}
          pagination={pagination}
          operationsOnLeft = {true}
        />
      </Row>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.account.filter,
  accounts: state.account.list_data,
  total_count: state.account.total_count,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createAccount,
      updateAccount,
      deleteAccount,
      getAccounts,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(AccountList);
