import { I18n } from 'react-redux-i18n';
import { get } from 'lodash'

/**
 * 
 * rule = '' || {name: '', }
 * 
 */


export default function validationFields(fields: any, values: any) {
  let errors = {};
  if (!Array.isArray(fields)) {
    return errors;
  }
  fields.forEach(field => {
    const rules = Array.isArray(field.rules) ? field.rules : [field.rules];
    rules.forEach((_rule: any) => {
      const rule = typeof _rule == 'object' ? _rule : { name: _rule };
      validField(field, rule.name, rule, values, errors);
    });
  });
  return errors;
}

function validField(field: any, rule_name: any, rule: any, values: any, errors: any) {
  let value = get(values, field.name);
  const t = (message: string, options?: any) => field.message || rule.message || I18n.t(`errors.${message}`, options) 
  switch (rule_name) {
    case 'required':
      if (!value && isNaN(parseFloat(value))) {
        // errors[field.name] = field.message || 'Required'
        setError(field.name, t('Required'), errors)
      }
      break;
    case 'email':
      if (value) {
        let email = value;
        const atpos = email.indexOf('@');
        const dotpos = email.lastIndexOf('.');
        if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
          setError(field.name, t('Not a valid e-mail address'), errors)
        }
      }
      break;
    case 'numeric':
      if (value) {
        if (!(!isNaN(parseFloat(value)) && isFinite(value))) {
          setError(field.name, t('Not a valid number'), errors)
        }
      }
      break;
    case 'length':
      if (value) {
        if (value.length !== rule.value) {
          setError(field.name, t('Should be {n} symbols', { n: rule.value }), errors);
        }
      }
      break;
    case 'integer':
      if (value) {
        let n = Math.floor(Number(value));
        if (!(String(n) === value && n >= 0)) {
          setError(field.name, t('Not a valid integer'), errors);
        }
      }
      break;
    case 'gt':
      if (value) {
        if (value <= rule.value) {
          setError(field.name, t('The number must be greater {n}', { n: rule.value }), errors);
        }
      }
      break;
    case 'gte':
      if (value) {
        if (value < rule.value) {
          setError(field.name, t('The number must be greater than or equal to {n}', { n: rule.value }), errors);
        }
      }
      break;
    case 'lt':
      if (value) {
        if (value > rule.value) {
          setError(field.name, t('The number must be less than {n}', { n: rule.value }), errors);
        }
      }
      break;
    case 'lte':
      if (value) {
        if (value > rule.value) {
          setError(field.name, t(`The number must be less than or equal to ${rule.value}`), errors);
        }
      }
      break;
    case 'array':
        if (!( value && Array.isArray(value) && value.length && value[0])) {
          setError(field.name, t('Required'), errors);
        }
        break;
    default:
      break;
  }
}

function setError(field_name: any, message: any, errors: any) {
  errors[field_name] = message;
}
