import cloneDeep from 'lodash.clonedeep';
import { getValueByKey } from '../lib/storage';
import { STORAGE_TRANSFER_ORDERS_FILTERS } from '../types/Constants';
import {
  CREATE_TRANSFER_ORDER_ITEM_RECEIVED,
  CREATE_TRANSFER_ORDER_RECEIVED,
  DELETE_TRANSFER_ORDER_ITEM_RECEIVED,
  GET_TRANSFER_ORDER,
  GET_TRANSFER_ORDERS,
  SET_SHOWMODAL_TRANSFER_ORDER,
  SET_TRANSFER_ORDERS_FILTER,
  SET_TRANSFER_ORDER_READING_MODE,
  TRANSFER_ORDERS_RECEIVED,
  TRANSFER_ORDER_CLEAR_DATA,
  TRANSFER_ORDER_ITEMS_RECEIVED,
  TRANSFER_ORDER_PROCESSED,
  TRANSFER_ORDER_PROCESSED_RECEIVED,
  TRANSFER_ORDER_RECEIVED,
  TRANSFER_ORDER_SET_TOTALS,
  TRANSFER_ORDER_UPDATE,
  UPDATE_TRANSFER_ORDER_ITEM_RECEIVED,
  UPDATE_TRANSFER_ORDER_RECEIVED,
} from '../types/TransferOrderTypes';

export interface TransferOrdersState {
  loading: boolean;
  list_data: any[];
  filter: any[];
  items: any[];
  order: any;
  totals: any;
  total_count: number;
  show_modal: boolean;
  isReadingMode: boolean;
  created_order_id: number;
}

const defaultState: TransferOrdersState = {
  loading: false,
  list_data: [],
  order: {},
  totals: {
    total: 0,
    sub_total: 0,
  },
  items: [],
  total_count: 0,
  show_modal: false,
  isReadingMode: false,
  created_order_id : 0,
  filter: getValueByKey(STORAGE_TRANSFER_ORDERS_FILTERS, [
    {
      attribute: 'include_count',
      value: true,
    },
    {
      attribute: 'limit',
      value: 10,
    },
    // {
    //   attribute: 'populate',
    //   value: 'storage_from,storage_to',
    // },
    {
      attribute: 'sort',
      value: '-date',
    },
    // {
    //   attribute: 'date',
    //   comparison: 'date_is_within',
    //   value: {
    //     from: moment().toJSON(),
    //     to: moment().toJSON(),
    //   }
    // },
  ]),
};

const transferOrderReducer = (state: TransferOrdersState = defaultState, action: any) => {
  let tmpItems: any = cloneDeep(state.items);
  let tmpListData: any = cloneDeep(state.list_data);
  switch (action.type) {
    case GET_TRANSFER_ORDERS:
      return {...state, loading: true, created_order_id : 0 };
    // case TRANSFER_ORDER_DATA_CREATE:
      // return {...state, loading: true};
    case TRANSFER_ORDER_UPDATE:
      tmpListData = tmpListData.map((item: any) => {
        if (item.id === action.item.id) {
          item = Object.assign({}, item, action.item);
        }
        return item;
      });
      return {...state, list_data: tmpListData};
    // case TRANSFER_ORDER_DATA_CREATE_RECEIVED:
      // return {...state, loading: false};
    case CREATE_TRANSFER_ORDER_RECEIVED:
      return {...state,
        total_count: state.total_count + 1,
        list_data: [action.order, ...state.list_data],
        created_order_id : action.order.id,
        loading: false,
        order: action.order,
      };
    case UPDATE_TRANSFER_ORDER_RECEIVED:
      return {...state,
        order: action.order,
        list_data: state.list_data.map((order: any) => order.id === action.id ? action.order : order),
        created_order_id : 0,
        loading: false,
      };
    case GET_TRANSFER_ORDER:
      return {...state, loading: true};
    case SET_TRANSFER_ORDERS_FILTER:
      return {...state, filter: action.filter};
    case TRANSFER_ORDER_SET_TOTALS:
      return {...state, totals: action.totals};
    case TRANSFER_ORDERS_RECEIVED:
      return {...state,
        loading: false,
        list_data: action.data,
        total_count: action.count,
        totals: action.totals,
      };
    case TRANSFER_ORDER_RECEIVED:
      return {...state, loading: false, order: action.order};
    case CREATE_TRANSFER_ORDER_ITEM_RECEIVED:
      return {...state, items: [...state.items, action.item]};
    case UPDATE_TRANSFER_ORDER_ITEM_RECEIVED:
      return {...state, items: state.items.map((item: any) => item.id === action.id ? action.item : item)};
    case DELETE_TRANSFER_ORDER_ITEM_RECEIVED:
      tmpItems = tmpItems.filter((item: any) => item.id !== action.id);
      return {...state, items: tmpItems};
    case TRANSFER_ORDER_ITEMS_RECEIVED:
      return {...state, items: action.items};
    case TRANSFER_ORDER_CLEAR_DATA:
      return defaultState;
    case TRANSFER_ORDER_PROCESSED:
      return {...state, loading: true};
    case TRANSFER_ORDER_PROCESSED_RECEIVED:
      return {...state,
        loading: false,
        list_data: tmpListData.map((item: any) =>
          item.id === action.order.id ? Object.assign(item, {processed: action.order.processed}) : item,
        ),
      };
    case SET_SHOWMODAL_TRANSFER_ORDER:
      return {...state, show_modal: action.status};
    case SET_TRANSFER_ORDER_READING_MODE:
      return {...state, isReadingMode: action.status};
    default:
       return state;
  }
};

export default transferOrderReducer;
