import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { Spin } from 'antd';
import { AppState } from '../../reducers';
import CounterpartyList from './CounterpartyList';

function Counterparty({ loading }: any) {
  return (
    <Spin spinning={loading}>
      <Switch>
        <Route exact path="/counterparty/list" component={CounterpartyList} />
      </Switch>
    </Spin>
  );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.counterparty.loading,
});

export default connect(mapStateToProps)(Counterparty);
