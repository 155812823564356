import { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../reducers';
import { Row, Space } from 'antd';
import Filter from '../components/Filter/Filter';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import ReportCounterpartyData from './components/ReportCounterpartyData';
import { getReportCounterparty } from './ReportActions';

function ReportCounterparty({ filter, getReportCounterparty }: any) {
  const dateFrom = useRef(moment().toJSON())
  const dateTo = useRef(moment().toJSON())

  useEffect(() => {
    getReportCounterparty([
      { attribute: 'date_from', value: dateFrom.current },
      { attribute: 'date_to', value: dateTo.current },
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabledStartDate = useCallback((startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!startValue || !endValue) {
      return false;
    }
    return !startValue || startValue.isAfter(endValue);
  }, []);

  const disabledEndDate = useCallback((startValue: any, endValue: any) => {
    startValue = moment(startValue);
    endValue = moment(endValue);
    if (!endValue || !startValue) {
      return false;
    }
    return !endValue || endValue.isBefore(startValue);
  }, []);

  return (
    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
      <Row>
        <Filter
          onChange={(values: any) => {
            dateFrom.current = (values.find((item: any) => item.attribute === 'date_from') || { value: '' }).value;
            dateTo.current = (values.find((item: any) => item.attribute === 'date_to') || { value: '' }).value;
            getReportCounterparty(values);
          }}
          initialValues={filter}
          rules={[
            { name: 'date_from', rules: ['required'] },
            { name: 'date_to', rules: ['required'] },
          ]}
          attributes={[
            {
              name: 'date_from',
              title: I18n.t('report.Date from'),
              label: I18n.t('report.Date from'),
              hyper_type: 'date',
              inputProps: {
                allowClear: false,
                disabledDate: (startValue: any) => {
                  return disabledStartDate(startValue, dateTo.current);
                },
              },
            },
            {
              name: 'date_to',
              title: I18n.t('report.Date to'),
              hyper_type: 'date',
              label: I18n.t('report.Date to'),
              inputProps: {
                allowClear: false,
                disabledDate: (endValue: any) => {
                  return disabledEndDate(dateFrom.current, endValue);
                },
              },
            },
          ]}
          showClear={false}
        />
      </Row>
      <Row>
        <ReportCounterpartyData />
      </Row>
    </Space>
  );
}

const mapStateToProps = (state: AppState) => ({
  filter: state.report.filter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getReportCounterparty,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportCounterparty);
