import { CheckOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { I18n } from 'react-redux-i18n';

function ActionsColumn({
  record,
  changeStatus,
  edit,
  view,
  deleteRecord,
  duplicate,
}: any) {
  return (
    <div style={{ whiteSpace: 'nowrap', display: 'flex', gap: 5 }}>
      {changeStatus &&
        (record.processed ? (
          <Button type="link" icon={<StopOutlined />} onClick={changeStatus(record, false)} />
        ) : (
          <Button type="link" icon={<CheckOutlined />} onClick={changeStatus(record, true)} />
        ))}
      {!record.processed && edit && <Button type="link" icon={<EditOutlined />} onClick={edit(record)} />}
      {record.processed && view && <Button type="link" icon={<EyeOutlined />} onClick={view(record)} />}
      {!!deleteRecord && <div style={{ width: 20 }}>
        {!record.processed && (
          <Popconfirm
            title={I18n.t('order_item_editable.Sure to delete?')}
            onConfirm={deleteRecord(record)}
            cancelText={I18n.t('order_item_editable.Cancel')}
            okText={I18n.t('order_item_editable.Ok')}
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        )}
      </div>}
      
      {duplicate && <Button type="link" icon={<CopyOutlined />} onClick={duplicate(record)} />}
    </div>
  );
}

export default ActionsColumn;
