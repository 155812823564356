import {
  GET_ACCOUNTS,
  CREATE_ACCOUNT,
  UPDATE_ACCOUNT,
  DELETE_ACCOUNT,
} from '../../types/AccountTypes';

export const getAccounts = (filter: any = []) => ({
  type: GET_ACCOUNTS,
  filter,
});

export const createAccount = (data: any) => ({
  type: CREATE_ACCOUNT,
  data,
});

export const updateAccount = (id: number, data: any) => ({
  type: UPDATE_ACCOUNT,
  data,
  id,
});

export const deleteAccount = (id: any) => ({
  type: DELETE_ACCOUNT,
  id,
});
