import { Tree } from 'antd';

function ReportStorageCategoryTree({ treeData, onSelect, selectedKeys }: any) {

  return (
    <Tree
      defaultExpandAll={true}
      onSelect={onSelect}
      selectedKeys={(selectedKeys).toString().split()} // array of strings
      treeData={treeData}
    />
  );

}

export default (ReportStorageCategoryTree);
