import { Input, Form, Select, Tooltip } from 'antd';
import { useCallback, useRef } from 'react';

function EditableCell({
  options,
  inputProps,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  EditableContext,
  renderInput: _renderInput,
  ...restProps
}: any) {
  const isSubmit = useRef(false);

  const renderInput = useCallback((inputType: any, title: any, dataIndex: any, formik: any) => {
    const { errors, touched, isSubmitting, values } = formik;
    if (isSubmitting) {
      isSubmit.current = true;
    }

    if (_renderInput) {
      return _renderInput({ ...formik, isSubmit: isSubmit.current, name: dataIndex, title, inputType });
    }

    if (inputType === 'select') {
      return <Form.Item
        {...errors[dataIndex]
          && (touched[dataIndex] || isSubmit.current)
          && { validateStatus: 'error'}}
      >
        <Tooltip
          title={errors[dataIndex] === 'Required' ? '' : errors[dataIndex]}
        >
          <Select
            {...inputProps}
            showSearch
            style={{ width: '100%' }}
            placeholder={title}
            optionFilterProp="children"
            allowClear={true}
            value={values[dataIndex]}
            onBlur={(value) => {formik.handleBlur({target: {name: dataIndex, value}}); }}
            onChange={(value: any) => {
              formik.handleChange({
                target: {
                  name: dataIndex,
                  value,
                },
              });
            }}
            filterOption={(input: any, option: any) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {options.map((option: any) =>
              <Select.Option key={option.value} value={option.value}>{option.title}</Select.Option>)}
          </Select>
        </Tooltip>
      </Form.Item>;
    }

    if (inputType === 'calculated') {
      return 
    }

    return <Form.Item
      {...errors[dataIndex]
        && (touched[dataIndex] || isSubmit.current)
        && { validateStatus: 'error'}}
    >
      <Tooltip
        title={errors[dataIndex] === 'Required' ? '' : errors[dataIndex]}
      >
        <Input
          {...inputProps}
          placeholder={title}
          value={values[dataIndex]}
          type={inputType}
          name={dataIndex}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          autoComplete="off"
        />
      </Tooltip>
    </Form.Item>;
  }, [_renderInput, inputProps, options])

  return (
    <EditableContext.Consumer>
      {(formik: any) => {
        return <td {...restProps} className={editing ? 'editable-item' : ''}>
          {editing ? renderInput(inputType, title, dataIndex, formik) : restProps.children}
        </td>;
      }}
    </EditableContext.Consumer>
  );
}

export default EditableCell;
