import moment from 'moment';

const notFilterAttributes = ['sort', 'limit', 'offset', 'include_count'];

export default function generateFilter(filters = [], skipNotFilterAttributes = false) {
  if (!filters && !Array.isArray(filters)) {
    return {};
  }
  const res: any = {};
  filters.forEach((item: any) => {
    if (skipNotFilterAttributes && notFilterAttributes.includes(item.attribute)) {
      return;
    }
    if (item.attribute === 'sort') {
      if (item.value[0] === '-') {
        res[item.attribute] = {
          [item.value.substr(1)]: 0,
        };
      } else {
        res[item.attribute] = {
          [item.value]: 1,
        };
      }
    } else {
      res[item.attribute] = setOperator(item);
    }
  });

  return res;
}

function setOperator(data: any) {
  const whereOperator: any = {
    is: {
      operator: '',
      template: '{v}',
    },
    is_not: {
      operator: 'notILike',
      template: '{v}',
    },
    starts_with: {
      operator: 'iLike',
      template: '%{v}',
    },
    ends_with: {
      operator: 'iLike',
      template: '{v}%',
    },
    contains: {
      operator: 'iLike',
      template: '%{v}%',
    },
    exactly: {
      operator: 'eq',
      template: '{v}',
    },
    is_select: {
      operator: '',
      template: '*{v}',
    },
    is_not_array_select: {
      operator: '',
      template: '!{v}',
    },
    is_multy_select: {
      operator: '',
      template: '{v}',
    },
    is_not_array_multy_select: {
      operator: '',
      template: '!{v}',
    },
  };

  let resOperator: any;
  if (data.hasOwnProperty('comparison')) {
    if (data.comparison === 'between' && (data.value.hasOwnProperty('from') || data.value.hasOwnProperty('to')) ) {
      resOperator = {};
      if (data.value.hasOwnProperty('from') && data.value.from !== '') {
        if ( data.value.from !== undefined) {
          resOperator.gte = data.value.from;
        }
      }
      if (data.value.hasOwnProperty('to') && data.value.to !== '') {
        if ( data.value.to !== undefined) {
          resOperator.lte = data.value.to;
        }
      }
    } else {
      if (whereOperator.hasOwnProperty(data.comparison)) {
        if (whereOperator[data.comparison].operator === '') {
          resOperator = whereOperator[data.comparison].template.replace('{v}', data.value);
        } else {
          resOperator = {
            [whereOperator[data.comparison].operator]:
              whereOperator[data.comparison].template.replace('{v}', data.value),
          };
        }
      } else {
        switch (data.comparison) {
          case 'date_lt':
            resOperator = {
              lt: moment(data.value).toJSON(),
            };
            break;
          case 'date_gt':
            resOperator = {
              gte: moment(data.value).add('days', 1).toJSON(),
            };
            break;
          case 'date_is':
            resOperator = {
              gte: moment(data.value).toJSON(),
              lt:  moment(data.value).add('days', 1).toJSON(),
            };
            break;
          case 'date_is_not':
            resOperator = {
              lt:  moment(data.value).toJSON(),
              gte:  moment(data.value).add('days', 1).toJSON(),
              or: 1,
            };
            break;
          case 'date_is_within':
            if (data.value) {
              resOperator = {};
              if (data.value.from) {
                resOperator.gte = moment(data.value.from).toJSON();
              }
              if (data.value.to) {
                resOperator.lt = moment(data.value.to).add('days', 1).toJSON();
              }
            }
            break;
          default:
            resOperator = {
              [data.comparison]: typeof data.value === 'string' ? data.value.trim() : data.value,
            };
            break;
        }
      }
    }
  } else {
    resOperator = typeof data.value === 'string' ? data.value.trim() : data.value;
  }
  return resOperator;
}
