import { Formik } from 'formik';
import ProductFormContent from './ProductFormContent/ProductFormContent';
import validationFields from '../../../../lib/validate';
import { AppState } from '../../../../reducers';
import { Dispatch, bindActionCreators } from 'redux';
import {  createProduct, updateProduct, showModal } from '../../ProductActions';
import { connect } from 'react-redux';
import './ProductForm.scss'

function ProductForm({
  product,
  loading,
  categories,
  product_types,
  updateProduct,
  createProduct,
  showModal,
}: any) {

    product.published = typeof product.published === "undefined" ? true :  product.published ;

    return (
      <Formik
        initialValues = { product }
        enableReinitialize = {true}
        validate = { (values: any) => {
          const fields: any = [
            { name: 'title', rules: ['required'] },
            // { name: 'description', rules: ['required'] },
            { name: 'category_id', rules: ['required'] },
            { name: 'price', rules: ['required'] },
            { name: 'product_type', rules: ['required'] }
          ];
          return validationFields(fields, values);
        }}

        onSubmit = { (values) => {
          if (values.id) {
            updateProduct(values.id, values);
          } else {
            createProduct(values);
          }
          showModal(false);
        }}
      >
      {(props) => <ProductFormContent
        {...props}
        product = {product}
        loading = {loading}
        categories={categories}
        product_types={product_types}
      />}
      </Formik>
    );
}

const mapStateToProps = (state: AppState) => ({
  loading: state.product.loading,
  categories: state.started_data.categories,
  product: state.product.product,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createProduct,
      updateProduct,
      showModal,
    },
    dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(ProductForm);
