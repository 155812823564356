import { DELETE_STARTED_DATA_ITEM,
  CREATE_STARTED_DATA_COUNTERPARTY,
  UPDATE_STARTED_DATA_COUNTERPARTY,
} from './../types/StartedDataTypes';
import { CREATE_COUNTERPARTY, UPDATE_COUNTERPARTY, DELETE_COUNTERPARTY } from './../types/CounterpartyTypes';
import { put, takeLatest } from 'redux-saga/effects';
import restClient from '../config/rest-client';
import { ERROR } from '../types/ErrorTypes';
import generateFilter from '../lib/genFilter';
import {
  GET_COUNTERPARTIES,
  COUNTERPARTIES_RECEIVED,
  CREATE_COUNTERPARTY_RECEIVED,
  UPDATE_COUNTERPARTY_RECEIVED,
  DELETE_COUNTERPARTY_RECEIVED,
} from '../types/CounterpartyTypes';
import { GenericEntityResponse } from '../types/baseTypes';
import { setValueByKey } from '../lib/storage';
import { STORAGE_COUNTERPARTY_FILTERS } from '../types/Constants';

function* getCounterparties({filter}: any) {
  setValueByKey(STORAGE_COUNTERPARTY_FILTERS, filter);
  try {
    const {data, count} = yield restClient.get('counterparties', '', generateFilter(filter));
    yield put({ type: COUNTERPARTIES_RECEIVED, data, count, filter });
  } catch (error) {
    yield put({ type: COUNTERPARTIES_RECEIVED, data: [] });
    yield put({ type: ERROR, messages: ['Not counterparties']});
  }
}

function* createCounterparty({data}: any) {
  data.c_type = data.c_type ? data.c_type.toString() : '';
  try {
    let result: GenericEntityResponse = yield restClient.post('counterparties', data);
    result = {...result, c_type: result.c_type.split(',')};
    yield put({ type: CREATE_COUNTERPARTY_RECEIVED, item: result });
    yield put({ type: CREATE_STARTED_DATA_COUNTERPARTY, item: result });
  } catch (error) {
    yield put({ type: CREATE_COUNTERPARTY_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not counterparty create']});
  }
}

function* updateCounterparty({data, id}: any) {
  data.c_type = data.c_type ? data.c_type.toString() : '';
  try {
    let result: GenericEntityResponse = yield restClient.update('counterparties', id, data);
    result = {...result, c_type: result.c_type.split(',')};
    yield put({ type: UPDATE_COUNTERPARTY_RECEIVED, item: result });
    yield put({ type: UPDATE_STARTED_DATA_COUNTERPARTY, item: result });
  } catch (error) {
    yield put({ type: UPDATE_COUNTERPARTY_RECEIVED, item: {} });
    yield put({ type: ERROR, messages: ['Not counterparty update']});
  }
}
function* deleteCounterparty({id}: any) {
  try {
    const result: GenericEntityResponse = yield restClient.delete('counterparties', id);
    yield put({ type: DELETE_COUNTERPARTY_RECEIVED, id });
    yield put({ type: DELETE_STARTED_DATA_ITEM, name: 'counterparties', id });

  } catch (error) {
    yield put({ type: ERROR, messages: ['Not counterparty delete']});
  }
}

export default function* actionWatcherCounterparty() {
  yield takeLatest(GET_COUNTERPARTIES, getCounterparties);
  yield takeLatest(CREATE_COUNTERPARTY, createCounterparty);
  yield takeLatest(UPDATE_COUNTERPARTY, updateCounterparty);
  yield takeLatest(DELETE_COUNTERPARTY, deleteCounterparty);
}
