import { TreeSelect } from 'antd';

function ProductCategorySelect({ treeData, onSelect, selectedKeys }: any) {

  return (
    <TreeSelect
      treeDefaultExpandAll
      onChange={(value: any) => onSelect([value.toString()])}
      value={selectedKeys}
      style={{ margin: '0 0 10px 5px', width: 'calc(100% - 5px)' }}
      treeData={treeData}
    />
  );

}

export default ProductCategorySelect;
